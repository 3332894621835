import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form } from "react-router-dom"
import { getRequest } from "common/Constants"
import moment from "moment"
// import { useFormik } from "formik";
// import * as Yup from "yup";
const viewpurchase = () => {
  //meta title
  document.title = "Samarth Fruit Company"

//   hooks start
const navigate = useNavigate()
const location = useLocation()
//   hooks end

const [id, setId] = useState(location.state.id)

// to get the purchase order start

const [purchaseOrderData, setPurchaseOrderData] = useState([])
const getPurchaseOrderData = async() =>{
    try {
        const responseData = await getRequest(`purchaseOrderDetail?purchase_order_id=${id}`)
        if(responseData && responseData.status == 200) {
            setPurchaseOrderData(responseData.data.data)
        }
    } catch (error) {
        console.log(error)
    }
}
// to get the purchase order end


useEffect(() => {
    getPurchaseOrderData()
},[])

useEffect(() => {
  // While view purchase page is active, the order tab must also activated
  let element = document.getElementById("order")
  if (element) {
    element.classList.add("mm-active") // Add the 'active' class to the element
  }
  return () => {
    if (element) {
      element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
    }
  }
}, [])


  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="खरेदी ऑर्डर" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">नाव</label>
                      <div className="col-md-10 m-auto">
                        <p className="mb-0 para1">{!!(purchaseOrderData?.name) ? purchaseOrderData?.name : "-"}</p>
                      </div>
                    </div>
                    <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">पत्ता</label>
                      <div className="col-md-10 m-auto">
                        <p className="mb-0 para1">{!!(purchaseOrderData?.address) ? purchaseOrderData?.address :"-"}</p>
                      </div>
                    </div>

                    <div className="row mb-3 border-1">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        फोन नं
                      </label>
                      <div className="col-md-10 m-auto">
                        <p className="mb-0 para1">{!!(purchaseOrderData?.phone_no) ? purchaseOrderData?.phone_no :"-"}</p>
                      </div>
                    </div>
                    <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">
                        व्हरायटी
                      </label>
                      <div className="col-md-10 m-auto">
                        <p className="mb-0 para1">{!!(purchaseOrderData?.varierty) ? purchaseOrderData?.varierty : "-"}</p>
                      </div>
                    </div>

                    <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">
                        अंदाजे वजन
                      </label>
                      <div className="col-md-10 m-auto">
                        {/* <p className="mb-0 para1">{!!(purchaseOrderData?.weight) ? purchaseOrderData?.weight + " kg" : "-"}</p> */}
                        <p className="mb-0 para1">{purchaseOrderData?.weight } Kg</p>
                      </div>
                    </div>

                    <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">
                        खरेदी तारीख
                      </label>
                      <div className="col-md-10 m-auto">
                        {/* <p className="mb-0 para1">{!!(purchaseOrderData?.purchase_date) ?purchaseOrderData?.purchase_date : "-"}</p> */}
                        <p className="mb-0 para1">{!!(purchaseOrderData?.purchase_date) ?moment(purchaseOrderData?.purchase_date).format("DD/MM/YYYY") : "-"}</p>
                      </div>
                    </div>

                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-2" style={{ textAlign: "left" }}>
                        <Link to="/Purchaseorder" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default viewpurchase
