import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import axios from "axios"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from 'react-loader-spinner';

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"

import { postRequest, responsecode200, responsecode408, setLocalStorage } from "common/Constants";

const Login = props => {
  const navigate = useNavigate()

  const reactAppUrl = process.env.REACT_APP_ALLURL

  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false);

  //meta title
  document.title = "Samarth Fruit Company"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      password: Yup.string().min(6, 'Must be Minimum 6 digits').required("Please Enter Your Password"),
    }),
    onSubmit: async values => {

      setIsLoading(true)
      try {
        const formData = new FormData()
        
        formData.append("email", values.email)
        formData.append("password", values.password)

        const responseData = await postRequest(`adminLogin`,formData)

        setIsLoading(false)
        
        if(responseData && responseData.status == 200) {
          localStorage.setItem("token",responseData.data.token)
          localStorage.setItem("authUser", JSON.stringify(responseData.data));
          navigate("/dashboard")
        }

        // const response = await axios.post(
        //   `${reactAppUrl}/adminLogin`,
        //   formData
        // )

        // console.log(response)

        // if (response && response.status == responsecode200()) {
        //   toast(response.data.message)
        //   navigate("/dashboard")
        //   setLocalStorage("token",response.data.token)
        //   setLocalStorage("authUser", JSON.stringify(response.data));
        // }else{
        //   console.log("error")
        //   navigate("/login")
        // }

      } catch (error) {

        console.log(error)

        if(error.response && error.response.status == 408){
          toast.error(`${error.response.data.message}`)
        }else{
          toast.error(`${error.response.data.message}`)
        }
      }

    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.router.navigate, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.router.navigate, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">स्वागत आहे !</h5>
                        <p>वर सुरू ठेवण्यासाठी साइन इन करा.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      {/* Same as */}
                      {/* <ToastContainer /> */}

                      <div className="mb-3">
                        <Label className="form-label">ईमेल</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="ईमेल प्रविष्ट करा"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">पासवर्ड</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="पासवर्ड टाका"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          name="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          लक्षात ठेवा
                        </label>
                      </div> */}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? "लॉगिन करत आहोत" :"लॉगिन करा"}
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">सह साइन इन करा</h5>

                                              </div> */}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          तुमचा पासवर्ड विसरलात
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  खाते नाही?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    आता साइन अप करा{" "}
                  </Link>{" "}
                </p> */}
                 <p>
                  © {new Date().getFullYear()} Samarth Fruit Company Crafted by CodetentaclesTechnologies
                </p> 
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
