import React, { useEffect, useState } from "react"
import { Button, Label } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import axios from "axios"
import { Pagination } from "@mui/material"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getHeader, getRequest } from "common/Constants"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { Modal } from "react-bootstrap"

const Business = () => {

  const [isLoading, setIsLoading] = useState(false)

  const [startDate, setStartDate] = useState("")
  const [EndDate, setEndDate] = useState("")
  const [perPageItem, setPerPageItem] = useState(0)

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const navigate = useNavigate()
  const reactAppUrl = process.env.REACT_APP_ALLURL

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  const [businessManList, setBusinessManList] = useState([])
  const getBusinessMan = async () => {

    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `merchantList?page=${page}&start_date=${startDate}&end_date=${EndDate}&name=${name}&phone_no=${phone}`
      )
      setIsLoading(false)
      if (responseData && responseData?.status == 200) {
        setBusinessManList(responseData?.data?.data)

        let totalPages = responseData?.data?.total / responseData?.data?.perPage
        setTotalCount(Math.ceil(totalPages))
        setPerPageItem(responseData.data.perPage)
      }
    } catch (error) {
      console.log(error)
    }

    // const response = await axios.get(
    //   `${reactAppUrl}/merchantList?page=${page}&start_date=${startDate}&end_date=${EndDate}`,
    //   getHeader()
    // )

    // setBusinessManList(response.data.data)

    // let totalPages = response.data.total / response.data.perPage
    // setTotalCount(Math.ceil(totalPages))
  }

  // To view the merchant detail start

  const handleView = id => {
    try {
      // navigate({pathname:"/Viewfarmer",state:id})
      navigate("/businessview", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To view the merchant detail end
  // To Edit the merchant detail start

  const handleEdit = id => {
    try {

      // navigate({pathname:"/EditBusiness",state:id})
      navigate("/EditBusiness", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the merchant detail end

  // react bootstrap modal start

  const [deleteId, setDeleteId] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  // react bootstrap modal end

  // To delete the merchant start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)

      const responseData = await getRequest(
        `merchantDelete?merchant_id=${deleteId}`
      )
      setIsLoading(false)
      setPage(1)

      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  // To delete the merchant end

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  useEffect(() => {
    getBusinessMan()
  }, [page, render])

  return (
    <div>
      <div className="row mt-3">
        <div className="col">
        <form className="">
            <div className="row mb-4">
            <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">
                          नाव 
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="नाव"
                            onChange={e => {
                              setName(
                                e.target.value
                              )
                            }}
                            />
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">
                          फोन नं.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="फोन नं."
                            minLength={10}
                            maxLength={10}
                            onChange={e => {
                              setPhone(
                                e.target.value
                              )
                            }}/>
                        </div>
                      </div>
            
             
              <div className="col-sm-2 col-xl-2 align-self-end">
                <div className="mb-3">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={getBusinessMan}
                  >
                    फिल्टर
                  </Button>
                </div>
              </div>
            </div>
          </form>

          {isLoading ? (
            <>
              <h1>
                <Skeleton />
              </h1>
              <h1>
                <Skeleton />
              </h1>
              <h1>
                <Skeleton />
              </h1>
              <h1>
                <Skeleton />
              </h1>
              <h1>
                <Skeleton />
              </h1>
            </>
          ) : businessManList.length > 0 ? (
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th>अ.क्र.</th>
                      <th>नाव</th>
                      <th>व्यवसायाचे नाव</th>
                      <th>पत्ता</th>

                      <th>फोन नं</th>

                      <th></th>
                    </tr>
                  </thead>
                  {businessManList.map((businessMan, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>{page * perPageItem - perPageItem + (index + 1)}</td>
                          <td>{businessMan.name}</td>
                          <td>{businessMan.business_name}</td>
                          <td>{businessMan.address}</td>
                          <td>{businessMan.phone_no}</td>
                          <td>
                            <a
                              className="btn-sm btn-view  btn"
                              onClick={e => handleView(businessMan.merchant_id)}
                            >
                              <div className="tooltip1">
                                <i className="bx bx-file align-middle ">
                                  <span className="tooltiptext">पहा</span>
                                </i>
                              </div>
                            </a>

                            <a
                              className="btn-sm btn-edit  btn"
                              onClick={e =>
                                handleEdit(businessMan?.merchant_id)
                              }
                            >
                              <div className="tooltip1">
                                <i className="bx bx-edit align-middle">
                                  <span className="tooltiptext">
                                    संपादित करा
                                  </span>
                                </i>
                              </div>
                            </a>
                            <a
                              className="btn-sm btn-delete btn"
                              onClick={() =>
                                handleShow(businessMan?.merchant_id)
                              }
                            >
                              <div className="tooltip1">
                                <i className="bx bxs-trash align-middle">
                                  <span className="tooltiptext">हटवा</span>
                                </i>
                              </div>
                            </a>

                            {/* <Link
                              to="#"
                              type="button"
                              className="btn-sm btn-delete btn "
                            >
                              <div className="tooltip1">
                                <i className="bx bxs-trash align-middle">
                                  <span className="tooltiptext">हटवा</span>
                                </i>
                              </div>
                            </Link> */}
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
                </table>
              </div>
              <ul className="pagination modal-1" style={{ float: "right" }}>
                <Pagination
                  count={totalCount}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                  shape="rounded"
                />
              </ul>
            </div>
          ) : (
            <div className="nodata">
              <b>Data Not Available</b>
            </div>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>व्यापारी हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button
            variant="secondary"
            onClick={isLoading ? "" : handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Business
