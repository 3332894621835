import React, { useEffect, useState } from "react"

import "../../assets/css/style.css"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest } from "common/Constants"
import { Pagination } from "@mui/material"
import moment from "moment"
import { Modal } from "react-bootstrap"

const Purchaseorder = () => {
  const [isLoading, setIsLoading] = useState(false)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  // hooks start
  const navigate = useNavigate()
  // hooks end

  // to get the farmer order data start
  const [farmerOrderData, setFarmerOrderData] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [plantedAreaFrom, setPlantedAreaFrom] = useState("")
  const [plantedAreaTo, setPlantedAreaTo] = useState("")
  const [fromTreeCount, setFromTreeCount] = useState("")
  const [toTreeCount, setToTreeCount] = useState("")
  const [fromWeightFilter, setFromWeightFilter] = useState("")
  const [toWeightFilter, setToWeightFilter] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const [perPageItem, setPerPageItem] = useState(0)

  const getFarmerOrderData = async () => {
    try {
      setIsLoading(true)

      const responseData = await getRequest(
        `farmerOrderList?page=${page}&start_date=${startDate}&end_date=${endDate}&from_planted_area=${plantedAreaFrom}&to_planted_area=${plantedAreaTo}&from_tree_count=${fromTreeCount}&to_tree_count=${toTreeCount}&from_weight=${fromWeightFilter}&to_weight=${toWeightFilter}&name=${name}&phone_no=${phone}`
      )

      setIsLoading(false)

      if (responseData && responseData.status == 200) {
        setFarmerOrderData(responseData.data.data)
        let totalPages = responseData.data.total / responseData.data.perPage
        setTotalCount(Math.ceil(totalPages))
        setPerPageItem(responseData.data.perPage)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the farmer order data end

  // react bootstrap modal start

  const [deleteId, setDeleteId] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  // react bootstrap modal end

  // To delete the farmerOrderList start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)

      const responseData = await getRequest(
        `farmerOrderDelete?farmer_order_id=${deleteId}`
      )

      setIsLoading(false)

      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  // To delete the farmerOrderList end

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  // view the farmer detail start

  const handleView = id => {
    try {

      // navigate({pathname:"/Viewfarmer",state:id})
      navigate("/Viewfarmerorder", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // view the farmer detail end

  // To Edit the sales invoice detail start

  const handleEdit = id => {
    try {
      // navigate({pathname:"/EditBusiness",state:id})
      navigate("/Editfarmerorder", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the sales invoice detail end

  useEffect(() => {
    getFarmerOrderData()
  }, [page])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="अगामी खरेदी ऑर्डर " />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <div className="row" style={{ justifyContent: "flex-end" }}>
                    <div
                      className="col-md-3 mb-3"
                      style={{ textAlign: "right" }}
                    >
                      <a
                        className="btn btn-primary"
                        href="/farmerorder"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <i className="bx bx-plus align-middle"></i>नवीन अगामी
                        खरेदी ऑर्डर
                      </a>
                    </div>
                  </div>
                  <form className="mt-3">
                    <div className="row mb-4">
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">नाव</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="नाव"
                            onChange={e => {
                              setName(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">फोन नं.</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="फोन नं."
                            minLength={10}
                            maxLength={10}
                            onChange={e => {
                              setPhone(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            या काढणी तारखे पासून{" "}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="dd-mm-yyyy"
                            onChange={e => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            या काढणी तारखे पर्यंत{" "}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="dd-mm-yyyy"
                            onChange={e => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            लागवड क्षेत्र (एकर मध्ये ){" "}
                          </label>
                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="पासून "
                              className="form-control"
                              onChange={e => setPlantedAreaFrom(e.target.value)}
                            />
                            <span className="p-1">-</span>
                            <input
                              type="text"
                              placeholder="पर्यंत"
                              className="form-control"
                              onChange={e => setPlantedAreaTo(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">अंदाजे वजन </label>
                          <div className="d-flex">
                            <input
                              placeholder="पासून "
                              type="text"
                              className="form-control"
                              onChange={e =>
                                setFromWeightFilter(e.target.value)
                              }
                            />
                            <span className="p-1">-</span>
                            <input
                              type="text"
                              placeholder="पर्यंत "
                              className="form-control"
                              onChange={e => setToWeightFilter(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">झाडांची संख्या </label>

                          <div className="d-flex">
                            <input
                              type="text"
                              placeholder="पासून"
                              className="form-control"
                              onChange={e => setFromTreeCount(e.target.value)}
                            />
                            <span className="p-1">-</span>
                            <input
                              type="text"
                              placeholder="पर्यंत"
                              className="form-control"
                              onChange={e => setToTreeCount(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3 align-self-end">
                        <div className="mb-3">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md"
                            onClick={getFarmerOrderData}
                          >
                            फिल्टर
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>

                  {isLoading ? (
                    <>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                    </>
                  ) : farmerOrderData.length > 0 ? (
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>अ.क्र.</th>
                              <th>शेतकरी चे नाव</th>
                              <th>फोन नं.</th>
                              <th>व्हरायटी</th>
                              <th>लागवड क्षेत्र (एकर मध्ये )</th>
                              <th>झाडांची संख्या</th>
                              <th>अंदाजे वजन</th>
                              <th>लागवडीची तारीख</th>
                              <th>काढणी तारीख</th>
                              <th>तोडणी वारंवारिता</th>
                              <th>तोडणी महिने</th>
                              <th></th>
                            </tr>
                          </thead>

                          {farmerOrderData.length > 0 ? (
                            farmerOrderData.map((farmerOrder, index) => {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td>
                                      {page * perPageItem -
                                        perPageItem +
                                        (index + 1)}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.name
                                        ? farmerOrder.name
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.phone_no
                                        ? farmerOrder.phone_no
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.variety
                                        ? farmerOrder.variety
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.planted_area
                                        ? farmerOrder.planted_area
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.tree_count
                                        ? farmerOrder.tree_count
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.weight
                                        ? farmerOrder.weight
                                        : "-"}{" "}
                                      kg
                                    </td>
                                    <td>
                                      {!!farmerOrder?.plantation_date
                                        ? moment(
                                            farmerOrder.plantation_date
                                          ).format("DD/MM/YYYY")
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.cutting_date
                                        ? moment(
                                            farmerOrder.cutting_date
                                          ).format("DD/MM/YYYY")
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.cutting_ratio
                                        ? farmerOrder.cutting_ratio
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!farmerOrder?.cutting_end_month
                                        ? moment(
                                            farmerOrder?.cutting_end_month
                                          ).format("DD/MM/YYYY")
                                        : "-"}
                                    </td>
                                    <td>
                                      <a
                                        className="btn-sm btn-view  btn"
                                        onClick={e =>
                                          handleView(
                                            farmerOrder?.farmer_order_id
                                          )
                                        }
                                      >
                                        <div className="tooltip1">
                                          <i className="bx bx-file align-middle ">
                                            <span className="tooltiptext">
                                              पहा
                                            </span>
                                          </i>
                                        </div>
                                      </a>

                                      <a
                                        className="btn-sm btn-edit  btn"
                                        onClick={e =>
                                          handleEdit(
                                            farmerOrder?.farmer_order_id
                                          )
                                        }
                                      >
                                        <div className="tooltip1">
                                          <i className="bx bx-edit align-middle">
                                            <span className="tooltiptext">
                                              संपादित करा
                                            </span>
                                          </i>
                                        </div>
                                      </a>

                                      {/* <Link
                                        to="#"
                                        type="button"
                                        className="btn-sm btn-delete btn "
                                      >
                                        <div className="tooltip1">
                                          <i className="bx bxs-trash align-middle">
                                            <span className="tooltiptext">
                                              हटवा
                                            </span>
                                          </i>
                                        </div>
                                      </Link> */}

                                      <a
                                        className="btn-sm btn-delete btn"
                                        onClick={() =>
                                          handleShow(
                                            farmerOrder?.farmer_order_id
                                          )
                                        }
                                      >
                                        <div className="tooltip1">
                                          <i className="bx bxs-trash align-middle">
                                            <span className="tooltiptext">
                                              हटवा
                                            </span>
                                          </i>
                                        </div>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan="12">
                                <div className="nodata">
                                  <b>Data Not Available</b>
                                </div>
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                      <ul
                        className="pagination modal-1"
                        style={{ float: "right" }}
                      >
                        <Pagination
                          count={totalCount}
                          page={page}
                          onChange={handleChange}
                          color="primary"
                          shape="rounded"
                        />
                      </ul>
                    </div>
                  ) : (
                    <div className="nodata">
                      <b>No data available</b>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>अगामी खरेदी ऑर्डर हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button
            variant="secondary"
            onClick={isLoading ? "" : handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Purchaseorder
