import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Footer from "components/VerticalLayout/Footer"
import Header from "components/VerticalLayout/Header"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Link, useLocation } from "react-router-dom"
import axios from "axios"
import { getRequest } from "common/Constants"
// import { useFormik } from "formik";
// import * as Yup from "yup";
const Viewothers = () => {
document.title = "Samarth Fruit Company"

const location = useLocation()
const [id, setId] = useState(location.state?.id)

  const reactAppUrl = process.env.REACT_APP_ALLURL



  const [otherData, setOtherData] = useState([])


  const getOtherData = async () => {
    try {
      
      // const response = await axios.get(
      //   `${reactAppUrl}/showOther?otherId=${id}`  //api
      // )

      const response  = await getRequest(`showOther?otherId=${id}`)
      setOtherData(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getOtherData()
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="इतर" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                     
                      <form>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">नाव</label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{otherData.otherName}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">प्रवर्ग</label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{otherData.catName}</p>
                          </div>
                        </div>
                    
                        <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            फोन नं
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{otherData.phoneNo}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            पत्ता
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{otherData.address}</p>
                          </div>
                        </div>
                       
                            <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-2" style={{ textAlign: "left" }}>
                        <Link to="/farmer/Listindex" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                   
                    </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default Viewothers
