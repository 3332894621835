import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "../../assets/css/style.css"
import { Link } from "react-router-dom"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { postRequest } from "common/Constants"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const Addpayment = () => {
  //meta title

  const [customchkPrimary, setcustomchkPrimary] = useState(true)
  const [customchkSuccess, setcustomchkSuccess] = useState(true)
  const [customchkInfo, setcustomchkInfo] = useState(true)
  const [customchkWarning, setcustomchkWarning] = useState(true)
  const [customchkDanger, setcustomchkDanger] = useState(true)
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true)
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true)
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true)
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
  const [startDate, setStartDate] = useState(new Date("04-03-2020"))
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: "",
      date: "",
      payment: "",
      paymentstatus: "",
      Note: "",
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .min(2, "कमीत कमी 2 अक्षर टाका ")
        .required("कृपया नाव टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
      date: Yup.string().required("कृपया तारीख टाका"),
      payment: Yup.string().required("कृपया डेबिट/क्रेडिट टाका"),
      paymentstatus: Yup.string().required("कृपया कॅश/बँकिंग टाका"),
      Note: Yup.string()
        .required("कृपया नोट टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
    }),
    onSubmit: async values => {
      try {

        const formData = new FormData()

        formData.append("name", `${values.Name}`)
        formData.append("date", `${values.date}`)
        formData.append("payment_mode", `${values.payment}`)
        formData.append("payment_type", `${values.paymentstatus}`)
        formData.append("notes", `${values.Note}`)

        setIsLoading(true)
        const responseData = await postRequest(`paymentCreate`, formData)
        setIsLoading(false)

        if (responseData && responseData.status == 200) {
          navigate("/paymentlist")
        } else if (responseData && responseData.status == 409) {
          navigate("/Addpayment")
        }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status == responsecode408()) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.response.data.message)
          // navigate("/login")
        }
      }
    },
  })

  useEffect(() => {
    // While add payment page is active, the payment tab must also activated
    let element = document.getElementById("paymentlist")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs
                title="डॅशबोर्ड"
                breadcrumbItem="
नवीन पेमेंट"
              />

              <div className="row">
                <div className="col" style={{ padding: "0" }}>
                  <div className="card">
                    <div className="card-body">
                      <div className="cardTitle h4 d-none">Textual inputs</div>
                      <p className="card-title-desc d-none">
                        Here are examples of <code>.form-control</code> applied
                        to each textual HTML5 <code>&lt;input&gt;</code>{" "}
                        <code>type</code>.
                      </p>
                      <form
                        className="needs-validation"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            नाव*
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Name"
                              name="Name"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Name}
                            />
                            {validation.touched.Name &&
                            validation.errors.Name ? (
                              <span className="error">
                                {validation.errors.Name}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            तारीख*
                          </label>
                          <div className="col-md-10">
                            {/* <DatePicker
                              selected={startDate}
                              onChange={date => setStartDate(date)}
                              className="form-control"
                              dateFormat="d-MM-yyyy"
                              placeholderText="Select date"
                              required="required"
                            /> */}

                            <input
                              className="form-control"
                              type="date"
                              name="date"
                              id="example-month-input month"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.date}
                            />
                            {validation.touched.date &&
                            validation.errors.date ? (
                              <span className="error">
                                {validation.errors.date}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            डेबिट/क्रेडिट*
                          </label>
                          <div className="col-md-10">
                            <select
                              id="formrow-InputState payment"
                              name="payment"
                              className="form-select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.payment}
                            >
                              <option value="">Select</option>
                              <option value="1">डेबिट</option>
                              <option value="2">क्रेडिट</option>
                            </select>
                            {validation.touched.payment &&
                            validation.errors.payment ? (
                              <span className="error">
                                {validation.errors.payment}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            पेमेंट पद्धत(कॅश/बँकिंग)*
                          </label>
                          <div className="col-md-10">
                            <select
                              id="formrow-InputState payment"
                              name="paymentstatus"
                              className="form-select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.paymentstatus}
                            >
                              <option value="">Select</option>
                              <option value="1">कॅश</option>
                              <option value="2">बँकिंग</option>
                            </select>
                            {validation.touched.paymentstatus &&
                            validation.errors.paymentstatus ? (
                              <span className="error">
                                {validation.errors.paymentstatus}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            नोट*
                          </label>
                          <div className="col-md-10">
                            <textarea
                              id="Note"
                              name="Note"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Note}
                            />
                            {validation.touched.Note &&
                            validation.errors.Note ? (
                              <span className="error">
                                {validation.errors.Note}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {/* <Row className="mb-3">
                    <label
                      htmlFor="example-number-input"
                      className="col-md-2 col-form-label"
                    >
                      Number
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="number"
                        defaultValue="42"
                        id="example-number-input"
                      />
                    </div>
                  </Row> */}

                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2 col-5"
                            style={{ textAlign: "left" }}
                          >
                            <Link to="/paymentlist" className="btn btn-danger">
                              मागे जा
                            </Link>
                          </div>
                          <div
                            className="col-md-2 col-7"
                            style={{ textAlign: "right" }}
                          >
                            <Button color="primary" type="submit" disabled = {isLoading}>
                              {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Addpayment
