import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import logoDark from "../../assets/images/logo.png"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest } from "common/Constants"
import moment from "moment"
import phone from "../../assets/images/phone.png"
import sideimg from "../../assets/images/side-img.jpg"
import khardi from "../../assets/images/khardi.png"
const Addinvoice = () => {
  const [startDate, setStartDate] = useState(new Date("04-03-2020"))
  //meta title
  document.title = "Samarth Fruit Company"

  //   hooks start
  const navigate = useNavigate()
  const location = useLocation()
  const [id, setId] = useState(location.state.id)
  //   hooks end

  // get the sales invoice data for specific start

  const [salesInvoice, setSalesInvoice] = useState([])

  const getInvoiceData = async () => {
    try {
      const responseData = await getRequest(
        `purchaseInvoiceDetail?purchase_invoice_id=${id}`
      )
      setSalesInvoice(responseData.data.data)

      if (responseData && responseData.status == 404) {
        navigate("/SalesInvoicelist")
      }
    } catch (error) {
      console.log(error)
    }
  }
  // get the sales invoice data for specific end

  useEffect(() => {
    getInvoiceData()
  }, [])

  // to download pdf start

  const componentRef = useRef(null)

  const downloadPDF = () => {
    // const pageWidth = 210 // Width of the PDF page in mm (A4 size)
    // const pageHeight = 297 // Height of the PDF page in mm (A4 size)
    // const pageMargin = 10 // Margin of the PDF content in mm
    // const orientation = "portrait" // 'portrait' or 'landscape'

    html2canvas(componentRef.current)
      .then(canvas => {
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF()
        const imgProps = pdf.getImageProperties(imgData)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
        pdf.save("component.pdf")
      })
      .catch(error => {
        console.error("Error generating PDF:", error)
      })

    // html2canvas(componentRef.current)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF({
    //       orientation,
    //       unit: 'mm',
    //       format: [pageWidth, pageHeight],
    //     });
    //     const imgProps = pdf.getImageProperties(imgData);
    //     const pdfWidth = pageWidth - 2 * pageMargin;
    //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    //     let yPos = pageMargin;
    //     let currentPage = 1;

    //     const addNewPageIfNeeded = () => {
    //       if (yPos + pdfHeight > pageHeight - pageMargin) {
    //         pdf.addPage();
    //         yPos = pageMargin;
    //         currentPage++;
    //       }
    //     };

    //     pdf.setProperties({
    //       title: 'Component PDF',
    //       author: 'Your Name',
    //     });

    //     pdf.addImage(imgData, 'PNG', pageMargin, yPos, pdfWidth, pdfHeight);
    //     yPos += pdfHeight + pageMargin;

    //     pdf.save(`component_page_${currentPage}.pdf`);
    //   })
    //   .catch((error) => {
    //     console.error('Error generating PDF:', error);
    //   });
  }

  // to download pdf end

  useEffect(() => {
    // While Purchasedetails page is active, the invoice tab must also activated
    let element = document.getElementById("invoice")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div ref={componentRef}>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="इनव्हॉइस" />

          <div className="row">
            <div className="col">
              <div className="card card-invoice d-flex">
                <div className=" invoice-img">
                  <img src={sideimg} alt="" className="side-imgg" />
                </div>
                <div className="card-body invoice-body">
                  <div className="card-invoice-head">
                    <div className="row">
                      <div
                        className="col-md-3"
                        style={{ textAlign: "center", width: "20%" }}
                      >
                        <img
                          src={logoDark}
                          alt=""
                          height="120px"
                          width="120px"
                          className="invoice_img"
                        />
                      </div>
                      <div
                        className="col-md-9 mx-auto text-center justify-content-center"
                        style={{ width: "80%" }}
                      >
                        <h1 className="card-invoice-heading">
                          श्री समर्थ फ्रुट कंपनी, मालेगाव
                        </h1>
                        <h5 className="card-invoice-add">
                        पत्ता : ग्रामपंचायत कॉम्प्लेक्स, मालेगाव, ता. अर्धापूर, जि. नांदेड, महाराष्ट्र.
                        </h5>
                        <h4 className="card-invoice-phone">
                          <img src={phone} width="20" /> 9422842222,8888555445
                        </h4>
                      </div>
                      {/* <div
                      className="col-md-6 position-relative"
                      style={{ textAlign: "right" }}
                    >
                      <a href="#" className="btn btn-download">
                        <div className="tooltip1">
                          <i className="bx bx-download">
                            <span className="tooltiptext">डाउनलोड करा</span>
                          </i>
                        </div>
                      </a>
                    </div> */}
                    </div>
                  </div>
                  <div className="img-part">
                    <div className="row img-row">
                      <div
                        className="col-md-3 card-invoice-img justify-content-center  text-center"
                        style={{ width: "25%", marginTop: "10px" }}
                      >
                        {/* <div className="card-invoice-label">
                  <h5  className="card-invoice-add">
                  विक्री इन्व्हॉईस
                      </h5>
                  </div> */}
                        <img src={khardi} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="row d-none" ref={componentRef}>
                    <div className="col-md-6" style={{ textAlign: "left" }}>
                      <img
                        src={logoDark}
                        alt=""
                        height="95px"
                        className="invoice_img"
                      />
                    </div>
                    {/* <div
                      className="col-md-6 position-relative"
                      style={{ textAlign: "right" }}
                    >
                      <a href="#" className="btn btn-download">
                        <div className="tooltip1">
                          <i className="bx bx-download">
                            <span className="tooltiptext">डाउनलोड करा</span>
                          </i>
                        </div>
                      </a>
                    </div> */}
                  </div>
                  <div className="invoice-form-part">
                    <div className="row invoice-form-detail">
                      <div
                        className="col-xl-9 col-md-9 col-sm-12 col-12"
                        style={{ textAlign: "left", width: "75%" }}
                      >
                        <div
                          className="d-flex mb-3"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <h2 className="h2_label mb-0">
                            नाव <span className="p-1">:</span>
                          </h2>

                          <p className="mb-0 para1">{salesInvoice?.name}</p>
                        </div>
                        <div
                          className="d-flex mb-3"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <h2 className=" h2_label mb-0">
                            पत्ता <span className="p-1">:</span>
                          </h2>

                          <p className="mb-0 para1">{salesInvoice?.address}</p>
                        </div>
                        <div
                          className="d-flex mb-3"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <h2 className="h2_label mb-0">
                          फोन नं. <span className="p-1">:</span>
                          </h2>

                          <p className="mb-0 para1">{salesInvoice?.phone_no}</p>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 col-md-3 col-sm-12 col-12"
                        style={{ textAlign: "left", width: "25%" }}
                      >
                        <div
                          className="d-flex mb-3"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <h2 className="h2_label mb-0">
                            दिनांक <span className="p-1">:</span>
                          </h2>

                          {/* <p className="mb-0 para1">{salesInvoice?.date}</p> */}
                          <p className="mb-0 para1">
                            {moment(salesInvoice?.date).format("DD/MM/YYYY")}
                          </p>
                        </div>
                        <div
                          className="d-flex mb-3"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <h2 className="h2_label mb-0">
                            पावती क्र. <span className="p-1">:</span>
                          </h2>

                          <p className="mb-0 para1">
                            {salesInvoice?.receipt_no}
                          </p>
                        </div>
                        <div
                          className="d-flex mb-3"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <h2 className=" h2_label mb-0">
                            गाडी नं. <span className="p-1">:</span>
                          </h2>

                          <p className="mb-0 para1">
                            {salesInvoice?.vehicle_no}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-add-table">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* <h4>माहिती</h4> */}

                        <div className="table-responsive">
                          <table className="table table-striped table-nowrap table-invoice  mb-0 no-footer add-table-items">
                            <thead>
                              <tr>
                                <th>फळाचे नाव</th>
                                <th>वजन</th>
                                <th>कटींग वजन </th>
                                <th>नगत वजन </th>
                                <th>भाव </th>
                                <th>एकूण</th>
                              </tr>
                            </thead>
                            {salesInvoice?.invoice_information_array?.map(
                              (array, index) => {
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td>
                                        {!!array?.fruit_name
                                          ? array.fruit_name
                                          : "-"}
                                      </td>
                                      <td>
                                        {!!array?.weight ? array.weight : "-"}{" "}
                                        Kg
                                      </td>
                                      <td>
                                        {!!array?.cutting_weight
                                          ? array.cutting_weight
                                          : "-"}{" "}
                                        Kg
                                      </td>
                                      <td>
                                        {!!array?.actual_weight
                                          ? array.actual_weight
                                          : "-"}{" "}
                                        Kg
                                      </td>
                                      <td>
                                        <span className="p-1">₹</span>
                                        {!!array?.rate ? array.rate : "-"}
                                      </td>
                                      <td>
                                        <span className="p-1">₹</span>
                                        {!!array?.total ? array.total : "-"}
                                      </td>
                                    </tr>
                                  </tbody>
                                )
                              }
                            )}

                            {/* {salesInvoice.length > 0 &&
                              salesInvoice.map((invoice, index) => {
                                return invoice.invoice_information_array.map(
                                  (array, arrayIndex) => {
                                    return (
                                      <tbody key={arrayIndex}>
                                        <tr>
                                          <td>{array.fruit_id}</td>
                                          <td>{array.weight}</td>
                                          <td>{array.cutting_weight}</td>
                                          <td>{array.actual_weight}</td>
                                          <td>{array.rate}</td>
                                          <td>{array.total}</td>
                                          <td>Hi</td>
                                        </tr>
                                      </tbody>
                                    )
                                  }
                                )
                              })} */}

                            {/* <tbody>
                              <tr className="add-row">
                                <td>Papaya</td>
                                <td>188</td>

                                <td>23</td>
                                <td>332</td>
                                <td>12</td>

                                <td>23239</td>
                              </tr>
                            </tbody> */}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-card-note-part">
                    <div
                      className="row invoice-card-note"
                      
                    >
                      <div className="col-md-6" style={{ width: "50%" }}>
                        <div className="mb-3">
                          <h2 className="h2_label">नोट</h2>
                          <p className="mb-0 para1">{salesInvoice?.notes}</p>
                        </div>
                      </div>
                      <div className="col-md-6" style={{ width: "50%" }}>
                        <div className="invoice-total-card">
                          {/* <h4 className="invoice-total-title">सारांश</h4> */}
                          <div className="invoice-total-box">
                            <div className="invoice-total-inner view-invoice-total-inner">
                              <p>
                                एकूण <span>₹{salesInvoice?.total}</span>
                              </p>
                              <p>
                                कामगार खर्च{" "}
                                <span>₹{salesInvoice?.worker_charge}</span>
                              </p>
                              <p>
                                इतर खर्च{" "}
                                <span>₹{salesInvoice?.other_charge}</span>
                              </p>

                              <div className="links-info-one">
                                <div className="links-info">
                                  <div className="links-cont">
                                    <a href="#" className="service-trash"></a>
                                  </div>
                                </div>

                                <div className="invoice-total-footer view-invoice-total-footer">
                                  <h4>
                                    एकूण{" "}
                                    <span>₹ {salesInvoice?.overall_total}</span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <img
                          src={salesInvoice?.signature}
                          style={{
                            width: "200px",
                            height: "100px",
                            float: "right",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer-part">
                    <div className="row invoice-footer">
                      <div
                        className="col-md-6 d-flex"
                        style={{ width: "50%", alignItems: "center" }}
                      >
                        <div className="footer-heading">
                          <h1 className="card-invoice-heading">धन्यवाद...!</h1>
                        </div>
                      </div>

                      <div
                        className="col-md-6 mt-3 mb-3 text-right justify-content-end d-flex"
                        style={{
                          width: "50%",
                          flexDirection: "column",
                          justifyContent: "end",
                        }}
                      >
                        <div
                          className="selectedimg"
                          style={{
                            height: "40px",
                            marginBottom: "10px",
                            position: "relative",
                            marginTop: "10px",
                            justifyContent: "end",
                            display: "flex",
                          }}
                        >
                          <img
                            alt="not found"
                            width={"230px"}
                            height={"50px"}
                            src={
                              "https://samarthfruitcompany.in/api/public/signature/signature.jpeg"
                            }
                          />
                        </div>
                        <p className="sign-p text-right">
                          श्री समर्थ फ्रुट कंपनी
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row mb-3 mt-3 d-print-none print-btn"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div
                      className="col-md-2 col-5"
                      style={{ textAlign: "left" }}
                    >
                      <Link to="/purchaselist" className="btn btn-danger">
                        मागे जा
                      </Link>
                    </div>
                    <div
                      className="col-md-2 col-7"
                      style={{ textAlign: "right" }}
                    >
                      {/* <button
                          className="btn btn-primary"
                          onClick={downloadPDF}
                        > */}
                      <button
                        className="btn btn-primary"
                        onClick={() => window.print()}
                      >
                        प्रिंट करा
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addinvoice
