import axios from "axios"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { clearSubmit } from "redux-form"

export const getHeader = () => {
  return {
    headers: { token: localStorage.getItem("token") },
  }
}

export const postRequest = async (link, data) => {
  // debugger
  try {
    const reactAppUrl = process.env.REACT_APP_ALLURL

    let response = await axios.post(`${reactAppUrl}/${link}`, data, getHeader())

    if (response && response.status == 200) {
      toast.success(response.data.message)
      return response
    }
  } catch (error) {
    if (error.response && error.response.status == 408) {
      toast.error(error.response.data.message)
      window.location.href = "/login"
    } else if (error.response && error.response.status == 409) {
      toast.error(error.response.data.message)
      return error.response
    } else if (error.response && error.response.status == 404) {
      toast.error(error.response.data.message)
      return error.response
    } else if (error.response && error.response.status == 400) {
      toast.error(error.response.data.message[0])
      return error.response
    }else {
      toast.error(error.response.data.message)
      return error.response
    }
  }
}

export const getRequest = async link => {
  try {
    const reactAppUrl = process.env.REACT_APP_ALLURL

    let response = await axios.get(`${reactAppUrl}/${link}`, getHeader())

    if (response && response.status == 200) {
      toast.success(response.data.message)
      return response
    }
  } catch (error) {
    if (error.response && error.response.status == 408) {
      toast.error(error.response.data.message)
      window.location.href = "/login"
    } else if (error.response && error.response.status == 409) {
      toast.error(error.response.data.message)
      return error.response
    } else if (error.response && error.response.status == 404) {
      toast.error(error.response.data.message)
      return error.response
    } else if (error.response && error.response.status == 400) {
      toast.error(error.response.data.message)
      return error.response
    }else {
      toast.error(error?.response?.data?.message)
      return error.response
    }
  }
}

export const setLocalStorage = (itemName, value) => {
  localStorage.setItem(itemName, value)
}

export const responsecode200 = () => {
  return 200
}
export const responsecode408 = () => {
  return 408
}
export const Role = role => {
  return Role == role
}

export const getRole = () => {

  let RoleString = localStorage.getItem("authUser")
  if(RoleString){
    let RoleObject = JSON.parse(RoleString)
    let Role = RoleObject?.role_id
    return Role
  }else{
    window.location.href = "/login"
  }

}
