import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { getHeader, getRequest, responsecode200, responsecode408 } from "common/Constants"

//Import Breadcrumb

import { Form, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
const AddFarmer = () => {
  //meta title

  const navigate = useNavigate()

  const reactAppUrl = process.env.REACT_APP_ALLURL

  const [customchkPrimary, setcustomchkPrimary] = useState(true)
  const [customchkSuccess, setcustomchkSuccess] = useState(true)
  const [customchkInfo, setcustomchkInfo] = useState(true)
  const [customchkWarning, setcustomchkWarning] = useState(true)
  const [customchkDanger, setcustomchkDanger] = useState(true)
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true)
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true)
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true)
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: "",
      Address: "",
      Village: "",
      District: "",
      City: "",
      Variety: "",
      Telephone: "",
      BusinessName: "",
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .min(2, "कमीत कमी 2 अक्षर टाका ")
        .required("कृपया आपले नाव टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
      BusinessName: Yup.string()
        .min(2, "कमीत कमी 2 अक्षर टाका ")
        .required("कृपया व्यवसायाचे नाव टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
      Address: Yup.string()
        .min(3, "कमीत कमी 3 अक्षर टाका ")
        .required("कृपया तुमचा पत्ता टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
      Telephone: Yup.string()
        .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .required("कृपया  फोन नं. टाका"),
    }),
    onSubmit: async values => {
      try {
        let data = {
          name: values.Name,
          address: values.Address,
          business_name: values.BusinessName,
          phone_no: values.Telephone,
        }

        setIsLoading(true)
        const response = await axios.post(
          `${reactAppUrl}/createMerchant`,
          data,
          getHeader()
        )
        setIsLoading(false)

        if (response && response.status == 200) {
          toast.success(response.data.message)
          navigate("/farmer/Listindex")
        }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status == 400) {
          toast.error(error.response.data.message[0])
        }
        console.log(error)
      }
    },
  })

  // to check the phone no start
  const [isPhoneChecking, setIsPhonechecking] = useState(false)
  const checkPhoneNo = async () => {
    try {
      setIsPhonechecking(true)
      const response = await getRequest(`checksPhoneNoMerchant?phone_no=${validation.values.Telephone}`)
      setIsPhonechecking(false)
      // toast.error(response?.data?.message)
    } catch (error) {
      setIsPhonechecking(false)
      console.log(error)
    }
  }
  // to check the phone no end

  return (
    <div>
      {/* <div className="row">
        <div className="col" style={{ padding: "0" }}> */}
      {/* <div className="card">
            <div className="card-body">
            */}
      <form
        className="needs-validation mt-4"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div className="row mb-3">
          <label
            htmlFor="example-tel-input"
            className="col-md-2 col-form-label"
          >
            फोन नं*
          </label>
          <div className="col-md-10">
            <input
              id="Telephone"
              name="Telephone"
              className="form-control"
              type="number"
              onChange={validation.handleChange}
              onBlur={e => {
                validation.handleBlur(e)
                checkPhoneNo()
              }}
              value={validation.values.Telephone}
            />
            {validation.touched.Telephone && validation.errors.Telephone ? (
              <span className="error">{validation.errors.Telephone}</span>
            ) : null}
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-md-2 col-form-label">नाव*</label>
          <div className="col-md-10">
            <input
              id="Name"
              name="Name"
              className="form-control"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.Name}
            />
            {validation.touched.Name && validation.errors.Name ? (
              <span className="error">{validation.errors.Name}</span>
            ) : null}
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-md-2 col-form-label">व्यवसायाचे नाव*</label>
          <div className="col-md-10">
            <input
              id="BusinessName"
              name="BusinessName"
              className="form-control"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.BusinessName}
            />
            {validation.touched.BusinessName &&
            validation.errors.BusinessName ? (
              <span className="error">{validation.errors.BusinessName}</span>
            ) : null}
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-md-2 col-form-label">पत्ता*</label>
          <div className="col-md-10">
            <textarea
              id="Address"
              name="Address"
              className="form-control"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.Address}
            />
            {validation.touched.Address && validation.errors.Address ? (
              <span className="error">{validation.errors.Address}</span>
            ) : null}
          </div>
        </div>

        {/* <div className="row mb-3">
                  <label
                    htmlFor="example-tel-input"
                    className="col-md-2 col-form-label"
                  >
                    फोन नं*
                  </label>
                  <div className="col-md-10">
                    <input
                      id="Telephone"
                      name="Telephone"
                      className="form-control"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.Telephone}
                    />
                    {validation.touched.Telephone &&
                    validation.errors.Telephone ? (
                      <span className="error">
                        {validation.errors.Telephone}
                      </span>
                    ) : null}
                  </div>
                </div> */}

        <div className="row mb-3" style={{ justifyContent: "flex-end" }}>
          <div className="col-md-2" style={{ textAlign: "right" }}>
            <Button color="primary" type="submit" disabled={isLoading || isPhoneChecking}>
              {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
            </Button>
          </div>
        </div>
      </form>
      {/* </div>
          </div> */}
      {/* </div>
      </div>*/}
    </div>
  )
}

export default AddFarmer
