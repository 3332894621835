import React, { useEffect, useState } from "react"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import Sidebar from "components/VerticalLayout/Sidebar"
import { Button } from "reactstrap"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Link, useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  getHeader,
  getRequest,
  postRequest,
  responsecode200,
  responsecode408,
} from "common/Constants"

const Editothers = () => {

  const location = useLocation()

  const reactAppUrl = process.env.REACT_APP_ALLURL
  const navigate = useNavigate()

  // to get the category list data start

  const [isLoading, setIsLoading] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const getCategoryList = async () => {
    try {
      const responseData = await getRequest(`categoryList`)
      if (responseData && responseData.status == 200) {
        setCategoryList(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const [id, setId] = useState(location?.state?.id)
  const [category, setCategory] = useState([])
  const getCategory = async () => {
    try {
      const responseData = await getRequest(`showOther?otherId=${id}`)
      if (responseData && responseData.status == 200) {
        setCategory(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: category.otherName || "",
      Category: category.categoryId || "",
      phone: category.phoneNo || "",
      Address: category.address || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("कृपया आपले नाव टाका"),
      Address: Yup.string().required("कृपया तुमचा पत्ता टाका"),
      Category: Yup.string().required("कृपया  प्रवर्ग टाका"),
      phone: Yup.string().required("कृपया  फोन नं. टाका").min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा").max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा"),
    }),
    onSubmit: async values => {
      try {
        const data = {
          otherName: values.name,
          categoryId: values.Category,
          address: values.Address,
          phoneNo: values.phone,
          otherId :id,
        }

        setIsLoading(true)
        const responseData = await postRequest(`updateOther`, data)
        setIsLoading(false)
        navigate("/farmer/Listindex") 
      } catch (error) {
        setIsLoading(false)
        console.log(error)
        if (error.response && error.response.status == responsecode408()) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.response.data.message)
        }
      }
    },
  })

  useEffect(() => {
    getCategoryList()
    getCategory()
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="इतर" />

              <div className="row">
                <div className="col" style={{ padding: "0" }}>
                  <div className="card">
                    <div className="card-body">
                      <form
                        className="needs-validation"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            नाव*
                          </label>
                          <div className="col-md-10">
                            <input
                              id="name"
                              name="name"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name}
                            />
                            {validation.touched.name &&
                              validation.errors.name ? (
                              <span className="error">
                                {validation.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-6 mb-3">
                            <div className="row">
                              <label className="col-md-4 col-form-label">
                                प्रवर्ग*{" "}
                              </label>
                              <div className="col-md-8">
                                <select
                                  id="Category"
                                  name="Category"
                                  className="form-select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Category}
                                >
                                  <option value="">select</option>
                                  {categoryList &&
                                    categoryList.map((Category, index) => {
                                      return (
                                        <option
                                          value={Category.categoryId}
                                          key={index}
                                        >
                                          {Category.catName}
                                        </option>
                                      )
                                    })}
                                </select>
                                {validation.touched.Category &&
                                  validation.errors.Category ? (
                                  <span className="error">
                                    {validation.errors.Category}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="row">
                              <label className="col-md-2 col-form-label">
                                फोन नं.*
                              </label>
                              <div className="col-md-10">
                                <input
                                  id="phone"
                                  name="phone"
                                  className="form-control"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.phone}
                                />
                                {validation.touched.phone &&
                                  validation.errors.phone ? (
                                  <span className="error">
                                    {validation.errors.phone}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-12 col-form-label">
                            पत्ता*
                          </label>
                          <div className="col-md-10 col-12">
                            <textarea
                              id="address"
                              name="Address"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Address}
                            />
                            {validation.touched.Address &&
                              validation.errors.Address ? (
                              <span className="error">
                                {validation.errors.Address}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2 col-5"
                            style={{ textAlign: "left" }}
                          >
                            <Link
                              to="/farmer/Listindex"
                              className="btn btn-danger"
                            >
                              मागे जा
                            </Link>
                          </div>
                          <div
                            className="col-md-2 col-7"
                            style={{ textAlign: "right" }}
                          >
                            <Button color="primary" type="submit" disabled={isLoading}>
                              {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Editothers
