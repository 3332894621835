import React, { useEffect, useState } from "react"
import "../../assets/css/style.css"
import { Link, useLocation, useNavigate } from "react-router-dom"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest, postRequest } from "common/Constants"
import { Pagination } from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"

const Editdistrict = () => {
  const navigate = useNavigate()
  //meta title
  document.title = "Samarth Fruit Company"

  const location = useLocation()
  const [id, setId] = useState(location?.state?.id)
  const [isLoading, setIsLoading] = useState(false)

  // to get the district detail start
  const [districtData, setDistrictData] = useState([])
  const getDistrict = async () => {
    try {
      setIsLoading(true)
      const response = await getRequest(`showDistrict?district_id=${id}`)
      setIsLoading(false)
      setDistrictData(response?.data?.data)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  // to get the district detail end

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      district: isLoading ? "Fetching..." : districtData.name,
    },
    validationSchema: Yup.object({
      district: Yup.string()
        .min(2, "कमीत कमी 2 अक्षर टाका ")
        .required("कृपया जिल्हा टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
    }),
    onSubmit: async values => {

      try {
        let data = {
          district_id: id,
          name: values.district,
        }
        const responseData = await postRequest(`editDistrict`, data)

        if (responseData && responseData.status == 200) {
          // toast.success(responseData.data.message))
          navigate("/Information")
        }
      } catch (error) {
        console.log(error)
      }
    },
  })

  useEffect(() => {
    getDistrict()
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="एडिट जिल्हा" />
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row">
                      <div className="col-md-5 mb-3">
                        <div className="form-group">
                          <label>जिल्हा</label>
                          <input
                            type="text"
                            id="district"
                            className="form-control"
                            name="district"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.district}
                          />
                          {validation.touched.district &&
                          validation.errors.district ? (
                            <span className="error">
                              {validation.errors.district}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        className="col-md-2 col-5"
                        style={{ textAlign: "left" }}
                      >
                        <Link to="/Information" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div
                        className="col-md-2 col-7"
                        style={{ textAlign: "right" }}
                      >
                        <button
                          type="submit"
                          color="primary"
                          className="w-md btn btn-primary"
                        >
                          एडिट
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editdistrict
