import React, { useEffect } from "react"
import { useState } from "react"
import { Button, Label } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

// import { Link } from "react-router-dom";
// import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest, getRole } from "common/Constants"

const Farmer = () => {
  const [startDateSales, setStartDateSales] = useState("")
  const [startDatePurchase, setStartDatePurchase] = useState("")
  const [EndDateSales, setEndDateSales] = useState("")
  const [EndDatePurchase, setEndDatePurchase] = useState("")
  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }

  //meta title
  document.title = "Samarth Fruit Company"

  // to get the sales dashboard data start

  const [isLoadingSales, setIsLoadingSales] = useState(false)
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false)

  // to get the sales dashboard data end

  const [totalMerchants, setTotalMerchants] = useState(null)
  const [totalSalesWeight, setTotalSalesWeight] = useState(null)
  const [totalSalesRate, setTotalSalesRate] = useState(null)
  const [overAllSalesRate, setOverAllSalesRate] = useState(null)
  const getDashboardSalesData = async () => {
    setIsLoadingSales(true)

    const responseData = await getRequest(
      `sellDashboardDetail?start_date=${startDateSales}&end_date=${EndDateSales}`
    )
    setIsLoadingSales(false)
    if (responseData && responseData.status == 200) {
      setTotalMerchants(responseData.data.merchant_count)
      setTotalSalesWeight(responseData.data.total_weight)
      setTotalSalesRate(responseData.data.total_rate)
      setOverAllSalesRate(responseData.data.overall_total)
    }
  }

  // to get the purchase dashboard data start

  const [totalPurchaseFarmer, setTotalPurchaseFarmer] = useState(null)
  const [totalWeight, setTotalWeight] = useState(null)
  const [totalRate, setTotalRate] = useState(null)
  const [overAllRate, setOverAllRate] = useState(null)
  const getDashboardPurchaseData = async () => {
    setIsLoadingPurchase(true)
    const responseData = await getRequest(
      `purchaseDashboardDetail?start_date=${startDatePurchase}&end_date=${EndDatePurchase}`
    )
    setIsLoadingPurchase(false)
    if (responseData && responseData.status == 200) {
      setTotalPurchaseFarmer(responseData.data.farmer_count)
      setTotalWeight(responseData.data.total_weight)
      setTotalRate(responseData.data.total_rate)
      setOverAllRate(responseData.data.overall_total)
    }
  }
  // to get the purchase dashboard data start

  useEffect(() => {
    getDashboardPurchaseData()
    getDashboardSalesData()
  }, [])

  const Role = getRole()
  return (
    <div>
      {Role == 1 ? (
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="mb-4 h4 card-title">मागील विक्री ऑर्डर</div>

                <form>
                  <div className="row mb-4">
                    <div className="col-sm-3 col-xl-3">
                      <div className="form-group mb-3">
                        <Label className="form-label">या तारखे पासून </Label>
                        {/* <DatePicker
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          className="form-control"
                          dateFormat="d-MM-yyyy"
                          placeholderText="Select date"
                        /> */}
                        <input
                          className="form-control"
                          type="date"
                          name="startDate"
                          id="example-month-input month"
                          onChange={e => setStartDateSales(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xl-3">
                      <div className="form-group mb-3">
                        <Label className="form-label">या तारखे पर्यंत </Label>
                        {/* <DatePicker
                          selected={EndDate}
                          onChange={date => setEndDate(date)}
                          className="form-control"
                          dateFormat="d-MM-yyyy"
                          placeholderText="Select date"
                        /> */}
                        <input
                          className="form-control"
                          type="date"
                          name="startDate"
                          id="example-month-input month"
                          onChange={e => setEndDateSales(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 col-xl-3 align-self-end">
                      <div className="mb-3">
                        <Button
                          type="button"
                          color="primary"
                          className="w-md"
                          onClick={getDashboardSalesData}
                        >
                          फिल्टर
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
                {isLoadingSales ? (
                  <>
                    <h1>
                      <Skeleton />
                    </h1>
                    <h1>
                      <Skeleton />
                    </h1>
                  </>
                ) : (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <table
                        id="tech-companies-1"
                        className="table table-bordered table-hover"
                      >
                        <thead className="table-light table-nowrap">
                          <tr>
                            <th>एकूण व्यापारी</th>
                            <th>एकूण प्रमाण</th>

                            <th>सरासरी दर</th>

                            <th>एकूण महसूल</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{totalMerchants ? totalMerchants : "-"}</td>
                            <td>{totalSalesWeight ? totalSalesWeight : "-"}<span  className="pl-1">Kg</span></td>
                            <td><span className="pr-1">₹</span>{totalSalesRate ? totalSalesRate : "-"}</td>
                            <td><span className="pr-1">₹</span>{overAllSalesRate ? overAllSalesRate : "-"}</td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <ul className="pagination modal-1" style={{ float: "right" }}>
                    <li>
                      <a href="#" className="prev">
                        «
                      </a>
                    </li>
                    <li>
                      <a href="#" className="active">
                        1
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">2</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">3</a>
                    </li>

                    <li>
                      <a href="#" className="next">
                        »
                      </a>
                    </li>
                  </ul> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="mb-4 h4 card-title">मागील खरेदी ऑर्डर</div>

              <form>
                <div className="row mb-4">
                  <div className="col-sm-3 col-xl-3">
                    <div className="form-group mb-3">
                      <Label className="form-label">या तारखे पासून </Label>
                      {/* <DatePicker
                        selected={startDate}
                        onChange={date => setStartDatePurchase(date)}
                        className="form-control"
                        dateFormat="d-MM-yyyy"
                        placeholderText="Select date"
                      /> */}
                      <input
                        className="form-control"
                        type="date"
                         pattern="\d{2}/\d{2}/\d{4}" 
                         placeholder="DD/MM/YYYY"
                        name="startDate"
                        id="example-month-input month"
                        onChange={e => setStartDatePurchase(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-3 col-xl-3">
                    <div className="form-group mb-3">
                      <Label className="form-label">या तारखे पर्यंत </Label>
                      {/* <DatePicker
                        selected={EndDate}
                        onChange={date => setEndDatePurchase(date)}
                        className="form-control"
                        dateFormat="d-MM-yyyy"
                        placeholderText="Select date"
                      /> */}
                      <input
                        className="form-control"
                        type="date"
                        name="endDate"
                        pattern="\d{2}/\d{2}/\d{4}" 
                        placeholder="DD/MM/YYYY"
                        id="example-month-input month"
                        onChange={e => setEndDatePurchase(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-3 col-xl-3 align-self-end">
                    <div className="mb-3">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md"
                        onClick={getDashboardPurchaseData}
                      >
                        फिल्टर
                      </Button>
                    </div>
                  </div>
                </div>
              </form>

              {isLoadingPurchase ? (
                <>
                  <h1>
                    <Skeleton />
                  </h1>
                  <h1>
                    <Skeleton />
                  </h1>
                </>
              ) : (
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <table
                      id="tech-companies-1"
                      className="table table-bordered table-hover"
                    >
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th>एकूण शेतकरी</th>
                          <th>एकूण प्रमाण</th>

                          <th>सरासरी दर</th>

                          <th>शेतकऱ्यांना दिलेली एकूण रक्कम</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {totalPurchaseFarmer ? totalPurchaseFarmer : "-"}
                          </td>
                          <td>{totalWeight ? totalWeight : "-"}<span className="pl-1">Kg</span></td>
                          <td><span className="pr-1">₹</span>{totalRate ? totalRate : "-"}</td>
                          <td><span className="pr-1">₹</span>{overAllRate ? overAllRate : "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <ul className="pagination modal-1" style={{ float: "right" }}>
                    <li>
                      <a href="#" className="prev">
                        «
                      </a>
                    </li>
                    <li>
                      <a href="#" className="active">
                        1
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">2</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">3</a>
                    </li>

                    <li>
                      <a href="#" className="next">
                        »
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Farmer
