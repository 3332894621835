import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "../../assets/css/style.css"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Button } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import "react-toastify/dist/ReactToastify.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { getRequest, postRequest } from "common/Constants"
import axios from "axios";

import Select from "react-select"
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

const Editbusinesspayment = () => {

  const reactAppUrl = process.env.REACT_APP_ALLURL
 // hooks start
 const navigate = useNavigate()
 const location = useLocation()
 const {id} = useParams()
 // hooks end
 const [other, setOther] = useState([])
 const [isLoading, setIsLoading] = useState(false)
 const [personName, setPersonName] = useState([])
 const getAllOthers = async () => {
   const otherResponse = await getRequest(`dropdownOthers`) //api chnge
   setOther(otherResponse.data.data)
   const mappedData = otherResponse.data.data.map(item => ({
    value: item.others_id,
    label: item.name,
  }))
  setPersonName(mappedData)
 }

 // category api

 const [otherc, setOtherc] = useState([])
 const [categoryName, setCategoryName] = useState([])
  const getAllOthersc = async () => {
    const othercResponse = await axios.get(`${reactAppUrl}/categoryList`) //api dropdown category
    setOtherc(othercResponse.data.data)
    const mappedData = othercResponse.data.data.map(item => ({
      value: item.categoryId,
      label: item.catName,
    }))
    setCategoryName(mappedData)
  }

// api get merchant
const [otherPaymentId, setOtherPaymentId] = useState(id)
const [otherPaymentData, setOtherPaymentData] = useState([])
const getOther = async () => {
  const otherResponse = await getRequest(`otherPayDetails?otherPayId=${otherPaymentId}`)   //api frmer
  setOtherPaymentData(otherResponse?.data)
}
useEffect(() => {
  getOther();
  getAllOthersc();
},[])

const validation = useFormik({
  // enableReinitialize : use this flag when initial values needs to be changed
  enableReinitialize: true,

  initialValues: {
    Name: otherPaymentData.otherId || "",
    Category: otherPaymentData.categoryId || "",
    Date: otherPaymentData.date || "",
    Amount: otherPaymentData.payment_mode || "",
    Paymentstatus: otherPaymentData.payment_type || "",
    Money: otherPaymentData.amount || "",
    Hetu: otherPaymentData.reason || "",
    Note: otherPaymentData.notes || "",
    
  },
  validationSchema: Yup.object({
    Name: Yup.string().required("कृपया आपले नाव टाका"),
    Category: Yup.string().required("कृपया आपले नाव टाका"),  // chnge category marthi
    Date: Yup.string().required("कृपया तारीख टाका"),
    Amount: Yup.string().required("कृपया पेमेंट प्रकार टाका"),
    Paymentstatus: Yup.string().required("कृपया पेमेंट पेमेंट पद्धत टाका"),
    Money: Yup.string().required("कृपया रक्कम टाका"),
    Hetu: Yup.string().required("कृपया हेतू टाका"),
    Note: Yup.string().required("कृपया नोट टाका"),
  }),
  onSubmit: async values => {
    try {
      const data = {
        otherPayId: otherPaymentId,
        categoryId: values.Category,
        otherId: values.Name,
        date: values.Date,
        payment_mode: values.Amount,
        payment_type: values.Paymentstatus,
        amount: values.Money,
        reason: values.Hetu,
        notes: values.Note,
      }

      setIsLoading(true)
      const responseData = await postRequest(`updateOtherPay`, data)  //api bussness
      setIsLoading(false)

      if (responseData && responseData.status == 200) {
        navigate("/businesspayment")                                  //pge change
      } else if (responseData && responseData.status == 409) {
        navigate("/editbusinesspayment")                              // pge change
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  },
})


useEffect(() => {
  getAllOthers()
}, [])

useEffect(() => {
  // While detailInvoice page is active, the invoice tab must also activated
  let element = document.getElementById("paymentlist")
  let element2 = document.getElementById("payment-ul")
  if (element) {
    element.classList.add("mm-active") // Add the 'active' class to the element
  }
  if (element2) {
    element2.classList.add("mm-show")
  }
  return () => {
    if (element) {
      element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      element2.classList.remove("mm-show") // remove the 'show' class to the element when change to another page
    }
  }
}, [])
  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs
                title="डॅशबोर्ड"
                breadcrumbItem="एडिट इतर पेमेंट"
              />

              <div className="row">
                <div className="col" style={{ padding: "0" }}>
                  <div className="card">
                    <div className="card-body">
                      <form className="needs-validation"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}>
                      <div className="row mb-3">
                          <label className="col-md-2 col-form-label">नाव *</label>
                          <div className="col-md-10">
                            {/* <select
                              id="formrow-InputState variety"
                              name="Name"
                              className="form-select"
                              onChange={validation.handleChange}
                              // onChange={handleUsernameChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Name}
                            >
                              <option value="">Select</option>

                              {other &&
                                other.map((others, index) => {
                                  return (
                                    <option
                                      value={others?.other_id}
                                      key={index}
                                    >
                                      {others?.name}
                                    </option>
                                  )
                                })}
                            </select> */}
                            <Select
                              styles={customStyles}
                              id="formrow-InputState variety"
                              name="Name"
                              className="basic-single"
                              classNamePrefix="select"
                              options={personName}
                              onChange={selectedOption => {
                                validation.setFieldValue(
                                  "Name",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }}
                              onBlur={e => {
                                validation.handleBlur(e)
                              }}
                              value={personName.find(
                                option =>
                                  option.value === validation.values.Name
                              )}
                            />

                            {validation.touched.Name && validation.errors.Name ? (
                              <span className="error">
                                {validation.errors.Name}
                              </span>
                            ) : null}
                          </div>
                        </div>

                       
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            प्रवर्ग*
                          </label>
                          <div className="col-md-10">
                            {/* <select
                              id="Category"
                              name="Category"
                              className="form-select"
                              onChange={validation.handleChange}
                              // onChange={handleUsernameChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Category}
                            >
                              <option value="">Select</option>

                              {otherc &&
                                otherc.map((othersc, index) => {
                                  return (
                                    <option
                                      value={othersc?.categoryId}  //id
                                      key={index}
                                    >
                                      {othersc?.catName}
                                    </option>
                                  )
                                })}
                            </select> */}

<Select
                              styles={customStyles}
                              id="formrow-InputState variety"
                              name="Name"
                              className="basic-single"
                              classNamePrefix="select"
                              options={categoryName}
                              onChange={selectedOption => {
                                validation.setFieldValue(
                                  "Category",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }}
                              onBlur={e => {
                                validation.handleBlur(e)
                              }}
                              value={categoryName.find(
                                option =>
                                  option.value === validation.values.Category
                              )}
                            />

                            {validation.touched.Category &&
                              validation.errors.Category ? (
                              <span className="error">
                                {validation.errors.Category}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            तारीख*
                          </label>
                          <div className="col-md-10">
                            {/* <DatePicker
                              selected={startDate}
                              onChange={date => setStartDate(date)}
                              className="form-control"
                              dateFormat="d-MM-yyyy"
                              placeholderText="Select date"
                              required="required"
                            /> */}

                            <input
                              className="form-control"
                              type="date"
                              name="Date"
                              id="example-month-input month"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Date}
                            />
                            {validation.touched.Date && validation.errors.Date ? (
                              <span className="error">
                                {validation.errors.Date}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            पेमेंट प्रकार*
                          </label>
                          <div className="col-md-10">
                            <select
                              id="Amount"
                              name="Amount"
                              className="form-select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Amount}

                            >
                              <option value="">Select</option>
                              <option value="1">डेबिट</option>
                              <option value="2">क्रेडिट</option>
                            </select>
                            {validation.touched.Amount && validation.errors.Amount ? (
                              <span className="error">
                                {validation.errors.Amount}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            पेमेंट पद्धत(कॅश/बँकिंग)*
                          </label>
                          <div className="col-md-10">
                            <select
                              id="formrow-InputState payment"
                              name="Paymentstatus"
                              className="form-select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Paymentstatus}
                            >
                              <option value="">Select</option>
                              <option value="1">कॅश</option>
                              <option value="2">बँकिंग</option>
                            </select>
                            {validation.touched.Paymentstatus && validation.errors.Paymentstatus ? (
                              <span className="error">
                                {validation.errors.Paymentstatus}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            रक्कम*
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Money"
                              name="Money"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Money}
                            />
                            {validation.touched.Money && validation.errors.Money ? (
                              <span className="error">
                                {validation.errors.Money}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            हेतू*
                          </label>
                          <div className="col-md-10">
                            <input
                              id="hetu"
                              name="Hetu"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Hetu}
                            />
                            {validation.touched.Hetu && validation.errors.Hetu ? (
                              <span className="error">
                                {validation.errors.Hetu}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            नोट
                          </label>
                          <div className="col-md-10">
                            <textarea
                              id="Note"
                              name="Note"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Note}
                            />
                            {validation.touched.Note && validation.errors.Note ? (
                              <span className="error">
                                {validation.errors.Note}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2 col-5"
                            style={{ textAlign: "left" }}
                          >
                            <Link
                              to="/businesspayment"
                              className="btn btn-danger"
                            >
                              मागे जा
                            </Link>
                          </div>
                          <div
                            className="col-md-2 col-7"
                            style={{ textAlign: "right" }}
                          >
                            <Button color="primary" type="submit" disabled={isLoading}>
                              {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Editbusinesspayment
