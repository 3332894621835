import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

// import ApexRadial from "./ApexRadial"
import { getRequest } from "common/Constants"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const MonthlyEarning = () => {
  // hooks start
  const navigate = useNavigate()
  // hooks end

  const [isLoading, setIsLoading] = useState(false)

  // get month data start
  const [date, setDate] = useState("")
  const getMonth = e => {
    setDate(e.target.value)
  }
  // get month data end

  // to get the profit data start

  const [profitLoss, setProfitLoss] = useState([])
  const getProfitLoss = async () => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `profitLossInfoDashboard?date=${date}`
      )
      setIsLoading(false)
      if (responseData && responseData.status == 200) {
        setProfitLoss(responseData.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the profit data end

  useEffect(() => {
    getProfitLoss()
  }, [date])

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <div className="monthly-title card-title mb-4 d-flex">
            <span className="">निव्वळ नफा</span>
            <input type="month" className="form-control" onChange={getMonth} />
          </div>
          <Row>
            {isLoading ? (
              <>
                <h1>
                  <Skeleton />
                </h1>
                <h1>
                  <Skeleton />
                </h1>
                <h1>
                  <Skeleton />
                </h1>
              </>
            ) : (
              <Col sm="6">
                <h5 className="mb-3">
                  ग्रॉस प्रॉफिट -<span>₹{profitLoss.gross_profit}</span>
                </h5>
                <h5 className="mb-3">
                  खर्च - <span>₹{profitLoss.debit_money}</span>
                </h5>

                <h5 className="mb-3">
                  नेट प्रॉफिट -<span>₹{profitLoss.net_profit}</span>
                </h5>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarning
