import React, { useEffect, useState } from "react"
import "../../assets/css/style.css"
import { Button, Label } from "reactstrap"

import Modal from "react-bootstrap/Modal"

// import { Link } from "react-router-dom";
// import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { Pagination } from "@mui/material"
import moment from "moment"
import { getRequest } from "common/Constants"
const Farmerleadger = () => {
  const [show, setShow] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [name, setName] = useState("")

  const handleClose = () => setShow(false)
  document.title = "Samarth Fruit Company"

  const navigate = useNavigate()

  const [totalCount, setTotalCount] = useState(0)

  const [leadgerPay, setLeadgerPay] = useState([])
  const getLeadgerPay = async () => {
    try {
      setIsLoading(true)
      const leadgerResponse = await getRequest(
        `ledgerFarmerList?page=${page}&name=${name}&start_date=${startDate}&end_date=${endDate}`
      ) //api
      setIsLoading(false)
      setLeadgerPay(leadgerResponse.data.data)
      let totalPages = leadgerResponse.data.total / leadgerResponse.data.perPage
      setTotalCount(Math.ceil(totalPages))
      setPerPageItem(leadgerResponse.data.perPage)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }
  const [deleteId, setDeleteId] = useState("")
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  const [perPageItem, setPerPageItem] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `farmerPayDelete?farmerPayId=${deleteId}` //api
      )
      setIsLoading(false)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  //   to go to the view page with the id start
  const handleView = id => {
    try {
      navigate(`/viewfarmerleadger/${id}`)
    } catch (error) {
      console.log(error)
    }
  }
  //   to go to the view page with the id end

  useEffect(() => {
    getLeadgerPay()
  }, [render, page])
  useEffect(() => {
    // While detailInvoice page is active, the invoice tab must also activated
    let element = document.getElementById("leadegerlist")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="शेतकरी लेडजर" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="row mb-3 mt-3"
                        style={{ justifyContent: "flex-end" }}
                      ></div>
                      <div className="row mb-3 mt-3">
                        {/* <div className="col-md-2 mb-3" style={{ textAlign: "right" }}>
                      <Link to="/Addfarmer" type="button" class="btn-add btn-sm  btn btn-primary"></Link>
                    </div> */}
                        <div className="col-md-10">
                          <form>
                            <div className="row">
                              <div className="col-sm-3 col-xl-3">
                                <div className="form-group mb-3">
                                  <label className="form-label">नाव</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="नाव"
                                    onChange={e => {
                                      setName(e.target.value)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    या तारखे पासून
                                  </Label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e => setStartDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    या तारखे पर्यंत
                                  </Label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e => setEndDate(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-sm-3 col-xl-3  mt-0 filter-button">
                                <div>
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="w-md"
                                    onClick={() => {
                                      setPage(1)
                                      getLeadgerPay()
                                    }}
                                  >
                                    फिल्टर
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {isLoading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                        </>
                      ) : (
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th>अ.क्र.</th>
                                  <th>नाव</th>
                                  <th>एकूण रक्कम </th>
                                  <th>तारीख</th>
                                  <th>हेतू </th>
                                </tr>
                              </thead>

                              {leadgerPay && leadgerPay.length > 0 ? (
                                leadgerPay.map((item, index) => {
                                  return (
                                    <tbody key={index}>
                                      <tr>
                                        <td>
                                          {page * perPageItem -
                                            perPageItem +
                                            (index + 1)}
                                        </td>
                                        <td>{item?.name}</td>
                                        <td>{item?.total_amount}</td>
                                        <td>{!!item?.latest_date ? moment(item?.latest_date).format(
                                            "DD/MM/YYYY"
                                          ) : "-"}</td>
                                        <td>{item?.reason}</td>

                                        <td>
                                          <a
                                            className="btn-sm btn-view  btn"
                                            // href="/viewfarmerleadger"
                                            onClick={e => handleView(item?.id)}
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bx-file align-middle ">
                                                <span className="tooltiptext">
                                                  पहा
                                                </span>
                                              </i>
                                            </div>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan="6">
                                    <div className="nodata">
                                      <b>Data Not Available</b>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </table>
                          </div>
                          <ul
                            className="pagination modal-1"
                            style={{ float: "right" }}
                          >
                            <Pagination
                              count={totalCount}
                              page={page}
                              onChange={handleChange}
                              color="primary"
                              shape="rounded"
                            />
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>इतर पेमेंट</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Farmerleadger
