import React, { useEffect, useState } from "react"
import "../../assets/css/style.css"
import { Link, useNavigate } from "react-router-dom"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest, postRequest } from "common/Constants"
import { Pagination } from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import Modal from "react-bootstrap/Modal"
import { Button } from "reactstrap"

const District = () => {
  const navigate = useNavigate()
  //meta title
  document.title = "Samarth Fruit Company"

  // for pagination start

  const [perPageItem, setPerPageItem] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose1 = () => {
    setOpen(false)
  }

  // for pagination end

  // react bootstrap modal start

  const [deleteId, setDeleteId] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  // react bootstrap modal end

  // To Edit the district start

  const handleEdit = id => {
    try {
      // navigate({pathname:"/EditBusiness",state:id})
      navigate("/Editdistrict", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the district end

  // To delete the sistrict start

  const [render, setRender] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const handleDelete = async id => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `deleteDistrict?district_id=${deleteId}`
      )
      setIsLoading(false)

      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  // To delete the district end

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      district: "",
    },
    validationSchema: Yup.object({
      district: Yup.string()
        .min(2, "कमीत कमी 2 अक्षर टाका ")
        .required("कृपया जिल्हा टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
    }),
    onSubmit: async values => {

      try {
        let data = {
          name: values.district,
        }
        const responseData = await postRequest(`addDistrict`, data)

        if (responseData && responseData.status == 200) {
          // toast.success(responseData.data.message)
          var element = document.getElementById("district")
          element.value = ""
          setRender(!render)
          navigate("/Information")
        }
      } catch (error) {
        console.log(error)
      }
    },
  })

  const [districtList, setDistrictList] = useState([])
  const getDistrictList = async () => {
    const response = await getRequest(`districtList?page=${page}`)
    if (response && response.status == 200) {
      setDistrictList(response?.data?.data)
      let totalPages = response.data.total / response.data.perPage
      setTotalCount(Math.ceil(totalPages))
      setPerPageItem(response.data.perPage)
    }
  }

  useEffect(() => {
    getDistrictList()
  }, [page, render])

  return (
    <div>
      {/* <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="जिल्हा" />
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body"> */}
                  <form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label>जिल्हा</label>
                          <input
                            name="district"
                            type="text"
                            className="form-control"
                            id="district"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            // value={validation.values.district}
                          />
                          {validation.touched.district &&
                          validation.errors.district ? (
                            <span className="error">
                              {validation.errors.district}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 mb-3 filter-button">
                        <div className="mt-lg-4 mt-md-3 mt-0">
                          <button
                            type="submit"
                            color="primary"
                            className="w-md btn btn-primary"
                          >
                            ऍड
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="table-rep-plugin mt-3">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>अ.क्र.</th>
                              <th>जिल्हा</th>
                              <th></th>
                            </tr>
                          </thead>
                          {districtList.length > 0 &&
                            districtList.map((item, index) => {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td>
                                      {page * perPageItem -
                                        perPageItem +
                                        (index + 1)}
                                    </td>
                                    <td>{item.name}</td>
                                    <td>
                                      <a
                                        className="btn-sm btn-edit  btn"
                                        onClick={e => handleEdit(item?.district_id)}
                                      >
                                        <div className="tooltip1">
                                          <i className="bx bx-edit align-middle">
                                            <span className="tooltiptext">
                                              संपादित करा
                                            </span>
                                          </i>
                                        </div>
                                      </a>
                                      <a
                                        className="btn-sm btn-delete btn"
                                        onClick={() =>
                                          handleShow(item?.district_id)
                                        }
                                      >
                                        <div className="tooltip1">
                                          <i className="bx bxs-trash align-middle">
                                            <span className="tooltiptext">
                                              हटवा
                                            </span>
                                          </i>
                                        </div>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })}
                        </table>
                      </div>
                      <ul
                        className="pagination modal-1"
                        style={{ float: "right" }}
                      >
                        <Pagination
                          color="primary"
                          shape="rounded"
                          count={totalCount}
                          page={page}
                          onChange={handleChange}
                        />
                      </ul>
                    </div>
                  </form>
                {/* </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>जिल्हा हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            className="btn btn-success"
            onClick={handleDelete}
            disabled = {isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button
            variant="danger"
            className="btn btn-danger"
            onClick={handleClose}
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default District
