import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import Footer from "components/VerticalLayout/Footer";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Report2 = () => {
  //meta title
  document.title = "Samarth Fruit Company";
  return (
    <div>
       <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>
     
        <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="अहवाल" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-3">
                   <div className="col-md-3">
                   <div className="form-group">
                   <label
                      className="col-form-label"
                    >
                     लागवडीची तारीख
                    </label>
                   
                      <input
                        className="form-control"
                        type="date"
                       name="date1"
                        id="example-date-input date1"
                          />
                        </div>
                        </div>
                        <div className="col-md-3">
                        <div className="form-group">
                        <label
                      className="col-form-label"
                    >
                      काढणी तारीख
                    </label>
                   
                      <input
                        className="form-control"
                        type="date"
                        name="date2"
                        id="example-date-input date2"
                        />
                    </div>
                        </div>
                        <div className="col-md-3">
                        <a className="btn btn-save btn-primary" href="javascript(void:0)"><i className="bx bx-search-alt align-middle"></i> Search</a>
                            </div>
                  </div>

                  <div className="">
                    <div
                      className="table-responsive mb-0"
                   
                    >
                      <table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>अ.क्र.</th>
                            <th>
                              नाव
                            </th>
                            <th>
                              पत्ता
                            </th>
                            <th>फोन नं</th>
                            <th>व्हरायटी</th>
                            <th>लागवड क्षेत्र (एकर मध्ये )</th>
                            <th>झाडांची संख्या</th>
                            <th>अंदाजे वजन (टन मध्ये)</th>
                            <th>लागवडीची तारीख</th>
                            <th>काढणी तारीख</th>
                            <th>तोडणी वारंवारिता</th>
                            <th>तोडणी महिने</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>रुचिता सोनार</td>
                            <td>
                              सावत नगर, नाशिक</td>

                            <td>9156528394</td>
                            <td>आमराई</td>
                            <td>4 एकर</td>
                            <td>8 रांगांत 128 झाडे</td>
                            <td>2 टन वजन</td>
                            <td>23-02-2022</td>
                            <td>23-04-2023</td>
                            <td>fvgnbm</td>
                            <td>एप्रिल 2023</td>
                            <td><Link to="/#" type="button" className="btn-sm btn-view  btn "><div className="tooltip1"><i className="bx bx-file align-middle "><span className="tooltiptext">पहा</span></i></div></Link>
                              <Link to="/#" type="button" className="btn-sm btn-edit  btn "><div className="tooltip1"><i className="bx bx-edit align-middle"><span className="tooltiptext">संपादित करा</span></i></div></Link></td>
                          </tr>
                          <tr>
                          <td>2</td>
                            <td>रुचिता सोनार</td>
                            <td>
                              सावत नगर, नाशिक</td>

                            <td>9156528394</td>
                            <td>आमराई</td>
                            <td>4 एकर</td>
                            <td>8 रांगांत 128 झाडे</td>
                            <td>2 टन वजन</td>
                            <td>23-02-2022</td>
                            <td>23-04-2023</td>
                            <td>fvgnbm</td>
                            <td>एप्रिल 2023</td>

                            <td><Link to="/#" type="button" className="btn-sm btn-view  btn "><div className="tooltip1"><i className="bx bx-file align-middle "><span className="tooltiptext">पहा</span></i></div></Link>
                              <Link to="/#" type="button" className="btn-sm btn-edit  btn "><div className="tooltip1"><i className="bx bx-edit align-middle"><span className="tooltiptext">संपादित करा</span></i></div></Link></td>
                          </tr>
                          <tr>
                          <td>3</td>
                            <td>रुचिता सोनार</td>
                            <td>
                              सावत नगर, नाशिक</td>

                            <td>9156528394</td>
                            <td>आमराई</td>
                            <td>4 एकर</td>
                            <td>8 रांगांत 128 झाडे</td>
                            <td>2 टन वजन</td>
                            <td>23-02-2022</td>
                            <td>23-04-2023</td>
                            <td>fvgnbm</td>
                            <td>एप्रिल 2023</td>

                            <td><Link to="/#" type="button" className="btn-sm btn-view  btn "><div className="tooltip1"><i className="bx bx-file align-middle "><span className="tooltiptext">पहा</span></i></div></Link>
                              <Link to="/#" type="button" className="btn-sm btn-edit  btn "><div className="tooltip1"><i className="bx bx-edit align-middle"><span className="tooltiptext">संपादित करा</span></i></div></Link></td>
                          </tr>
                          <tr>
                          <td>4</td>
                            <td>रुचिता सोनार</td>
                            <td>
                              सावत नगर, नाशिक</td>

                            <td>9156528394</td>
                            <td>आमराई</td>
                            <td>4 एकर</td>
                            <td>8 रांगांत 128 झाडे</td>
                            <td>2 टन वजन</td>
                            <td>23-02-2022</td>
                            <td>23-04-2023</td>
                            <td>fvgnbm</td>
                            <td>एप्रिल 2023</td>

                            <td><Link to="/#" type="button" className="btn-sm btn-view  btn "><div className="tooltip1"><i className="bx bx-file align-middle "><span className="tooltiptext">पहा</span></i></div></Link>
                              <Link to="/#" type="button" className="btn-sm btn-edit  btn "><div className="tooltip1"><i className="bx bx-edit align-middle"><span className="tooltiptext">संपादित करा</span></i></div></Link></td>
                          </tr>
                          <tr>
                          <td>5</td>
                            <td>रुचिता सोनार</td>
                            <td>
                              सावत नगर, नाशिक</td>

                            <td>9156528394</td>
                            <td>आमराई</td>
                            <td>4 एकर</td>
                            <td>8 रांगांत 128 झाडे</td>
                            <td>2 टन वजन</td>
                            <td>23-02-2022</td>
                            <td>23-04-2023</td>
                            <td>fvgnbm</td>
                            <td>एप्रिल 2023</td>

                            <td><Link to="/#" type="button" className="btn-sm btn-view  btn "><div className="tooltip1"><i className="bx bx-file align-middle "><span className="tooltiptext">पहा</span></i></div></Link>
                              <Link to="/#" type="button" className="btn-sm btn-edit  btn "><div className="tooltip1"><i className="bx bx-edit align-middle"><span className="tooltiptext">संपादित करा</span></i></div></Link></td>
                          </tr>
                        </tbody>
                      </table>
                      
                  </div>
                  <ul className="pagination modal-1" style={{float:"right"}}>
  <li><a href="#" className="prev">«</a></li>
  <li><a href="#" className="active">1</a></li>
  <li> <a href="#">2</a></li>
  <li> <a href="#">3</a></li>
  
  <li><a href="#" className="next">»</a></li>
</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    </div>
    </div>
    </div>
  );
};

export default Report2;
