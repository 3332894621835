import React, { useEffect, useState } from "react"

import "../../assets/css/style.css"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest } from "common/Constants"
import { Pagination } from "@mui/material"
import moment from "moment"

const Purchaseorder = () => {
  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  //   hooks start
  const navigate = useNavigate()
  //   hooks end

  const [isLoading, setIsLoading] = useState(false)

  // to get the purchaseOrder data start
  const [purchaseOrderList, setPurchaseOrderList] = useState([])
  const [fromPurchaseDate, setFromPurchaseDate] = useState("")
  const [toPurchaseDate, setToPurchaseDate] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const [perPageItem, setPerPageItem] = useState(0)

  const getPurchaseOrder = async () => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `purchaseOrderList?page=${page}&from_date=${fromPurchaseDate}&to_date=${toPurchaseDate}&name=${name}&phone_no=${phone}`
      )
      setIsLoading(false)
      if (responseData && responseData.status == 200) {
        setPurchaseOrderList(responseData.data.data)
        let totalPages = responseData.data.total / responseData.data.perPage
        setTotalCount(Math.ceil(totalPages))
        setPerPageItem(responseData.data.perPage)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the purchaseOrder data end

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  //   to go to the view page with the id start
  const handleView = id => {
    try {
      navigate("/Viewpurchase", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }
  //   to go to the view page with the id end

  // To delete the merchant detail start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {

      //   const responseData = await getRequest(
      //     `sellInvoiceDelete?sell_invoice_id=${id}`
      //   )

      setRender(!render)
    } catch (error) {
      console.log(error)
    }
  }

  // To delete the merchant detail end

  useEffect(() => {
    getPurchaseOrder()
  }, [page, render])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="खरेदी ऑर्डर" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <form className="mt-3">
                        <div className="row mb-4">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-sm-3 col-xl-3">
                                <div className="form-group mb-3">
                                  <label className="form-label">नाव</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="नाव"
                                    onChange={e => {
                                      setName(e.target.value)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3">
                                <div className="form-group mb-3">
                                  <label className="form-label">फोन नं.</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="फोन नं."
                                    minLength={10}
                                    maxLength={10}
                                    onChange={e => {
                                      setPhone(e.target.value)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3">
                                <div className="form-group mb-3">
                                  <label className="form-label">
                                    या तारखे पासून
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e =>
                                      setFromPurchaseDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3">
                                <div className="form-group mb-3">
                                  <label className="form-label">
                                    या तारखे पर्यंत
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e =>
                                      setToPurchaseDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-2 col-xl-2 align-self-end">
                            <div className="mb-3">
                              <Button
                                type="button"
                                color="primary"
                                className="w-md"
                                onClick={getPurchaseOrder}
                              >
                                फिल्टर
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>

                      {isLoading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                        </>
                      ) : purchaseOrderList && purchaseOrderList.length > 0 ? (
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th>अ.क्र.</th>
                                  <th>नाव</th>
                                  <th> पत्ता</th>
                                  <th>फोन नं</th>
                                  <th>व्हरायटी</th>
                                  <th>अंदाजे वजन</th>
                                  <th>खरेदी तारीख</th>
                                  <th>नोट </th>
                                  <th></th>
                                </tr>
                              </thead>

                              {purchaseOrderList &&
                                purchaseOrderList.map(
                                  (purchaseOrder, index) => {
                                    return (
                                      <tbody key={index}>
                                        <tr>
                                          <td>
                                            {page * perPageItem -
                                              perPageItem +
                                              (index + 1)}
                                          </td>
                                          <td>
                                            {!!purchaseOrder?.name
                                              ? purchaseOrder.name
                                              : "-"}
                                          </td>
                                          <td>
                                            {!!purchaseOrder?.address
                                              ? purchaseOrder.address
                                              : "-"}
                                          </td>
                                          <td>
                                            {!!purchaseOrder?.phone_no
                                              ? purchaseOrder.phone_no
                                              : "-"}
                                          </td>
                                          <td>
                                            {!!purchaseOrder?.varierty
                                              ? purchaseOrder.varierty
                                              : "-"}
                                          </td>
                                          <td>{purchaseOrder?.weight} Kg</td>
                                          <td>
                                            {/* {!!purchaseOrder?.purchase_date
                                              ? purchaseOrder.purchase_date
                                              : "-"} */}
                                            {!!purchaseOrder?.purchase_date
                                              ? moment(
                                                  purchaseOrder?.purchase_date
                                                ).format("DD/MM/YYYY")
                                              : "-"}
                                          </td>
                                          <td>
                                            {!!purchaseOrder?.note
                                              ? purchaseOrder.note
                                              : "-"}
                                          </td>
                                          <td>
                                            <a
                                              className="btn-sm btn-view  btn"
                                              onClick={e =>
                                                handleView(
                                                  purchaseOrder?.purchase_order_id
                                                )
                                              }
                                            >
                                              <div className="tooltip1">
                                                <i className="bx bx-file align-middle">
                                                  <span className="tooltiptext">
                                                    पहा
                                                  </span>
                                                </i>
                                              </div>
                                            </a>

                                            {/* <a
                                              className="btn-sm btn-delete btn"
                                              onClick={e =>
                                                handleDelete(
                                                  purchaseOrder?.purchase_order_id
                                                )
                                              }
                                            >
                                              <div className="tooltip1">
                                                <i className="bx bxs-trash align-middle">
                                                  <span className="tooltiptext">
                                                    हटवा
                                                  </span>
                                                </i>
                                              </div>
                                            </a> */}
                                          </td>
                                        </tr>
                                      </tbody>
                                    )
                                  }
                                )}
                            </table>
                          </div>
                          <ul
                            className="pagination modal-1"
                            style={{ float: "right" }}
                          >
                            <Pagination
                              count={totalCount}
                              page={page}
                              onChange={handleChange}
                              color="primary"
                              shape="rounded"
                            />
                            {/* <li>
                            <a href="#" className="prev">
                              «
                            </a>
                          </li>
                          <li>
                            <a href="#" className="active">
                              1
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a href="#">2</a>
                          </li>
                          <li>
                            {" "}
                            <a href="#">3</a>
                          </li>

                          <li>
                            <a href="#" className="next">
                              »
                            </a>
                          </li> */}
                          </ul>
                        </div>
                      ) : (
                        <div className="nodata">
                          <b>No data available</b>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Purchaseorder
