import React, { useEffect, useState } from "react"
import { Button, Label } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import Sidebar from "components/VerticalLayout/Sidebar"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import Modal from "react-bootstrap/Modal"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { getRequest } from "common/Constants"
import { Pagination } from "@mui/material"
import moment from "moment"

const Farmer = () => {
  const [isLoading, setIsLoading] = useState(false)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  
  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  // hooks start
  const navigate = useNavigate()
  // hooks end

  // constants start
  // constants end

  //   to go to the view page with the id start
  const handleView = id => {
    try {
      navigate("/detailInvoice", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }
  //   to go to the view page with the id end

  // To Edit the sales invoice detail start

  const handleEdit = id => {
    try {
      // navigate({pathname:"/EditBusiness",state:id})
      navigate("/Editsale", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the sales invoice detail end

  const [deleteId, setDeleteId] = useState("")

  // react bootstrap modal start

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  // react bootstrap modal end

  // To delete the merchant detail start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `sellInvoiceDelete?sell_invoice_id=${deleteId}`
      )
      setIsLoading(false)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  // To delete the merchant detail end

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  // get sales invoice list start

  const [perPageItem, setPerPageItem] = useState(0)

  const [salesInvoiceList, setSalesInvoiceList] = useState([])
  const getSalesInvoice = async () => {
    try {
      setIsLoading(true)
      const response = await getRequest(
        `sellInvoiceList?page=${page}&from_date=${startDate}&to_date=${endDate}&name=${name}&phone_no=${phone}`
      )
      setIsLoading(false)
      if (response.status == 200) {
        setSalesInvoiceList(response.data.data)
        let totalPages = response.data.total / response.data.perPage
        setTotalCount(Math.ceil(totalPages))
        setPerPageItem(response.data.perPage)
      } else if (response.status == 409) {
        navigate("/Addinvoice")
      }
    } catch (error) {
      console.log(error)
    }
  }
  // get sales invoice list end

  useEffect(() => {
    getSalesInvoice()
  }, [page, render])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="विक्री इनव्हॉइस" />

          <div className="row">
            <div className="col" style={{ padding: "0" }}>
              <div className="card">
                <div className="card-body">
                  <div
                    className="row mt-3"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div className="col-md-3" style={{ textAlign: "right" }}>
                      <a
                        className="btn btn-primary"
                        href="/Addinvoice"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <i className="bx bx-plus align-middle"></i> नवीन विक्री
                        इनव्हॉइस
                      </a>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-9">
                      <form>
                        <div className="row ">
                          <div className="col-sm-3 col-xl-3  mb-3">
                            <div className="form-group mb-3">
                              <label className="form-label">नाव</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="नाव"
                                onChange={e => {
                                  setName(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 col-xl-3  mb-3">
                            <div className="form-group mb-3">
                              <label className="form-label">फोन नं.</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="फोन नं."
                                minLength={10}
                                maxLength={10}
                                onChange={e => {
                                  setPhone(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 col-xl-3 mb-3">
                            <div className="form-group">
                              <Label className="form-label">
                                या तारखे पासून
                              </Label>
                              {/* <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                className="form-control"
                                dateFormat="d-MM-yyyy"
                                placeholderText="Select date"
                              /> */}
                              <input
                                className="form-control"
                                type="date"
                                name="startDate"
                                id="example-month-input month"
                                onChange={e => setStartDate(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 col-xl-3 mb-3">
                            <div className="form-group">
                              <Label className="form-label">
                                या तारखे पर्यंत
                              </Label>
                              {/* <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                className="form-control"
                                dateFormat="d-MM-yyyy"
                                placeholderText="Select date"
                              /> */}
                              <input
                                className="form-control"
                                type="date"
                                name="startDate"
                                id="example-month-input month"
                                onChange={e => setEndDate(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-3 d-flex align-items-center mb-2">
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          className="w-md"
                          onClick={getSalesInvoice}
                        >
                          फिल्टर
                        </Button>
                      </div>
                    </div>
                  </div>

                  {isLoading ? (
                    <>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                    </>
                  ) : salesInvoiceList && salesInvoiceList.length > 0 ? (
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>अ.क्र.</th>
                              <th>नाव</th>
                              <th>फोन नं</th>
                              <th>पत्ता</th>
                              <th>तारीख</th>
                              <th></th>
                            </tr>
                          </thead>

                          {salesInvoiceList &&
                            salesInvoiceList.map((salesInvoice, index) => {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td>
                                      {page * perPageItem -
                                        perPageItem +
                                        (index + 1)}
                                    </td>
                                    <td>
                                      {!!salesInvoice?.name
                                        ? salesInvoice.name
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!salesInvoice?.phone_no
                                        ? salesInvoice.phone_no
                                        : "-"}
                                    </td>
                                    <td>
                                      {!!salesInvoice?.address
                                        ? salesInvoice.address
                                        : "-"}
                                    </td>
                                    <td>
                                      {/* {!!salesInvoice?.date
                                      ? salesInvoice.date
                                      : "-"} */}
                                      {!!salesInvoice?.date
                                        ? moment(salesInvoice?.date).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </td>
                                    <td>
                                      {/* <Link
                                    to="/detailInvoice"
                                    type="button"
                                    className="btn-sm btn-view  btn "
                                  >
                                    <div className="tooltip1">
                                      <i className="bx bx-file align-middle ">
                                        <span className="tooltiptext">पहा</span>
                                      </i>
                                    </div>
                                  </Link> */}

                                      <a
                                        className="btn-sm btn-view  btn"
                                        onClick={e =>
                                          handleView(
                                            salesInvoice?.sell_invoice_id
                                          )
                                        }
                                      >
                                        <div className="tooltip1">
                                          <i className="bx bx-file align-middle">
                                            <span className="tooltiptext">
                                              पहा
                                            </span>
                                          </i>
                                        </div>
                                      </a>

                                      {/* <Link
                                    to="/Editsale"
                                    type="button"
                                    className="btn-sm btn-edit btn "
                                  >
                                    <div className="tooltip1">
                                      <i className="bx bx-edit align-middle">
                                        <span className="tooltiptext">
                                          संपादित करा
                                        </span>
                                      </i>
                                    </div>
                                  </Link> */}

                                      <a
                                        className="btn-sm btn-edit  btn"
                                        onClick={e =>
                                          handleEdit(
                                            salesInvoice?.sell_invoice_id
                                          )
                                        }
                                      >
                                        {/* <Link
                                to="/EditBusiness"
                                type="button"
                                className="btn-sm btn-edit btn "
                              > */}
                                        <div className="tooltip1">
                                          <i className="bx bx-edit align-middle">
                                            <span className="tooltiptext">
                                              संपादित करा
                                            </span>
                                          </i>
                                        </div>
                                        {/* </Link> */}
                                      </a>

                                      {/* <Link
                                      to="#"
                                      type="button"
                                      className="btn-sm btn-print btn "
                                    >
                                      <div className="tooltip1">
                                        <i className="bx bx-printer align-middle">
                                          <span className="tooltiptext">
                                            प्रिंट करा
                                          </span>
                                        </i>
                                      </div>
                                    </Link> */}
                                      {/* <Link
                                    to="#"
                                    type="button"
                                    className="btn-sm btn-delete btn "
                                  >
                                    <div className="tooltip1">
                                      <i className="bx bxs-trash align-middle">
                                        <span className="tooltiptext">
                                          हटवा
                                        </span>
                                      </i>
                                    </div>
                                  </Link> */}

                                      <a
                                        className="btn-sm btn-delete btn"
                                        // onClick={e =>
                                        //   handleDelete(
                                        //     salesInvoice?.sell_invoice_id
                                        //   )
                                        // }
                                        onClick={() =>
                                          handleShow(
                                            salesInvoice?.sell_invoice_id
                                          )
                                        }
                                      >
                                        <div className="tooltip1">
                                          <i className="bx bxs-trash align-middle">
                                            <span className="tooltiptext">
                                              हटवा
                                            </span>
                                          </i>
                                        </div>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })}

                          {/* <tbody>
                          <tr>
                            <td>1</td>
                            <td>रुचिता</td>
                            <td>सावत नगर, नाशिक</td>

                            <td>23/03/2022</td>

                            <td>
                              <Link
                                to="/detailInvoice"
                                type="button"
                                className="btn-sm btn-view  btn "
                              >
                                <div className="tooltip1">
                                  <i className="bx bx-file align-middle ">
                                    <span className="tooltiptext">पहा</span>
                                  </i>
                                </div>
                              </Link>
                              <Link
                                to="/addinvoice"
                                type="button"
                                className="btn-sm btn-edit btn "
                              >
                                <div className="tooltip1">
                                  <i className="bx bx-edit align-middle">
                                    <span className="tooltiptext">
                                      संपादित करा
                                    </span>
                                  </i>
                                </div>
                              </Link>
                              <Link
                                to="#"
                                type="button"
                                className="btn-sm btn-print btn "
                              >
                                <div className="tooltip1">
                                  <i className="bx bx-printer align-middle">
                                    <span className="tooltiptext">
                                      प्रिंट करा
                                    </span>
                                  </i>
                                </div>
                              </Link>
                              <Link
                                to="#"
                                type="button"
                                className="btn-sm btn-delete btn "
                              >
                                <div className="tooltip1">
                                  <i className="bx bxs-trash align-middle">
                                    <span className="tooltiptext">हटवा</span>
                                  </i>
                                </div>
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>रुचिता</td>
                            <td>सावत नगर, नाशिक</td>

                            <td>23/03/2022</td>

                            <td>
                              <Link
                                to="/detailInvoice"
                                type="button"
                                className="btn-sm btn-view  btn "
                              >
                                <div className="tooltip1">
                                  <i className="bx bx-file align-middle ">
                                    <span className="tooltiptext">पहा</span>
                                  </i>
                                </div>
                              </Link>
                              <Link
                                to="/addinvoice"
                                type="button"
                                className="btn-sm btn-edit btn "
                              >
                                <div className="tooltip1">
                                  <i className="bx bx-edit align-middle">
                                    <span className="tooltiptext">
                                      संपादित करा
                                    </span>
                                  </i>
                                </div>
                              </Link>
                              <Link
                                to="#"
                                type="button"
                                className="btn-sm btn-print btn "
                              >
                                <div className="tooltip1">
                                  <i className="bx bx-printer align-middle">
                                    <span className="tooltiptext">
                                      प्रिंट करा
                                    </span>
                                  </i>
                                </div>
                              </Link>
                              <Link
                                to="#"
                                type="button"
                                className="btn-sm btn-delete btn "
                              >
                                <div className="tooltip1">
                                  <i className="bx bxs-trash align-middle">
                                    <span className="tooltiptext">हटवा</span>
                                  </i>
                                </div>
                              </Link>
                            </td>
                          </tr>
                        </tbody> */}
                        </table>
                      </div>
                      <ul
                        className="pagination modal-1"
                        style={{ float: "right" }}
                      >
                        <Pagination
                          count={totalCount}
                          page={page}
                          onChange={handleChange}
                          color="primary"
                          shape="rounded"
                        />
                      </ul>
                    </div>
                  ) : (
                    <div className="nodata">
                      <b>No data available</b>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>विक्री इनव्हॉइस हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Farmer
