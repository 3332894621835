import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "../../assets/css/style.css"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form } from "react-router-dom"
import axios from "axios"
import { useFormik } from "formik"
import * as Yup from "yup";
import { getHeader, responsecode200, responsecode408 } from "common/Constants"

const EditFarmer = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [id, setId] = useState(location.state.id)
  const [isLoading, setIsLoading] = useState(false)
  const reactAppUrl = process.env.REACT_APP_ALLURL

  const [merchant, setMerchant] = useState([])
  const getMerchantData = async () => {

    const response = await axios.get(
      `${reactAppUrl}/merchantDetail?merchant_id=${id}`
    )
    setMerchant(response.data.data)
  }

  //   to edit the merchant useFormik start

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: merchant.name || "",
      Address: merchant.address || "",
      Telephone: merchant.phone_no || "",
      BusinessName: merchant.business_name || "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().min(2,"कमीत कमी 2 अक्षर टाका ").required("कृपया आपले नाव टाका").matches(/^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/, {
        message: 'केवळ अंक मान्य नाहीत'
      }),
      BusinessName: Yup.string().min(2,"कमीत कमी 2 अक्षर टाका ").required("कृपया व्यवसायाचे नाव टाका").matches(/^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/, {
        message: 'केवळ अंक मान्य नाहीत'
      }),
      Address: Yup.string().min(2,"कमीत कमी 2 अक्षर टाका ").required("कृपया तुमचा पत्ता टाका").matches(/^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/, {
        message: 'केवळ अंक मान्य नाहीत'
      }),
      Telephone: Yup.string()
        .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .required("कृपया  फोन नं. टाका"),
    }),
    onSubmit: async values => {
      try {
        let data = {
          merchant_id: id,
          name: values.Name,
          address: values.Address,
          business_name: values.BusinessName,
          phone_no: values.Telephone,
        }

        setIsLoading(true)
        const response = await axios.post(
          `${reactAppUrl}/merchantUpdate`,
          data,
          getHeader()
        )
        setIsLoading(false)

        if (response && response.status == responsecode200()) {
          toast.success(response.data.message)
          navigate("/farmer/Listindex")
        }
      } catch (error) {
        setIsLoading(false)
        console.log(error)
        if (error.response && error.response.status == responsecode408()) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.response.data.message)
        }
      }
    },
  })
  //   to edit the merchant useFormik end

  useEffect(() => {
    getMerchantData()
  }, [])

  //meta title

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="एडिट " />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <form
                        className="needs-validation"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">नाव</label>
                          <div className="col-md-10">
                            <input
                              id="Name"
                              name="Name"
                              className="form-control"
                              type="text"
                              //   defaultValue={merchant.name}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Name}
                            />
                            {validation.touched.Name &&
                            validation.errors.Name ? (
                              <span className="error">
                                {validation.errors.Name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            व्यवसायाचे नाव
                          </label>
                          <div className="col-md-10">
                            <input
                              id="BusinessName"
                              name="BusinessName"
                              className="form-control"
                              type="text"
                              //   defaultValue={merchant.business_name}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.BusinessName}
                            />
                            {validation.touched.BusinessName &&
                            validation.errors.BusinessName ? (
                              <span className="error">
                                {validation.errors.BusinessName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            पत्ता
                          </label>
                          <div className="col-md-10">
                            <textarea
                              id="Address"
                              name="Address"
                              className="form-control"
                              type="text"
                              //   defaultValue={merchant.address}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Address}
                            />
                            {validation.touched.Address &&
                            validation.errors.Address ? (
                              <span className="error">
                                {validation.errors.Address}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            फोन नं
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Telephone"
                              name="Telephone"
                              className="form-control"
                              type="number"
                              //   defaultValue={merchant.phone_no}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Telephone}
                            />
                            {validation.touched.Telephone &&
                            validation.errors.Telephone ? (
                              <span className="error">
                                {validation.errors.Telephone}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2 col-5"
                            style={{ textAlign: "left" }}
                          >
                            <Link
                              to="/farmer/Listindex"
                              className="btn btn-danger"
                            >
                              मागे जा
                            </Link>
                          </div>
                          <div
                            className="col-md-2 col-7"
                            style={{ textAlign: "right" }}
                          >
                            <Button color="primary" type="submit" disabled={isLoading}>
                              {isLoading ? " जतन करत आहोत" : "जतन करणे"}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default EditFarmer
