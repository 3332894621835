import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "../../assets/css/style.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import axios from "axios"
import {
  getHeader,
  getRequest,
  responsecode200,
  responsecode408,
} from "common/Constants"
import { use } from "i18next"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

const Createsalesorder = () => {
  //meta title

  const [startDate, setStartDate] = useState(new Date("04-03-2020"))
  const [customchkPrimary, setcustomchkPrimary] = useState(true)
  const [customchkSuccess, setcustomchkSuccess] = useState(true)
  const [customchkInfo, setcustomchkInfo] = useState(true)
  const [customchkWarning, setcustomchkWarning] = useState(true)
  const [customchkDanger, setcustomchkDanger] = useState(true)
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true)
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true)
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true)
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const [merchantData, setMerchantData] = useState([])
  const [merchantId, setMerchantId] = useState([])

  const reactAppUrl = process.env.REACT_APP_ALLURL

  const navigate = useNavigate()

  const [merchant, setMerchant] = useState([])
  const [personName, setPersonName] = useState([])
  const getAllMerchants = async () => {
    try {
      const merchantResponse = await axios.get(`${reactAppUrl}/allMerchants`)

      const mappedData = merchantResponse.data.data.map(item => ({
        value: item.merchant_id,
        label: item.name,
      }))
      setPersonName(mappedData)
      setMerchant(merchantResponse.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  

  // to get the variety list data start

  const [varietyList, setVarietyList] = useState([])
  const [varietyName, setVarietyName] = useState([])
  const getVarietyList = async () => {
    try {
      const responseData = await getRequest(`varietyList`)
      if (responseData && responseData.status == 200) {
        setVarietyList(responseData.data.data)
        const mappedData = responseData.data.data.map(item => ({
          value: item.variety_id,
          label: item.name,
        }))
        setVarietyName(mappedData)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the variety list data end

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: merchantData.name || "",
      Address: merchantData.address || "",
      Variety: "",
      Telephone: merchantData.phone_no || "",
      weight: "",
      date1: "",
      date2: "",
      Note: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("कृपया आपले नाव टाका"),
      Address: Yup.string().required("कृपया तुमचा पत्ता टाका"),
      Variety: Yup.string().required("कृपया  व्हरायटी टाका"),
      Telephone: Yup.string()
        .required("कृपया  फोन नं. टाका")
        .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा"),
      weight: Yup.string().required("कृपया अंदाजे वजन टाका"),
      date1: Yup.string().required("कृपया तारीख टाका"),
      Note: Yup.string().required("कृपया नोट टाका"),
      // date2: Yup.string().required("कृपया तारीख टाका"),
    }),
    onSubmit: async values => {
      try {
        const data = {
          merchant_id: merchantId,
          type: values.Variety,
          weight: values.weight,
          note: values.Note,
          sell_date: values.date1,
        }

        setIsLoading(true)
        const responseData = await axios.post(
          `${reactAppUrl}/sellOrderCreate`,
          data,
          getHeader()
        )
        setIsLoading(false)

        if (responseData && responseData.status == responsecode200()) {
          toast.success(responseData.data.message)
          navigate("/Salesorder")
        }
      } catch (error) {
        setIsLoading(false)
        console.log(error)
        if (error.response && error.response.status == responsecode408()) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.response.data.message)
        }
      }
    },
  })

  useEffect(() => {
    getAllMerchants()
    getVarietyList()
  }, [])


  
  const handleUsernameChange = async (value) => {
    try {
      setMerchantId(value)

      setIsLoading(true)
      const responseDetail = await axios.get(
        `${reactAppUrl}/merchantDetail?merchant_id=${value}`,
        getHeader
      )
      setIsLoading(false)
      setMerchantData(responseDetail.data.data)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    // While viewsale page is active, the order tab must also activated
    let element = document.getElementById("order")
    let element2 = document.getElementById("order-ul")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    if (element2) {
      element2.classList.add("mm-show")
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
        element2.classList.remove("mm-show") // remove the 'show' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="विक्री ऑर्डर" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div className="cardTitle h4 d-none">Textual inputs</div>
                      <p className="card-title-desc d-none">
                        Here are examples of <code>.form-control</code> applied
                        to each textual HTML5 <code>&lt;input&gt;</code>{" "}
                        <code>type</code>.
                      </p>
                      <form
                        className="needs-validation"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            नाव *
                          </label>

                          <div className="col-md-10">

                            <Select
                              styles={customStyles}
                              id="tours"
                              name="tours"
                              className="basic-single"
                              classNamePrefix="select"
                              options={personName}
                              // onChange={setSelectedOption}
                              onChange={selectedOption => {
                                handleUsernameChange(selectedOption.value)
                              }}
                              onBlur={(e) => {
                                validation.handleBlur(e);
                              }}
                              value={personName.find(
                                option => option.value == validation.values.tours
                              )}
                            />

                            {validation.touched.name &&
                            validation.errors.name ? (
                              <span className="error">
                                {validation.errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            पत्ता*
                          </label>
                          <div className="col-md-10">
                            <textarea
                              id="Address"
                              name="Address"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={isLoading ? "Loading..." : validation.values.Address}
                              disabled
                            />
                            {validation.touched.Address &&
                            validation.errors.Address ? (
                              <span className="error">
                                {validation.errors.Address}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            फोन नं*
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Telephone"
                              name="Telephone"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={isLoading ? "Loading..." : validation.values.Telephone}
                              disabled
                            />
                            {validation.touched.Telephone &&
                            validation.errors.Telephone ? (
                              <span className="error">
                                {validation.errors.Telephone}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            व्हरायटी*
                          </label>
                          <div className="col-md-10">
                            {/* <select
                              id="formrow-InputState variety"
                              name="Variety"
                              className="form-select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Variety}
                            >
                              <option value="">Select</option>

                              {varietyList &&
                                varietyList.map((variety, index) => {
                                  return (
                                    <option
                                      value={variety.variety_id}
                                      key={index}
                                    >
                                      {variety.name}
                                    </option>
                                  )
                                })}
                            </select> */}
                            <Select
                          styles={customStyles}
                          id="farmername"
                          name="Variety"
                          className="basic-single"
                          classNamePrefix="select"
                          options={varietyName}
                          onChange={selectedOption => {
                            validation.setFieldValue(
                              "Variety",
                              selectedOption ? selectedOption.value : ""
                            )
                          }}
                          onBlur={e => {
                            validation.handleBlur(e)
                          }}
                          value={varietyName.find(
                            option => option.value === validation.values.Variety
                          )}
                        />
                            {validation.touched.Variety &&
                            validation.errors.Variety ? (
                              <span className="error">
                                {validation.errors.Variety}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            अंदाजे वजन*
                          </label>
                          <div className="col-md-10">
                            <div className="d-flex">
                              <input
                                id="weight"
                                name="weight"
                                className="form-control"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.weight}
                              />
                              <span className="p-1">Kg</span>
                            </div>
                            {validation.touched.weight &&
                            validation.errors.weight ? (
                              <span className="error">
                                {validation.errors.weight}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">नोट*</label>
                          <div className="col-md-10">
                            <textarea
                              id="Note"
                              name="Note"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Note}
                            />
                            {validation.touched.Note &&
                            validation.errors.Note ? (
                              <span className="error">
                                {validation.errors.Note}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            विक्री तारीख*
                          </label>
                          {/* <div className="col-md-10">
                            <DatePicker
                              selected={startDate}
                              onChange={date => setStartDate(date)}
                              className="form-control"
                              name="date1"
                              dateFormat="d-MM-yyyy"
                              placeholderText="Select date"
                            />
                          </div> */}

                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="date"
                              name="date1"
                              id="example-month-input month"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.date1}
                            />
                            {validation.touched.date1 &&
                            validation.errors.date1 ? (
                              <span className="error">
                                {validation.errors.date1}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2 col-5"
                            style={{ textAlign: "left" }}
                          >
                            <Link to="/Salesorder" className="btn btn-danger">
                              मागे जा
                            </Link>
                          </div>
                          <div
                            className="col-md-2 col-7"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading
                                ? " प्रस्तुत करत आहोत"
                                : "प्रस्तुत करणे"}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Createsalesorder
