import React, { useState } from "react";
import Sidebar from "components/VerticalLayout/Sidebar";
import Header from "components/VerticalLayout/Header";
import AddBusinessman from "../Businessman/AddBusinessman";
import Footer from "components/VerticalLayout/Footer";
import Others from "./others";
import "../../assets/css/style.css";

import AddFarmer from "./AddFarmer";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledCollapse
} from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";
function Index(props) {

  //meta title
  document.title = "Samarth Fruit Company";

  const [activeTab, setactiveTab] = useState("1");
  const [activeTab1, setactiveTab1] = useState("5");
  const [activeTab2, setactiveTab2] = useState("1");
  const [activeTab3, setactiveTab3] = useState("1");
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1");
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1");
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const [col5, setcol5] = useState(true);
  const [col6, setcol6] = useState(true);
  const [col7, setcol7] = useState(true);

  const [col8, setcol8] = useState(true);
  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  const t_col5 = () => {
    setcol5(!col5);
  };

  const t_col6 = () => {
    setcol6(!col6);
  };

  const t_col7 = () => {
    setcol7(!col7);
  };

  const t_col8 = () => {
    setcol6(!col6);
    setcol7(!col7);
  };

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const toggle2 = tab => {
    if (activeTab2 !== tab) {
      setactiveTab2(tab);
    }
  };

  const toggle3 = tab => {
    if (activeTab3 !== tab) {
      setactiveTab3(tab);
    }
  };

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  const toggleVerticalIcon = tab => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab);
    }
  };

  return (
    <>
        <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>
     
        <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="नवीन खाते" />

          

          <div className="row">
          
            <div className="col">
              <div className="card">
                <div className="card-body">
                  {/* <CardTitle className="h4">Custom Tabs</CardTitle> */}
                  {/* <p className="card-title-desc">Example of custom tabs</p> */}

                  <Nav tabs className="nav-tabs-custom nav-farmer nav-justified" style={{width:"50%"}}>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block">शेतकरी</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block">व्यापारी</span>
                      </NavLink>
                    </NavItem>
                   <NavItem>
                    <NavLink  style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}>
                      
                      <span className="d-block">इतर</span>
                    </NavLink>
                   </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                           <AddFarmer></AddFarmer>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                          <AddBusinessman></AddBusinessman>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                          <Others></Others>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>

         
        </div>
        </div>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
}

export default Index;
