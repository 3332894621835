import React, { useEffect, useState } from "react"

import { Link, useLocation, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { getRequest } from "common/Constants"

// import { useFormik } from "formik";
// import * as Yup from "yup";
const Viewsubadmin = () => {

  // hooks start

  const location = useLocation()
  const navigate = useNavigate()
  // hooks end

  const [id, setId] = useState(location.state.id)
  const [subAdmin, setSubAdmin] = useState([])

  const getSubAdminData = async () =>{
    try {

      const responseData = await getRequest(`subAdminDetail?sub_admin_id=${id}`)
      setSubAdmin(responseData.data.data)
      
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getSubAdminData()
  },[])
 
  return (
    <div>
     
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="पहा" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                    
                  
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">नाव</label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{!!(subAdmin?.name) ? subAdmin.name : "-"}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            पत्ता
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{!!(subAdmin?.address) ? subAdmin?.address : "-"}</p>
                          </div>
                        </div>
                     
                        <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            फोन नं
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{!!(subAdmin?.phone_no) ? subAdmin?.phone_no : "-" }</p>
                          </div>
                        </div>
                        
                        <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            ई-मेल
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{!!(subAdmin?.email) ? subAdmin.email : "-"}</p>
                          </div>
                        </div>
                        
                        {/* <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            पासवर्ड
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">123456</p>
                          </div>
                        </div> */}
                        
                        
                        {/* <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                           कन्फर्म पासवर्ड
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">123456</p>
                          </div>
                        </div> */}
                        
                        
                        <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                           Isblocked
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{subAdmin?.is_block == 0 ? "No" : subAdmin?.is_block == 1 ? "Yes" : "-" }</p>
                          </div>
                        </div>
                        <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-2" style={{ textAlign: "left" }}>
                        <Link to="/Profile" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                     
                    </div>
                        
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
    </div>
  )
}

export default Viewsubadmin
