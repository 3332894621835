import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "../assets/css/style.css"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Form } from "react-router-dom"
import { getRequest, postRequest } from "common/Constants"
import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"

const Editsubadmin = () => {
  //meta title

  // hooks start
  const navigate = useNavigate()
  const location = useLocation()
  // hooks end

  const [id, setId] = useState(location.state.id)

  const [subAdmin, setSubAdmin] = useState([])
  const [isBlock, setIsBlock] = useState(false)
  const getSubAdmin = async () => {

    const responseData = await getRequest(`subAdminDetail?sub_admin_id=${id}`)
    setSubAdmin(responseData.data.data)
    setValue(responseData.data.data.is_block == 1 ? true : false)
  }
  
  const [value, setValue] = useState(subAdmin.length >0 && (subAdmin.is_block == 1 ? true : false))

  const handleChange = () => {
    setValue(!value)
  }

  //   to edit the merchant useFormik start

  const [responseUpdate, setResponseUpdate] = useState(false)
  // const [checked,setChecked] = useState(subAdmin.is_block)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // Name: merchant.name || "",
      // Address: merchant.address || "",
      // Telephone: merchant.phone_no || "",
      // BusinessName: merchant.business_name || "",
      Name: subAdmin.name || "",
      Address: subAdmin.address || "",
      Telephone: subAdmin.phone_no || "",
      EmailId: subAdmin.email || "",
      // isBlock: subAdmin.is_block == 0 ? false : true,
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("कृपया आपले नाव टाका"),
      Address: Yup.string().required("कृपया तुमचा पत्ता टाका"),
      Telephone: Yup.string()
        .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .required("कृपया  फोन नं. टाका"),
    }),
    EmailId: Yup.string().email().required("कृपया तुमचा ई-मेल टाका"),
    onSubmit: async values => {
      try {

        let data = {
          sub_admin_id: id,
          name: values.Name,
          address: values.Address,
          email: values.EmailId,
          phone_no: values.Telephone,
          is_block: value == true ? 1 : 0,
        }

        const responseData = await postRequest("subAdminUpdate", data)
        setResponseUpdate(!responseUpdate)

        if (responseData.status == 200) {
          navigate("/Profile")
        } else if (responseData.status == 409) {
          navigate("/editsubadmin")
        }
      } catch (error) {
        console.log(error)
      }
    },
  })
  //   to edit the merchant useFormik end

  useEffect(() => {
    getSubAdmin()
  }, [responseUpdate])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="एडिट" />
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">नाव</label>
                      <div className="col-md-10">
                        <input
                          id="Name"
                          name="Name"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Name}
                        />
                        {validation.touched.Name && validation.errors.Name ? (
                          <span className="error">
                            {validation.errors.Name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">पत्ता</label>
                      <div className="col-md-10">
                        <textarea
                          id="Address"
                          name="Address"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Address}
                        />
                        {validation.touched.Address &&
                        validation.errors.Address ? (
                          <span className="error">
                            {validation.errors.Address}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        फोन नं
                      </label>
                      <div className="col-md-10">
                        <input
                          id="Telephone"
                          name="Telephone"
                          className="form-control"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Telephone}
                        />
                        {validation.touched.Telephone &&
                        validation.errors.Telephone ? (
                          <span className="error">
                            {validation.errors.Telephone}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        ई-मेल
                      </label>
                      <div className="col-md-10">
                        <input
                          id="EmailId"
                          name="EmailId"
                          className="form-control"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.EmailId}
                        />
                        {validation.touched.EmailId &&
                        validation.errors.EmailId ? (
                          <span className="error">
                            {validation.errors.EmailId}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        पासवर्ड
                      </label>
                      <div className="col-md-10">
                        <input
                          id="password"
                          name="password"
                          className="form-control"
                          type="password"
                        />
                      </div>
                    </div> */}
                    {/* <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        कन्फर्म पासवर्ड
                      </label>
                      <div className="col-md-10">
                        <input
                          id="confirmpassword"
                          name="confirmpassword"
                          className="form-control"
                          type="password"
                        />
                      </div>
                    </div> */}
                    <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-4  col-form-label"
                      >
                        Isblocked
                      </label>
                      <div className="col-md-2 mt-lg-0 mt-2 pt-lg-0 pt-1 col-2">
                        {/* <input
                          className="form-check-input"
                          type="checkbox"
                          name="isBlock"
                          id="defaultCheck2"
                          onChange={handleChange}
                          checked={value}
                        
                        /> */}

                        <input
                        type="checkbox"
                        name="checkBox"
                        id="defaultCheck2"
                        checked={value}
                        onClick={handleChange}
                        />

                        {/* {validation.touched.isBlock &&
                        validation.errors.isBlock ? (
                          <span className="error">
                            {validation.errors.isBlock}
                          </span>
                        ) : null} */}
                      </div>
                    </div>
                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-2  col-5" style={{ textAlign: "left" }}>
                        <Link to="/Profile" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div className="col-md-2 col-7" style={{ textAlign: "right" }}>
                        <Button color="primary" type="submit">
                          जतन करा
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editsubadmin
