import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useFieldArray, useForm } from "react-hook-form"

import { ToWords } from "to-words"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest, postRequest } from "common/Constants"

import Select from "react-select"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

const Editsale = () => {
  const [startDate, setStartDate] = useState(new Date("04-03-2020"))
  //meta title
  document.title = "Samarth Fruit Company"

  // react hook form start
  const navigate = useNavigate()
  const location = useLocation()
  // react hook form end

  // to translate numbet to words start
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  })

  // to translate numbet to words end

  const [id, setId] = useState(location.state.id)
  const [isLoading, setIsLoading] = useState(false)

  // to get the merchant data to fill automatically start
  const [merchant, setMerchant] = useState([])
  const [personName, setPersonName] = useState([])
  const getAllMerchant = async () => {
    const responseData = await getRequest(`allMerchants`)
    
    setMerchant(responseData.data.data)
    const mappedData = responseData.data.data.map(item => ({
      value: item.merchant_id,
      label: item.name,
    }))
    setPersonName(mappedData)
  }

  const [merchantId, setMerchantId] = useState("")
  const [merchantData, setMerchantData] = useState(null)
  const getMerchantData = async e => {
    setMerchantId(getValues().Name)
    const responseData = await getRequest(
      `merchantDetail?merchant_id=${getValues().Name}`
    )

    setMerchantData(responseData.data.data)
  }
  // to get the merchant data to fill automatically end

  // file upload start
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const uploadFile = async e => {
    try {
      setSelectedImage(e.target.files[0])
      const formData = new FormData()
      formData.append("file", e.target.files[0])

      const responseData = await postRequest(`uploadSignature`, formData)

      if (responseData && responseData.status == 200) {
        setImageUrl(responseData.data.data.imageUrl)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // file upload end

  // to get sales invoice data start

  const [salesInvoiceData, setSalesInvoiceData] = useState([])
  const getSalesInvoice = async () => {
    try {
      const responseData = await getRequest(
        `sellInvoiceDetail?sell_invoice_id=${id}`
      )
      setValue("Name", responseData.data.data.merchant_id)
      setValue("Address", responseData.data.data.address)
      setValue("Phone", responseData.data.data.phone_no)
      setValue("date1", responseData.data.data.date)
      // setValue("Receipt", responseData.data.data.receipt_no)
      setValue("VehicleNo", responseData.data.data.vehicle_no)
      setValue("Notes", responseData.data.data.notes)
      setValue("TotalCost", responseData.data.data.total)
      setValue("TravelCost", responseData.data.data.vehicle_charge)
      setValue("ExtraCost", responseData.data.data.other_charge)
      setValue("finalCost", responseData.data.data.overall_total)
      setSalesInvoiceData(responseData.data.data)
      for (
        let i = 0;
        i < responseData.data.data.invoice_information_array.length;
        i++
      ) {
        append({})
      }
      responseData.data.data.invoice_information_array.forEach(
        (field, index) => {
          setValue(`inputs[${index}].fruit_id`, field.fruit_id)
          setValue(`inputs[${index}].weight`, field.weight)
          setValue(`inputs[${index}].cutting_weight`, field.cutting_weight)
          setValue(`inputs[${index}].actual_weight`, field.actual_weight)
          setValue(`inputs[${index}].rate`, field.rate)
          setValue(`inputs[${index}].total`, field.total)
        }
      )
    } catch (error) {
      console.log(error)
    }
  }
  // to get sales invoice data end

  // addition of the automatic fields start

  const handleFinalWeight = index => e => {
    const FinalWeight =
      parseFloat(watch(`inputs[${index}].weight`)) -
        parseFloat(e.target.value) || 0
    setValue(`inputs[${index}].actual_weight`, String(FinalWeight))

    const finalRate = FinalWeight * parseFloat(watch(`inputs[${index}].rate`))
    setValue(`inputs[${index}].total`, String(finalRate))
  }

  const handleFinalWeight1 = index => e => {
    const FinalWeight =
      parseFloat(
        parseFloat(e.target.value) - watch(`inputs[${index}].cutting_weight`)
      ) || 0
    setValue(`inputs[${index}].actual_weight`, String(FinalWeight))

    const finalRate = FinalWeight * parseFloat(watch(`inputs[${index}].rate`))
    setValue(`inputs[${index}].total`, String(finalRate))
  }

  const handleRate = index => e => {
    const FinalRate =
      parseFloat(e.target.value) *
        parseFloat(watch(`inputs[${index}].actual_weight`)) || 0
    setValue(`inputs[${index}].total`, String(FinalRate))
  }

  // addition of the automatic fields end
  const [sum, setSum] = useState(0)

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: "inputs",
  })

  const [additionalInputs, setAdditionalInputs] = useState([])
  const [rows, setRows] = useState(1)

  const [finalCost, setFinalCost] = useState(
    salesInvoiceData && salesInvoiceData?.overall_total
  )

  let words = toWords.convert(
    salesInvoiceData && finalCost
      ? finalCost
      : salesInvoiceData.overall_total
      ? salesInvoiceData.overall_total
      : "0"
  )

  const onSubmit = async data => {
    try {
      const data1 = {
        sell_invoice_id: id,
        merchant_id:
          merchantId != "" ? merchantId : salesInvoiceData.merchant_id,
        name: data.Name,
        address: data.Address,
        date: data.date1,
        vehicle_no: data.VehicleNo,
        notes: data.Notes,
        total: sum,
        vehicle_charge: data.TravelCost,
        other_charge: data.ExtraCost,
        overall_total: finalCost ? finalCost : salesInvoiceData.overall_total,
        invoiceInformation: data.inputs,
      }

      setIsLoading(true)
      const responseData = await postRequest(`sellInvoiceUpdate`, data1)
      setIsLoading(false)
      if (responseData && responseData.status == 200) {
        navigate("/SalesInvoicelist")
      } else if (responseData.status == 409) {
        navigate("/Editsale")
      } else if (responseData.status == 400) {
        navigate("/Editsale")
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    calculateValue()
  }, [watch()])

  const calculateValue = () => {
    // let cost1 = getValues().TotalCost
    let cost1 = sum
    let cost2 = getValues().TravelCost ? getValues().TravelCost : 0
    let cost3 = getValues().ExtraCost ? getValues().ExtraCost : 0
    let cost = Number(cost1) - Number(cost2) - Number(cost3)
    setFinalCost(cost)
  }

  const addRow = () => {
    append({})
  }

  const handleAddInput = () => {
    setAdditionalInputs(prevInputs => [
      ...prevInputs,
      `additionalInput${prevInputs.length}`,
    ])
  }

  const [fruiSelect, setFruitSelect] = useState([])
  const getFruit = async () => {
    try {
      const responseData = await getRequest(`fruitDropdown`)
      if (responseData && responseData.status == 200) {
        setFruitSelect(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const saveValues = () => {
    if (salesInvoiceData) {
      setValue("name", salesInvoiceData?.name)
    }
  }

  useEffect(() => {
    getFruit()

    getAllMerchant()
    if (merchantData?.address) {
      setValue("Address", merchantData?.address)
    }
  }, [merchantData])
  useEffect(() => {
    getSalesInvoice()
  }, [])

  useEffect(() => {
    // While detailInvoice page is active, the invoice tab must also activated
    let element = document.getElementById("invoice")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  // debugger
  useEffect(() => {
    let watchTotal = watch().inputs

    let sumValue = 0

    for (let i = 0; i < watchTotal.length; i++) {
      sumValue =
        Number(sumValue ? sumValue : 0) +
        Number(watchTotal[i].total != null ? watchTotal[i].total : 0)
    }

    setSum(sumValue)
  })

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="एडिट विक्री इनव्हॉइस" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row  border-1">
                      <div className="col-xl-7 col-md-7 col-sm-12 col-12">
                        <div className="form-group mb-3">
                          <label className="col-form-label">नाव</label>
                          {/* <select
                            id="formrow-InputState variety"
                            name="Name"
                            className="form-select"
                            {...register("Name")}
                            onChange={getMerchantData}
                          >
                            <option value="">Select</option>

                            {merchant &&
                              merchant.map((merchants, index) => {
                                return (
                                  <option
                                    value={merchants.merchant_id}
                                    key={index}
                                  >
                                    {merchants.name}
                                  </option>
                                )
                              })}
                          </select> */}

                          <Select
                            styles={customStyles}
                            id="formrow-InputState variety"
                            name="Name"
                            className="basic-single"
                            classNamePrefix="select"
                            options={personName}
                            {...register("Name", {
                              required: "कृपया आपले नाव टाका",
                            })}
                            onChange={selectedOption => {
                              setValue(
                                "Name",
                                selectedOption ? selectedOption.value : ""
                              )
                              getMerchantData()
                            }}
                            value={personName.find(
                              option => option.value === getValues().Name
                            )}
                          />

                          {errors.name && (
                            <span style={{ color: "red" }}>
                              {errors.name.message}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="col-form-label">पत्ता</label>

                          <textarea
                            className="form-control"
                            type="text"
                            name="Address"
                            disabled
                            {...register("Address")}
                            value={merchantData?.address}
                          ></textarea>
                          {errors.Address && (
                            <span style={{ color: "red" }}>
                              {errors.Address.message}
                            </span>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <label className="col-form-label">फोन नं</label>

                          <input
                            className="form-control"
                            type="text"
                            name="Phone"
                            disabled
                            {...register("Phone")}
                            value={merchantData?.phone_no}
                          />
                        </div>
                      </div>
                      <div className="col-xl-5 col-md-5 col-sm-12 col-12">
                        <div className="form-group mb-3">
                          <label className="col-form-label">दिनांक</label>

                          <input
                            className="form-control"
                            type="date"
                            name="date1"
                            id="example-month-input month"
                            {...register("date1")}
                          />
                          {errors.date1 && (
                            <span style={{ color: "red" }}>
                              {errors.date1.message}
                            </span>
                          )}
                        </div>
                        {/* <div className="form-group mb-3">
                          <label className="col-form-label">पावती क्र.</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Receipt"
                            {...register("Receipt")}
                          />
                          {errors.Receipt && (
                            <span style={{ color: "red" }}>
                              {errors.Receipt.message}
                            </span>
                          )}
                        </div> */}
                        <div className="form-group">
                          <label className="col-form-label">गाडी नं.</label>
                          <input
                            type="text"
                            className="form-control"
                            name="VehicleNo"
                            {...register("VehicleNo")}
                          />
                          {errors.VehicleNo && (
                            <span style={{ color: "red" }}>
                              {errors.VehicleNo.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="invoice-add-table">
                      <div className="row">
                        <div className="col-lg-12">
                          <h4>माहिती</h4>
                          <div
                            className="row"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <div
                              className="col-md-2 mb-3"
                              style={{ textAlign: "right" }}
                            >
                              {/* <a
                                href="javascript:void(0);"
                                className="add-btns btn btn-primary"
                              >
                                <i className="bx bx-plus align-middle"></i> नवीन
                              </a> */}
                              <button
                                type="button"
                                onClick={addRow}
                                className="btn btn-primary"
                              >
                                नवीन
                              </button>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-striped table-nowrap  mb-0 no-footer add-table-items">
                              <thead>
                                <tr>
                                  <th>फळाचे नाव</th>
                                  <th>वजन</th>
                                  <th>कटींग वजन </th>
                                  <th>नगद वजन </th>
                                  <th>भाव </th>
                                  <th>एकूण</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {salesInvoiceData && salesInvoiceData?.invoice_information_array.map((data, index) => {
                                  console.log(data)
                                  return (<tr className="add-row" key={index}>
                                    <td>
                                    <select
                                      className="form-control"
                                      name="fruit"
                                      {...register("fruit", {
                                        required: "कृपया फळाचे नाव टाका",
                                      })}
                                    >
                                      <option></option>

                                      {fruiSelect &&
                                        fruiSelect.map((fruitEach, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={fruitEach.fruit_id}
                                            >
                                              {fruitEach.name}
                                            </option>
                                          )
                                        })}

                                    </select>
                                    {errors.fruit && (
                                      <span style={{ color: "red" }}>
                                        {errors.fruit.message}
                                      </span>
                                    )}
                                    </td>
                                    <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="Weight"
                                      {...register("Weight", {
                                        required: "कृपया अंदाजे वजन टाका",
                                        pattern: {
                                          value: /^\d+$/,
                                          message: "कृपया केवळ अंक टाका",
                                        },
                                      })}
                                    />
                                    {errors.Weight && (
                                      <span style={{ color: "red" }}>
                                        {errors.Weight.message}
                                      </span>
                                    )}
                                    </td>
                                    <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="CuttingWeight"
                                      {...register("CuttingWeight", {
                                        required: "कृपया अंदाजे कटींग वजन टाका",
                                        pattern: {
                                          value: /^\d+$/,
                                          message: "कृपया केवळ अंक टाका",
                                        },
                                      })}
                                    />
                                    {errors.CuttingWeight && (
                                      <span style={{ color: "red" }}>
                                        {errors.CuttingWeight.message}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="CashWeight"
                                      {...register("CashWeight", {
                                        required: "कृपया अंदाजे नगद वजन टाका",
                                        pattern: {
                                          value: /^\d+$/,
                                          message: "कृपया केवळ अंक टाका",
                                        },
                                      })}
                                    />
                                    {errors.CashWeight && (
                                      <span style={{ color: "red" }}>
                                        {errors.CashWeight.message}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="Price"
                                      {...register("Price", {
                                        required: "कृपया भाव टाका",
                                        pattern: {
                                          value: /^\d+$/,
                                          message: "कृपया केवळ अंक टाका",
                                        },
                                      })}
                                    />
                                    {errors.Price && (
                                      <span style={{ color: "red" }}>
                                        {errors.Price.message}
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="Total"
                                      {...register("Total", {
                                        required: "कृपया एकूण टाका",
                                        pattern: {
                                          value: /^\d+$/,
                                          message: "कृपया केवळ अंक टाका",
                                        },
                                      })}
                                    />
                                    {errors.Total && (
                                      <span style={{ color: "red" }}>
                                        {errors.Total.message}
                                      </span>
                                    )}
                                  </td>
                                  </tr>)
                                })} */}

                                {fields.map((field, index) => (
                                  <tr key={field.id}>
                                    <td>
                                      <select
                                        {...register(
                                          `inputs[${index}].fruit_id`
                                        )}
                                        className="form-control"
                                        name={`inputs[${index}].fruit_id`}
                                      >
                                        {/* <option value="">Select</option> */}

                                        {fruiSelect &&
                                          fruiSelect.map((fruitEach, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={fruitEach.fruit_id}
                                              >
                                                {fruitEach.name}
                                              </option>
                                            )
                                          })}
                                      </select>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <input
                                          {...register(
                                            `inputs[${index}].weight`
                                          )}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          name={`inputs[${index}].weight`}
                                          onChange={handleFinalWeight1(index)}
                                        />
                                        <span className="p-1">kg</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <input
                                          name={`inputs[${index}].cutting_weight`}
                                          {...register(
                                            `inputs[${index}].cutting_weight`
                                          )}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          onChange={handleFinalWeight(index)}
                                        />
                                        <span className="p-1">kg</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <input
                                          name={`inputs[${index}].actual_weight`}
                                          {...register(
                                            `inputs[${index}].actual_weight`
                                          )}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          disabled
                                        />
                                        <span className="p-1">kg</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <span className="p-1">₹</span>
                                        <input
                                          name={`inputs[${index}].rate`}
                                          {...register(`inputs[${index}].rate`)}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          defaultValue={field.rate}
                                          onChange={handleRate(index)}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <span className="p-2">₹</span>
                                        <input
                                          name={`inputs[${index}].total`}
                                          {...register(
                                            `inputs[${index}].total`
                                          )}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          defaultValue={field.total}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                    >
                                      <i className="mdi mdi-close-thick"></i>
                                    </button>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>नोट्स जोडा</label>
                          <textarea
                            type="text"
                            className="form-control"
                            name="Notes"
                            {...register("Notes")}
                          ></textarea>
                          {/* {errors.Notes && (
                            <span style={{ color: "red" }}>
                              {errors.Notes.message}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="invoice-total-card">
                          <h4 className="invoice-total-title">सारांश</h4>
                          <div className="invoice-total-box">
                            <div className="invoice-total-inner">
                              <p>
                                एकूण{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>
                                    <input
                                      type="number"
                                      step="0.01"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="TotalCost"
                                      // {...register("TotalCost", {
                                      //   required: "कृपया एकूण टाका",
                                      // })}
                                      // onKeyUp={calculateValue}
                                      value={sum}
                                      disabled
                                    />
                                    {errors.TotalCost && (
                                      <span style={{ color: "red" }}>
                                        {errors.TotalCost.message}
                                      </span>
                                    )}
                                  </div>
                                </span>
                              </p>
                              <p>
                                वाहतुक खर्च{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>

                                    <input
                                      type="number"
                                      step="0.01"
                                      className="form-control"
                                      name="TravelCost"
                                      {...register("TravelCost")}
                                      onKeyUp={calculateValue}
                                    />
                                    {/* {errors.TravelCost && (
                                        <span style={{ color: "red" }}>
                                          {errors.TravelCost.message}
                                        </span>
                                      )} */}
                                  </div>
                                </span>
                              </p>
                              <p>
                                इतर खर्च{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>

                                    <input
                                      type="number"
                                      step="0.01"
                                      className="form-control"
                                      name="ExtraCost"
                                      {...register("ExtraCost")}
                                      onKeyUp={calculateValue}
                                    />
                                    {/* {errors.ExtraCost && (
                                        <span style={{ color: "red" }}>
                                          {errors.ExtraCost.message}
                                        </span>
                                      )} */}
                                  </div>
                                </span>
                              </p>

                              <div className="links-info-one">
                                <div className="links-info">
                                  <div className="links-cont">
                                    <a href="#" className="service-trash"></a>
                                  </div>
                                </div>

                                <div className="invoice-total-footer">
                                  <h4>
                                    एकूण{" "}
                                    <span>
                                      <div className="d-flex">
                                        <span className="p-2">₹</span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          disabled
                                          name="FinalCost"
                                          value={
                                            finalCost
                                              ? finalCost
                                              : salesInvoiceData.overall_total
                                          }
                                        />
                                      </div>
                                    </span>
                                  </h4>
                                </div>
                                <div className="invoice-total-inner1 mt-1">
                                  <p>
                                    अक्षरी{" "}
                                    <span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={words}
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="mt-3 mb-3"
                          style={{ textAlign: "right" }}
                        >
                          {/* {selectedImage ? (
                            <div
                              className="selectedimg"
                              style={{
                                display: "flex",
                                width: "250px",
                                marginLeft: "250px",
                                height: "40px",
                                marginBottom: "10px",
                                position: "relative",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                alt="not found"
                                width={"250px"}
                                height={"40px"}
                                src={URL.createObjectURL(selectedImage)}
                              />

                              <button
                                className="img-remove"
                                onClick={() => setSelectedImage(null)}
                              >
                                <i className="mdi mdi-close-thick"></i>
                              </button>
                            </div>
                          ) : (

                            <img
                              src={salesInvoiceData.signature}
                              style={{ width: "200px", height: "40px" }}
                            />
                          )}
                          {selectedImage ? null : (
                            <div className="form-group mt-3 mb-3">
                              <input
                                type="file"
                                multiple=""
                                onChange={uploadFile}
                                style={{ width: "200px" }}
                              />
                            </div>
                          )} */}

                          <div
                            className="selectedimg"
                            style={{
                              height: "40px",
                              marginBottom: "10px",
                              position: "relative",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              style={{ border: "0.5px solid black" }}
                              alt="not found"
                              width={"250px"}
                              height={"50px"}
                              src={
                                "https://samarthfruitcompany.in/api/public/signature/signature.jpeg"
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="row mb-3"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          className="col-md-2 col-5"
                          style={{ textAlign: "left" }}
                        >
                          <Link
                            to="/SalesInvoicelist"
                            className="btn btn-danger"
                          >
                            मागे जा
                          </Link>
                        </div>
                        <div
                          className="col-md-2 col-7"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={isLoading}
                          >
                            {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editsale
