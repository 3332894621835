import React, { useState } from "react";
import Sidebar from "components/VerticalLayout/Sidebar";
import Header from "components/VerticalLayout/Header";
import Footer from "components/VerticalLayout/Footer";
import "../../assets/css/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
const Createsalesorder = () => {

  //meta title

  const [startDate, setStartDate] = useState(new Date('04-03-2020'));
  const [customchkPrimary, setcustomchkPrimary] = useState(true);
  const [customchkSuccess, setcustomchkSuccess] = useState(true);
  const [customchkInfo, setcustomchkInfo] = useState(true);
  const [customchkWarning, setcustomchkWarning] = useState(true);
  const [customchkDanger, setcustomchkDanger] = useState(true);
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true);
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true);
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true);
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true);
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true);
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: "",
      Address: "",
      weight: "",
      City: "",
      Variety: "",
      Telephone: "",
      date1: "",
      date2: "",



    },
    validationSchema: Yup.object({
      Name: Yup.string().required("कृपया आपले नाव टाका"),
      Address: Yup.string().required("कृपया तुमचा पत्ता टाका"),

      Variety: Yup.string().required("कृपया  व्हरायटी टाका"),
      Telephone: Yup.string().required("कृपया  फोन नं. टाका").min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा").max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा"),

      weight: Yup.string().required("कृपया अंदाजे वजन टाका"),
      date1: Yup.string().required("कृपया तारीख टाका"),
      date2: Yup.string().required("कृपया तारीख टाका"),


    }),
    onSubmit: (values) => {
      console.log("values", values);
    }
  });

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="नवीन खरेदी ऑर्डर" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div className="cardTitle h4 d-none">Textual inputs</div>
                      <p className="card-title-desc d-none">
                        Here are examples of <code>.form-control</code> applied to
                        each textual HTML5 <code>&lt;input&gt;</code>{" "}
                        <code>type</code>.
                      </p>
                      <form className="needs-validation"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>

                        <div className="row mb-3">

                          <label
                            className="col-md-2 col-form-label"
                          >
                            नाव
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Name"
                              name="Name"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Name}
                            />
                            {validation.touched.Name && validation.errors.Name ? (
                              <span className="error">{validation.errors.Name}</span>
                            ) : null}
                          </div>

                        </div>
                        <div className="row mb-3">
                          <label
                            className="col-md-2 col-form-label"
                          >
                            पत्ता
                          </label>
                          <div className="col-md-10">
                            <textarea
                              id="Address"
                              name="Address"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Address}
                            />
                            {validation.touched.Address && validation.errors.Address ? (
                              <span className="error">{validation.errors.Address}</span>
                            ) : null}

                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            फोन नं
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Telephone"
                              name="Telephone"
                              className="form-control"
                              type="number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Telephone}
                            />
                            {validation.touched.Telephone && validation.errors.Telephone ? (
                              <span className="error">{validation.errors.Telephone}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">व्हरायटी</label>
                          <div className="col-md-10">
                            <select
                              id="formrow-InputState variety"
                              name="variety"
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="1">Option1</option>
                              <option value="2">Option2</option>
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Variety}
                            </select>
                            {validation.touched.Variety && validation.errors.Variety ? (
                              <span className="error">{validation.errors.Variety}</span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label"
                          >
                            अंदाजे वजन
                          </label>
                          <div className="col-md-10">
                            <input
                              id="weight"
                              name="weight"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.weight}
                            />
                            {validation.touched.weight && validation.errors.weight ? (
                              <span className="error">{validation.errors.weight}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            className="col-md-2 col-form-label"
                          >
                            नोट
                          </label>
                          <div className="col-md-10">
                            <textarea
                              id="Note"
                              name="Note"
                              className="form-control"
                              type="text"

                            />

                          </div>
                        </div>
                        {/* <Row className="mb-3">
                    <label
                      htmlFor="example-number-input"
                      className="col-md-2 col-form-label"
                    >
                      Number
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="number"
                        defaultValue="42"
                        id="example-number-input"
                      />
                    </div>
                  </Row> */}

                        <div className="row mb-3">
                          <label
                            className="col-md-2 col-form-label"
                          >
                             खरेदी तारीख
                          </label>
                          <div className="col-md-10">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              className="form-control"
                              dateFormat="d-MM-yyyy"
                              placeholderText="Select date"
                            />
                          </div>
                        </div>

                        <div className="row mb-3"style={{justifyContent:"space-between"}}>
                  <div className="col-md-2" style={{textAlign:"left"}}>
                <Link to="/Purchaseorder" className="btn btn-danger">
                    मागे जा 
                  </Link>
                    </div>
                          <div className="col-md-2" style={{ textAlign: "right" }}>
                            <Button color="primary" type="submit">
                              प्रस्तुत करणे
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>



    </div>
  )
}

export default Createsalesorder
