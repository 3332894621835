import React from "react"
import { Navigate } from "react-router-dom"
// Profile
import "assets/css/responsive.css"
import UserProfile from "../pages/Authentication/user-profile"

import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
//Invoices
import Orderdetails from "../pages/Dashboard/Orderdetails"

import PurschaseInvoice from "../pages/Invoices/PurschaseInvoice"
import Addinvoice from "../pages/Invoices/Addinvoice"
import ViewVehicleInvoice from "../pages/Invoices/ViewVehicleInvoice"
import VehicleInvoice from "../pages/Invoices/VehicleInvoice"
import AddVehicleInvoice from "../pages/Invoices/AddVehicleInvoice"
import SalesInvoicelist from "../pages/Invoices/SalesInvoicelist"
import Purchaselist from "../pages/Invoices/purchaselist"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Taluka from "../pages/Taluka/Taluka";
import District from "../pages/Taluka/District"
//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

import Farmer from "../pages/Farmer/farmer"
import AddFarmer from "../pages/Farmer/AddFarmer"
import EditFarmer from "../pages/Farmer/EditFarmer"
import ViewFarmer from "../pages/Farmer/viewfarmer"
import Index from "../pages/Farmer/index"
import Listindex from "pages/Farmer/Listindex"
import Others from "pages/Farmer/others"
//Business
import AddBusinessman from "../pages/Businessman/AddBusinessman"
import Business from "../pages/Businessman/Business"
import EditBusiness from "../pages/Businessman/EditBusiness"
import Businessview from "../pages/Businessman/businessview"
//Orders
import Purchaseorder from "../pages/Orders/Purchaseorder"
import Salesorder from "../pages/Orders/Salesorder"
import Createsaleorder from "../pages/Orders/createsalesorder"
import Createpurchase from "../pages/Orders/createpurchase"
import DetailInvoice from "../pages/Invoices/detailInvoice"
import ViewPurchase from "../pages/Orders/viewpurchase"
import Viewsale from "../pages/Orders/viewsale"

//Payment
import Addpayment from "../pages/Payment/Addpayment"
import Paymentlist from "../pages/Payment/Paymentlist"
//Report
import Report1 from "../pages/Report/Report1"
import Report2 from "../pages/Report/Report2"
import Report3 from "../pages/Report/Report3"
import Report4 from "../pages/Report/Report4"
import Report5 from "../pages/Report/Report5.js"
import Remainders from "../pages/Remainders/Remainders"
import Listinvoice from "../pages/Invoices/Listinvoice"
import Subadmin from "../pages/subadmin"
import Addsubadmin from "../pages/addsubadmin"
import Editsubadmin from "../pages/editsubadmin"
import Variety from "../pages/Variety/variety"
import Addvariety from "../pages/Variety/addvariety"
import Editvariety from "../pages/Variety/editvariety"
import Purchasedetail from "../pages/Invoices/Purchasedetail"
import Editsale from "../pages/Invoices/Editsale"
import Editpurchase from "../pages/Invoices/Editpurchase"
import Editvehicle from "../pages/Invoices/Editvehicle"
import Viewsubadmin from "../pages/Viewsubadmin"
import Farmerorder from "../pages/Orders/farmerorder"
import Viewfarmerorder from "../pages/Orders/viewfarmerorder"
import Editfarmerorder from "../pages/Orders/editfarmerorder"
import Editsaleorder from "../pages/Orders/editsaleorder"
import Farmerorderlist from "../pages/Orders/farmerorderlist"
import Profile from "../pages/Authentication/index"
import Editpayment from "../pages/Payment/Editpayment"
import Viewpayment from "../pages/Payment/Viewpayment"
import Edittaluka from "../pages/Taluka/Edittaluka"
import Editdistrict from "../pages/Taluka/Editdistrict"
import Information from "../pages/Taluka/Information"
import Editothers from "../pages/Farmer/editothers"
import Viewothers from "../pages/Farmer/viewothers.js"
import Merchantpayment from "../pages/Payment/merchantpayment"
import Businesspayment from "../pages/Payment/businesspayment"
import Farmerpayment from "../pages/Payment/farmerpayment"
import Editfarmerpayment from "../pages/Payment/editfarmerpayment"
import Addfarmerpayment from "../pages/Payment/addfarmerpayment"
import Viewfarmerpayment from "../pages/Payment/viewfarmerpayment"
import Editbusinesspayment from "../pages/Payment/editbusinesspayment"
import Addbusinesspayment from "../pages/Payment/addbusinesspayment"
import Viewbusinesspayment from "../pages/Payment/viewbusinesspayment"
import Editmerchantpayment from "../pages/Payment/editmerchantpayment"
import Addmerchantpayment from "../pages/Payment/addmerchantpayment"
import Viewmerchantpayment from "../pages/Payment/viewmerchantpayment"
import Farmerleadger from  "../pages/Leadger/farmerleadger"
import Businessleadger from "../pages/Leadger/businessleadger"
import Merchantleadger from "../pages/Leadger/merchantleadger"
import Viewfarmerleadger from "../pages/Leadger/viewfarmerleadger"
import Viewmerchantleadger from "../pages/Leadger/viewmerchantleadger"
import Viewbusinessleadger from "../pages/Leadger/viewbusinessleadger"
import Editcategory from "../pages/Category/editcategory"

const authProtectedRoutes = [
  
  { path: "/dashboard", component: <Dashboard /> },
  
  {path:"/District" , component:<District/>},
  { path: "/orderdetails", component: <Orderdetails /> },
  { path: "/Listinvoice", component: <Listinvoice /> },
  { path: "/subadmin", component: <Subadmin /> },
  { path: "/addsubadmin", component: <Addsubadmin /> },
  { path: "/editsubadmin", component: <Editsubadmin /> },
  { path: "/variety", component: <Variety /> },
  { path: "/addvariety", component: <Addvariety /> },
  { path: "/editvariety", component: <Editvariety /> },
  { path: "/Viewsubadmin", component: <Viewsubadmin /> },
  { path: "/farmerorder", component: <Farmerorder /> },
  { path: "/editfarmerorder", component: <Editfarmerorder /> },
  { path: "/viewfarmerorder", component: <Viewfarmerorder /> },
  { path: "/Farmerorderlist", component: <Farmerorderlist /> },
  { path: "/Profile", component: <Profile /> },
  { path: "/Editpayment", component: <Editpayment /> },
  { path: "/Viewpayment", component: <Viewpayment /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
   { path: "/Addinvoice", component: (<Addinvoice />) },
  { path: "/PurschaseInvoice", component: <PurschaseInvoice /> },
  { path: "/VehicleInvoice", component: <VehicleInvoice /> },
  { path: "ViewVehicleInvoice", component: <ViewVehicleInvoice /> },
  { path: "/AddVehicleInvoice", component: <AddVehicleInvoice /> },
  { path: "/SalesInvoicelist", component: (<SalesInvoicelist />) },
  { path: "/purchaselist", component: <Purchaselist /> },
  { path: "/detailInvoice", component: <DetailInvoice /> },
  { path: "/viewsale", component: <Viewsale /> },
  { path: "/viewpurchase", component: <ViewPurchase /> }, 
  { path: "/Purchasedetails", component: <Purchasedetail /> },
  { path: "/Editvehicle", component: <Editvehicle /> },
  { path: "/Editsale", component: <Editsale /> },
  { path: "/Editpurchase", component: <Editpurchase /> },
  { path: "/Editsaleorder", component: <Editsaleorder /> },
    { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },
  { path: "/Taluka", component: <Taluka /> },
  {path:"/Edittaluka", component:<Edittaluka/>},
  {path:"/Editdistrict", component:<Editdistrict/>},
  {path:"/Information", component:<Information/>},
  {path:"/editcategory",component:<Editcategory/>},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
 
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  // { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  {
    path: "/auth-two-step-verification-2",
    component: <TwostepVerification2 />,
  },

  // //Farmer
  { path: "/farmer", component: <Farmer /> },
  { path: "/Addfarmer", component: <AddFarmer /> },
  { path: "/Editfarmer", component: <EditFarmer /> },
  { path: "/Viewfarmer", component: <ViewFarmer /> },
  { path: "/farmer/index", component: <Index /> },
  { path: "/farmer/Listindex", component: <Listindex /> },
  {path:"/Others",component:<Others/>},
  {path:"/Editothers", component:<Editothers/>},
  {path:"/Viewothers", component:<Viewothers/>},
  {path:"/addfarmerpayment",component:<Addfarmerpayment/>},
  {path:"/editfarmerpayment/:id",component:<Editfarmerpayment/>},
  {path:"/viewfarmerpayment",component:<Viewfarmerpayment/>},
  {path:"/addmerchantpayment",component:<Addmerchantpayment/>},
  {path:"/editmerchantpayment/:id",component:<Editmerchantpayment/>},
  {path:"/viewmerchantpayment",component:<Viewmerchantpayment/>},
  {path:"/addbusinesspayment",component:<Addbusinesspayment/>},
  {path:"/editbusinesspayment/:id",component:<Editbusinesspayment/>},
  {path:"/viewbusinesspayment",component:<Viewbusinesspayment/>},
  //Business
  { path: "AddBusinessman", component: <AddBusinessman /> },
  { path: "Business", component: <Business /> },
  { path: "EditBusiness", component: <EditBusiness /> },
  { path: "businessview", component: <Businessview /> },

  //Orders
  { path: "Salesorder", component: <Salesorder /> },
  { path: "Purchaseorder", component: <Purchaseorder /> },
  { path: "createpurchase", component: <Createpurchase /> },
  { path: "createsalesorder", component: (<Createsaleorder />) },
  //Report
  { path: "/Report1", component: <Report1 /> },
  { path: "/Report2", component: <Report2 /> },
  { path: "/Report3", component: <Report3 /> },
  { path: "/Report4", component: <Report4 /> },
  { path: "/Report5", component: <Report5 /> },
  //Payment
  { path: "/Addpayment", component: <Addpayment /> },
  { path: "/Paymentlist", component: <Paymentlist /> },
  { path: "/Remainders", component: <Remainders /> },
  {path:"/merchantpayment",component:<Merchantpayment/>},
  {path:"/businesspayment",component:<Businesspayment/>},
  {path:"/farmerpayment",component:<Farmerpayment/>},
  {path:"/farmerleadger" , component:<Farmerleadger/>},
  {path:"/businessleadger" , component:<Businessleadger/>},
  {path:"/merchantleadger" , component:<Merchantleadger/>},

  {path:"/viewfarmerleadger/:id" , component:<Viewfarmerleadger/>},
  {path:"/viewbusinessleadger/:id" , component:<Viewbusinessleadger/>},
  {path:"/viewmerchantleadger" , component:<Viewmerchantleadger/>},


]

// debugger
// const forAdminRoutes = [
  
//   { path: "Salesorder", component: <Salesorder /> },
// ]

// export { authProtectedRoutes, publicRoutes,forAdminRoutes }
export { authProtectedRoutes, publicRoutes }
