import React, { useEffect, useState } from "react"

import { Button } from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  getHeader,
  getRequest,
  postRequest,
  responsecode200,
} from "common/Constants"
import Select from "react-select"
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

const Editfarmerorder = () => {
  //meta title

  // hooks start
  const navigate = useNavigate()
  const location = useLocation()
  // hooks end

  const [id, setId] = useState(location.state.id)
  const [isLoading, setIsLoading] = useState(false)

  // to get the data for specific farmer order start
  const [farmerOrderData, setFarmerOrderData] = useState([])
  const getFarmerOrderData = async () => {
    try {
      const responseData = await getRequest(
        `farmerOrderDetail?farmer_order_id=${id}`
      )
      if (responseData && responseData.status == 200) {
        setFarmerOrderData(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the data for specific farmer order end

  // to get the farmer data start

  const [farmersList, setFarmersList] = useState([])
  const [personName, setPersonName] = useState([])
  const getFarmerList = async () => {
    try {
      const responseData = await getRequest(`allFarmers`)
      if (responseData && responseData?.status == 200) {
        setFarmersList(responseData?.data?.data)
        const mappedData = responseData.data.data.map(item => ({
          value: item.farmer_id,
          label: item.name,
        }))
        setPersonName(mappedData)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the farmer data end

  // to get the variety list data start

  const [varietyList, setVarietyList] = useState([])
  const [varietyName, setVarietyName] = useState([])
  const getVarietyList = async () => {
    try {
      const responseData = await getRequest(`varietyList`)
      if (responseData && responseData?.status == 200) {
        setVarietyList(responseData?.data?.data)
        const mappedData = responseData.data.data.map(item => ({
          value: item.variety_id,
          label: item.name,
        }))
        setVarietyName(mappedData)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the variety list data end

  const reactAppUrl = process.env.REACT_APP_ALLURL

  const [validToken, setValidToken] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: farmerOrderData.farmer_id || "",
      Variety: farmerOrderData.variety_id || "",
      area: farmerOrderData.planted_area || "",
      weight: farmerOrderData.weight || "",
      papaya: farmerOrderData.is_planted || "",
      Counts: farmerOrderData.tree_count || "",
      date1: farmerOrderData.plantation_date || "",
      date2: farmerOrderData.cutting_date || "",
      frequency: farmerOrderData.cutting_ratio || "",
      month: farmerOrderData.cutting_month || "",
      // Address: farmerOrderData.farmer || "",
      // Village: farmerOrderData.farmer || "",
      // District: farmerOrderData.farmer || "",
      // City: farmerOrderData.farmer || "",
      // Telephone: farmerOrderData.farmer || "",
      // Taluka: farmerOrderData.farmer || "",
      // nopapaya: farmerOrderData.farmer || "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("कृपया आपले नाव टाका"),
      Variety: Yup.string().required("कृपया  व्हरायटी टाका"),
      area: Yup.string().required("कृपया लागवड केलेले क्षेत्र टाका"),
      weight: Yup.string().required("कृपया अंदाजे वजन टाका"),
      // papaya: Yup.string().required("कृपया फिएल्डस  टाका"),
      Counts: Yup.string().required("कृपया झाडांची संख्या  टाका"),
      date1: Yup.string().required("कृपया तारीख टाका"),
      date2: Yup.string().required("कृपया तारीख टाका"),
      frequency: Yup.string().required("कृपया तोडणी वारंवारिता टाका"),
      month: Yup.string().required("कृपया महिने टाका"),
      // Address: Yup.string().required("कृपया तुमचा पत्ता टाका"),
      // Village: Yup.string().required("कृपया  गावाचे नाव टाका"),
      // District: Yup.string().required("कृपया जिल्ह्याचे नाव टाका"),
      // Taluka: Yup.string().required("कृपया तालुक्याचे नाव टाका"),
      // Telephone: Yup.string()
      //   .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
      //   .required("कृपया  फोन नं. टाका"),
      // nopapaya:Yup.string().required("कृपया फिएल्डस  टाका"),
    }),
    onSubmit: async values => {
      try {
        let data = {
          // name: values.Name,
          // type: values.Variety,
          // planted_area: values.area,
          // tree_count: values.Counts,
          // weight: values.weight,
          // plantation_date: values.date1,
          // cutting_date: values.date2,
          // cutting_ratio: values.frequency,
          // cutting_month: values.month,

          farmer_id: values.Name,
          farmer_order_id: id,
          variety_id: values.Variety,
          // is_planted: values.papaya,
          planted_area: values.area,
          tree_count: values.Counts,
          weight: values.weight,
          plantation_date: values.date1,
          cutting_date: values.date2,
          cutting_ratio: values.frequency,
          cutting_month: values.month,
        }

        setIsLoading(true)
        const responseData = await postRequest(`farmerOrderUpdate`, data)
        setIsLoading(false)
        if (responseData && responseData.status == 200) {
          navigate("/farmerorderlist")
        } else if (responseData.status == 409) {
          navigate("/Editfarmerorder")
        }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status == responsecode408()) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.response.data.message)
        }
      }
    },
  })

  // const [variery, setVariety] = useState([])
  // const getVariety = async () => {
  //   try {
  //     const responseData = await getRequest(`varietyList`)
  //     if (responseData && responseData.status == 200) {
  //       setVariety(responseData.data.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    // getVariety()
    getFarmerOrderData()
    getFarmerList()
    getVarietyList()
  }, [])

  useEffect(() => {
    // While edit farmerorder page is active, the farmer order tab must also activated
    let element = document.getElementById("farmerorderList")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="शेतकरी ऑर्डर" />
          <div className="row">
            <div className="col" style={{ padding: "0" }}>
              <div className="card">
                <div className="card-body">
                  <div className="cardTitle h4 d-none">Textual inputs</div>
                  <p className="card-title-desc d-none">
                    Here are examples of <code>.form-control</code> applied to
                    each textual HTML5 <code>&lt;input&gt;</code>{" "}
                    <code>type</code>.
                  </p>
                  <form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        शेतकरी चे नाव*
                      </label>
                      <div className="col-md-10">
                        {/* <select
                          id="farmername"
                          name="Name"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Name}
                        >
                          <option value="">select</option>
                          {farmersList &&
                            farmersList.map((farmers, index) => {
                              return (
                                <option value={farmers.farmer_id} key={index}>
                                  {farmers.name}
                                </option>
                              )
                            })}
                        </select> */}
                        <Select
                          styles={customStyles}
                          id="farmername"
                          name="Name"
                          className="basic-single"
                          classNamePrefix="select"
                          options={personName}
                          onChange={selectedOption => {
                            validation.setFieldValue(
                              "Name",
                              selectedOption ? selectedOption.value : ""
                            )
                          }}
                          onBlur={e => {
                            validation.handleBlur(e)
                          }}
                          value={personName.find(
                            option => option.value == validation.values.Name
                          )}
                        />
                        {validation.touched.Name && validation.errors.Name ? (
                          <span className="error">
                            {validation.errors.Name}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        व्हरायटी*
                      </label>

                      <div className="col-md-10">
                        {/* <select
                          id="formrow-InputState variety"
                          name="Variety"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Variety}
                        >
                          <option value="">Select</option>
                          {varietyList &&
                            varietyList.map((variety, index) => {
                              return (
                                <option value={variety.variety_id} key={index}>
                                  {variety.name}
                                </option>
                              )
                            })}
                        </select> */}

                        <Select
                          styles={customStyles}
                          id="farmername"
                          name="Variety"
                          className="basic-single"
                          classNamePrefix="select"
                          options={varietyName}
                          onChange={selectedOption => {
                            validation.setFieldValue(
                              "Variety",
                              selectedOption ? selectedOption.value : ""
                            )
                          }}
                          onBlur={e => {
                            validation.handleBlur(e)
                          }}
                          value={varietyName.find(
                            option => option.value == validation.values.Variety
                          )}
                        />
                        {validation.touched.Variety &&
                        validation.errors.Variety ? (
                          <span className="error">
                            {validation.errors.Variety}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        लागवड क्षेत्र (एकर मध्ये )*
                      </label>
                      <div className="col-md-10">
                        <input
                          id="area"
                          name="area"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.area}
                        />
                        {validation.touched.area && validation.errors.area ? (
                          <span className="error">
                            {validation.errors.area}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        अंदाजे वजन*
                      </label>
                      <div className="col-md-10">
                        <div className="d-flex">
                          <input
                            id="weight"
                            name="weight"
                            className="form-control"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.weight}
                          />
                          <span className="p-1">Kg</span>
                        </div>
                        {validation.touched.weight &&
                        validation.errors.weight ? (
                          <span className="error">
                            {validation.errors.weight}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* 
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        पपई लागवड केलेले
                      </label>
                      <div className="col-md-10">
                        <select
                          id="formrow-InputState variety"
                          name="papata"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.papaya}
                        >
                          <option value="">select</option>
                          <option value="1">हो </option>
                          <option value="2">नाही </option>
                        </select>
                        {validation.touched.papaya &&
                        validation.errors.papaya ? (
                          <span className="error">
                            {validation.errors.papaya}
                          </span>
                        ) : null}
                      </div>{" "}
                    </div> */}

                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        झाडांची संख्या*
                      </label>
                      <div className="col-md-10">
                        <input
                          id="Counts"
                          name="Counts"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Counts}
                        />
                        {validation.touched.Counts &&
                        validation.errors.Counts ? (
                          <span className="error">
                            {validation.errors.Counts}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        अंदाजे वजन (टन मध्ये)
                      </label>
                      <div className="col-md-10">
                        <input
                          id="weight"
                          name="weight"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.weight}
                        />
                        {validation.touched.weight &&
                        validation.errors.weight ? (
                          <span className="error">
                            {validation.errors.weight}
                          </span>
                        ) : null}
                      </div>
                    </div> */}

                    {/* <Row className="mb-3">
                    <label
                      htmlFor="example-number-input"
                      className="col-md-2 col-form-label"
                    >
                      Number
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="number"
                        defaultValue="42"
                        id="example-number-input"
                      />
                    </div>
                  </Row> */}
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        लागवडीची तारीख*
                      </label>
                      {/* <div className="col-md-10">
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      dateFormat="d-MM-yyyy"
                      className="form-control"
                      placeholderText="Select date"
                    />
                  </div> */}

                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          name="date1"
                          id="example-month-input month"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.date1}
                        />
                        {validation.touched.date1 && validation.errors.date1 ? (
                          <span className="error">
                            {validation.errors.date1}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        काढणी तारीख*
                      </label>
                      {/* <div className="col-md-10">
                    <DatePicker
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                      className="form-control"
                      dateFormat="d-MM-yyyy"
                      placeholderText="Select date"
                    />
                  </div> */}

                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          name="date2"
                          id="example-month-input month"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.date2}
                        />
                        {validation.touched.date2 && validation.errors.date2 ? (
                          <span className="error">
                            {validation.errors.date2}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        तोडणी वारंवारिता*
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          id="frequency"
                          name="frequency"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.frequency}
                        />
                        {validation.touched.frequency &&
                        validation.errors.frequency ? (
                          <span className="error">
                            {validation.errors.frequency}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        तोडणी महिने*
                      </label>
                      {/* <div className="col-md-10">
                    <input
                      className="form-control"
                      type="date"
                      name="month"
                      id="example-month-input month"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.month}
                    />
                    {validation.touched.month && validation.errors.month ? (
                      <span className="error">{validation.errors.month}</span>
                    ) : null}
                  </div> */}

                      <div className="col-md-10">
                        <input
                          id="example-month-input month"
                          name="month"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.month}
                        />
                        {validation.touched.month && validation.errors.month ? (
                          <span className="error">
                            {validation.errors.month}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        className="col-md-2 col-5"
                        style={{ textAlign: "left" }}
                      >
                        <Link to="/farmerorderlist" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div
                        className="col-md-2 col-7"
                        style={{ textAlign: "right" }}
                      >
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? " जतन करत आहोत" : "जतन करणे"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editfarmerorder
