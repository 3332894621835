import React from "react";


import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Sidebar from "components/VerticalLayout/Sidebar";
import Header from "components/VerticalLayout/Header";
import Footer from "components/VerticalLayout/Footer";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Paymentlist = () => {
  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };
  //meta title
  document.title = "Samarth Fruit Company";
  return (
    <div>
  <div id="layout-wrapper">
        <Header></Header>
        {/* <Sidebar></Sidebar> */}
     
        <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="पेमेंट" />

    <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <div className="row" style={{ justifyContent:"space-between" }}>
                    {/* <div className="col-md-2 mb-3" style={{ textAlign: "right" }}>
                      <Link to="/Addfarmer" type="button" class="btn-add btn-sm  btn btn-primary"></Link>
                    </div> */}
                    <div className="col-md-4" style={{ textAlign: "left" }}><div className="search-box d-inline-block">
                      <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only">Search this table</span>
                          <input  type="text" className="form-control" placeholder="27 records..." /></label><i className="bx bx-search-alt search-icon"></i></div></div></div>
                   <div className="col-md-2 mb-3" style={{ textAlign: "right" }} ><a className="btn btn-primary" href="/Addpayment"><i className="bx bx-plus align-middle"></i> नवीन फॉर्म</a></div> 
                  </div>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>अ.क्र.</th>
                            <th>तारीख</th>
                            <th>नाव</th>
                            <th>पेमेंट पद्धत</th>
                            <th>नोट </th>
                            <th>पेमेंट</th>
                          
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <th>23/02/2022</th>
                            <td>रुचिता</td>
                            <td>
                            डेबिट</td>
                            <td>bcnsdj</td>
                            
                                <td><button type="button" className="btn-sm btn-rounded btn btn-primary">पैसे दिले</button></td>
                                
                          </tr>
                          <tr>
                            <td>2</td>
                            <th>23/02/2022</th>
                            <td>रुचिता</td>
                            <td>
                            क्रेडिट</td>
                            <td>bcnsdj</td>
                         
                                <td><button type="button" className="btn-sm btn-rounded btn btn-primary">पैसे दिले</button></td>
                                
                          </tr>
                        </tbody>
                      </table>
                      <ul className="pagination modal-1" style={{float:"right"}}>
  <li><a href="#" className="prev">«</a></li>
  <li><a href="#" className="active">1</a></li>
  <li> <a href="#">2</a></li>
  <li> <a href="#">3</a></li>
  
  <li><a href="#" className="next">»</a></li>
</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      <Footer></Footer>
                    </div>
   
  );
};

export default Paymentlist;
