import React, { useEffect, useState } from "react"
import { Button, Label } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import axios from "axios"
import { Pagination } from "@mui/material"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useFormik } from "formik"
import * as Yup from "yup"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Modal } from "react-bootstrap"
import { getHeader, getRequest, postRequest } from "common/Constants"
import { toast } from "react-toastify"

const Others = () => {
  //meta title
  document.title = "Samarth Fruit Company"
  const reactAppUrl = process.env.REACT_APP_ALLURL
  const navigate = useNavigate()

  // to get the category list data start
  const [isLoading, setIsLoading] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const getCategoryList = async () => {
    try {
      const responseData = await getRequest(`categoryList`)
      if (responseData && responseData.status == 200) {
        setCategoryList(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      Category: "",
      phone: "",
      Address: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("कृपया आपले नाव टाका"),
      Address: Yup.string().required("कृपया तुमचा पत्ता टाका"),
      Category: Yup.string().required("कृपया  प्रवर्ग टाका"),
      phone: Yup.string()
        .required("कृपया  फोन नं. टाका")
        .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा"),
    }),
    onSubmit: async values => {
      try {
        const data = {
          otherName: values.name,
          categoryId: values.Category,
          address: values.Address,
          phoneNo: values.phone,
        }

        setIsLoading(true)
        const responseData = await postRequest(`createOthers`, data)
        setIsLoading(false)
        navigate("/farmer/Listindex")
      } catch (error) {
        setIsLoading(false)
        console.log(error)
        if (error.response && error.response.status == responsecode408()) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.response.data.message)
        }
      }
    },
  })

  useEffect(() => {
    getCategoryList()
  }, [])

  // to check the phone no start
  const [isPhoneChecking, setIsPhonechecking] = useState(false)
  const checkPhoneNo = async () => {
    try {
      setIsPhonechecking(true)
      const response = await getRequest(`checksPhoneNoOther?phone_no=${validation.values.phone}`)
      setIsPhonechecking(false)
      // toast.error(response?.data?.message)
    } catch (error) {
      setIsPhonechecking(false)
      console.log(error)
    }
  }
  // to check the phone no end

  return (
    <>
      <form
        className="needs-validation mt-4"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div className="row mb-3">
          <label
            htmlFor="example-tel-input"
            className="col-md-2 col-form-label"
          >
            फोन नं*
          </label>
          <div className="col-md-10">
            <input
              id="phone"
              name="phone"
              className="form-control"
              type="number"
              onChange={validation.handleChange}
              onBlur={e => {
                validation.handleBlur(e)
                checkPhoneNo()
              }}
              value={validation.values.phone}
            />
            {validation.touched.phone && validation.errors.phone ? (
              <span className="error">{validation.errors.phone}</span>
            ) : null}
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-md-2 col-form-label">नाव*</label>
          <div className="col-md-10">
            <input
              id="name"
              name="name"
              className="form-control"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name}
            />
            {validation.touched.name && validation.errors.name ? (
              <span className="error">{validation.errors.name}</span>
            ) : null}
          </div>
        </div>

        <div className="row ">
          <div className="col-md-6 mb-3">
            <div className="row">
              <label className="col-md-4 col-form-label">प्रवर्ग*</label>
              <div className="col-md-8">
                <select
                  id="Category"
                  name="Category"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Category}
                >
                  <option value="">select</option>
                  {categoryList &&
                    categoryList.map((Category, index) => {
                      return (
                        <option value={Category.categoryId} key={index}>
                          {Category.catName}
                        </option>
                      )
                    })}
                </select>
                {validation.touched.Category && validation.errors.Category ? (
                  <span className="error">{validation.errors.Category}</span>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="col-md-6 mb-3">
            <div className="row">
              <label className="col-md-2 col-form-label">फोन नं.*</label>
              <div className="col-md-10">
                <input
                  id="phone"
                  name="phone"
                  className="form-control"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone}
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <span className="error">{validation.errors.phone}</span>
                ) : null}
              </div>
            </div>
          </div> */}
        </div>

        <div className="row mb-3">
          <label className="col-md-2 col-12 col-form-label">पत्ता*</label>
          <div className="col-md-10 col-12">
            <textarea
              id="address"
              name="Address"
              className="form-control"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.Address}
            />
            {validation.touched.Address && validation.errors.Address ? (
              <span className="error">{validation.errors.Address}</span>
            ) : null}
          </div>
        </div>

        <div className="row mb-3 mt-3" style={{ justifyContent: "flex-end" }}>
          <div className="col-md-2" style={{ textAlign: "right" }}>
            <Button color="primary" type="submit" disabled={isLoading || isPhoneChecking}>
              {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

export default Others
