import React, { useEffect, useState } from "react"

import { Link, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useLocation } from "react-router-dom"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import { getRequest } from "common/Constants"

// import { useFormik } from "formik";
// import * as Yup from "yup";
const Viewfarmerpayment = () => {


  const location = useLocation()

  const [farmerPaymentId, setFarmerPaymentId] = useState(location?.state?.id)
  const [farmerPaymentData, setFarmerPaymentData] = useState([])
  const getFarmer = async () => {
    const farmerResponse = await getRequest(`farmerPayDetails?farmerPayId=${farmerPaymentId}`)
    setFarmerPaymentData(farmerResponse?.data)
  }
  useEffect(() => {
    getFarmer();
  },[])

  useEffect(() => {
    // While detailInvoice page is active, the invoice tab must also activated
    let element = document.getElementById("paymentlist")
    let element2 = document.getElementById("payment-ul")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    if (element2) {
      element2.classList.add("mm-show")
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
        element2.classList.remove("mm-show") // remove the 'show' class to the element when change to another page
      }
    }
  }, [])

  
  return (
    <>
       <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="शेतकरी पेमेंट" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  
                    <form>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">तारीख</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                            {new Date(farmerPaymentData?.date).toLocaleDateString("en-GB")}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">नाव</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                          {farmerPaymentData?.name}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-3 border-1">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                           पेमेंट प्रकार 
                        </label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                          {farmerPaymentData?.payment_mode == 1 ? "डेबिट":"क्रेडिट"}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">
                          पेमेंट पद्धत(कॅश/बँकिंग)
                        </label>
                        <div className="col-md-10 m-auto">
                          {/* <p className="mb-0 para1">{!!(payment?.payment_type) ? payment.payment_type : "-"}</p> */}
                          <p className="mb-0 para1">
                          {farmerPaymentData?.payment_type ==1 ? "कॅश":"बँकिंग"}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">
                        रक्कम
                        </label>
                        <div className="col-md-10 m-auto">
                          {/* <p className="mb-0 para1">{!!(payment?.payment_type) ? payment.payment_type : "-"}</p> */}
                          <p className="mb-0 para1">
                          {farmerPaymentData?.amount}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">
                        हेतू
                        </label>
                        <div className="col-md-10 m-auto">
                          {/* <p className="mb-0 para1">{!!(payment?.payment_type) ? payment.payment_type : "-"}</p> */}
                          <p className="mb-0 para1">
                          {farmerPaymentData?.reason}
                          </p>
                        </div>
                      </div>
                      
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">नोट</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                          {farmerPaymentData?.notes}
                          </p>
                        </div>
                      </div>
                      <div
                        className="row mb-3"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-md-2" style={{ textAlign: "left" }}>
                          <Link to="/farmerpayment" className="btn btn-danger">
                            मागे जा
                          </Link>
                        </div>
                      </div>
                    </form>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer></Footer>
      </div>
    </>
  )
}

export default Viewfarmerpayment
