import React, { useEffect, useState } from "react"

import { Link, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useLocation } from "react-router-dom"
import axios from "axios"
import { getRequest } from "common/Constants"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import moment from "moment"

// import { useFormik } from "formik";
// import * as Yup from "yup";
const Viewpayment = () => {
  // hooks start
  const navigate = useNavigate()
  const location = useLocation()
  // hooks end

  const [isLoading, setIsLoading] = useState(false)

  const [id, setId] = useState(location?.state?.id)
  const [prevLocation,setPrevLocation] = useState(location?.state?.prevLocation)

  // to get the individual payment data start

  const [payment, setPayment] = useState([])
  const getpayment = async () => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(`paymentDetail?payment_id=${id}`)
      setIsLoading(false)
      if (responseData && responseData.status == 200) {
        setPayment(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the individual payment data end

  useEffect(() => {
    getpayment()
  }, [])

  useEffect(() => {
    // While view payment page is active, the payment tab must also activated
    let element = document.getElementById("paymentlist")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="पेमेंट" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  {isLoading ? (
                    <>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                    </>
                  ) : (
                    <form>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">तारीख</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                            {/* {!!payment?.date ? payment.date : "-"} */}
                            {!!payment?.date ? moment(payment?.date).format("DD/MM/YYYY") : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">नाव</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                            {!!payment.name ? payment.name : "-"}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-3 border-1">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                          डेबिट/क्रेडिट
                        </label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                            {!!payment?.payment_mode
                              ? payment.payment_mode == 1
                                ? "debit"
                                : payment.payment_mode == 2
                                ? "credit"
                                : "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">
                          पेमेंट पद्धत(कॅश/बँकिंग)
                        </label>
                        <div className="col-md-10 m-auto">
                          {/* <p className="mb-0 para1">{!!(payment?.payment_type) ? payment.payment_type : "-"}</p> */}
                          <p className="mb-0 para1">
                            {!!payment?.payment_type
                              ? payment.payment_type == 1
                                ? "cash"
                                : payment.payment_type == 2
                                ? "banking"
                                : "-"
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">नोट</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                            {!!payment?.notes ? payment.notes : "-"}
                          </p>
                        </div>
                      </div>
                      <div
                        className="row mb-3"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-md-2" style={{ textAlign: "left" }}>
                          <Link to={prevLocation ? prevLocation : "/paymentlist"} className="btn btn-danger">
                            मागे जा
                          </Link>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Viewpayment
