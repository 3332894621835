import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useFieldArray, useForm } from "react-hook-form"

import { ToWords } from "to-words"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest, postRequest } from "common/Constants"
import { width } from "dom7"
import { indexOf } from "lodash"
import Select from "react-select"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

const Addinvoice = () => {
  const [startDate, setStartDate] = useState(new Date("04-03-2020"))
  const [selectedOption, setSelectedOption] = useState(null);
  //meta title
  document.title = "Samarth Fruit Company"

  const navigate = useNavigate()

  // to translate numbet to words start
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  })

  // to translate numbet to words end

  // to get merchant data to fill automatically start
  const [merchant, setMerchant] = useState([])
  const [personName, setPersonName] = useState([])
  const getAllMerchants = async () => {
    // const merchantResponse = await axios.get(`${reactAppUrl}/allMerchants`)
    const merchantResponse = await getRequest("allMerchants")
    setMerchant(merchantResponse?.data?.data)
    const mappedData = merchantResponse.data.data.map(item => ({
      value: item.merchant_id,
      label: item.name,
    }))
    setPersonName(mappedData)
  }

  const [merchantId, setMerchantId] = useState("")
  const [merchantData, setMerchantData] = useState(null)
  const getMerchantData = async e => {
    try {
    setMerchantId(getValues().Name)
    const responseData = await getRequest(
      `merchantDetail?merchant_id=${getValues().Name}`
    )

    setMerchantData(responseData.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  // to get merchant data to fill automatically end

  // file upload start
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const uploadFile = async e => {
    try {
      setSelectedImage(e.target.files[0])
      const formData = new FormData()
      formData.append("file", e.target.files[0])

      const responseData = await postRequest(`uploadSignature`, formData)

      if (responseData && responseData.status == 200) {
        setImageUrl(responseData.data.data.imageUrl)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // file upload end

  // react hook form start

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: "inputs",
  })

  const [additionalInputs, setAdditionalInputs] = useState([])
  const [rows, setRows] = useState(1)
  const [finalCost, setFinalCost] = useState(0)

  // addition of the automatic fields start

  const handleFinalWeight = index => e => {
    const FinalWeight =
      parseFloat(watch(`inputs[${index}].weight`)) -
        parseFloat(e.target.value) || 0
    setValue(`inputs[${index}].actual_weight`, String(FinalWeight))

    const finalRate = FinalWeight * parseFloat(watch(`inputs[${index}].rate`))
    setValue(`inputs[${index}].total`, String(finalRate))
  }
  const handleFinalWeight1 = index => e => {
    const FinalWeight =
      parseFloat(
        parseFloat(e.target.value) - watch(`inputs[${index}].cutting_weight`)
      ) || 0
    setValue(`inputs[${index}].actual_weight`, String(FinalWeight))

    const finalRate = FinalWeight * parseFloat(watch(`inputs[${index}].rate`))
    setValue(`inputs[${index}].total`, String(finalRate))
  }

  const handleRate = index => e => {
    const FinalRate =
      parseFloat(e.target.value) *
        parseFloat(watch(`inputs[${index}].actual_weight`)) || 0
    setValue(`inputs[${index}].total`, String(FinalRate))
  }

  // addition of the automatic fields end
  const [sum, setSum] = useState(0)

  const onSubmit = async (data, index) => {
    try {

      let arrayData = data.inputs

      const firstObject = {
        fruit_id: data.fruit,
        weight: data.Weight,
        cutting_weight: data.CuttingWeight,
        // actual_weight: data.CashWeight,
        actual_weight: String(data.Weight - data.CuttingWeight),
        rate: data.Price,
        // total: data.Total,
        total: String((data.Weight - data.CuttingWeight) * data.Price),
      }
      
      const newArray = arrayData.push(firstObject)

      const data1 = {
        merchant_id: merchantId,
        name: data.Name,
        date: data.date1,
        address: data.Address,
        // receipt_no: data.Receipt,
        vehicle_no: data.VehicleNo,
        notes: data.Notes ? data.Notes : "",
        // total: Number(data.TotalCost),
        total: sum,
        vehicle_charge: Number(data.TravelCost ? data.TravelCost : 0),
        other_charge: Number(data.ExtraCost ? data.ExtraCost : 0),
        overall_total: finalCost,
        invoiceInformation: data.inputs,
        // signature: imageUrl,
      }

      setIsLoading(true)
      const responseData = await postRequest("sellInvoiceCreate", data1)
      setIsLoading(false)
      // debugger
      if (responseData.status == 200) {
        navigate("/SalesInvoicelist")
      } else if (responseData.status == 409) {
        navigate("/Addinvoice")
      } else if (responseData.status == 400) {
        navigate("/Addinvoice")
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  // react hook form end

  let words = toWords.convert(finalCost ? finalCost : "0")

  useEffect(() => {
    calculateValue()
  }, [watch()])

  const calculateValue = () => {
    // let cost1 = getValues().TotalCost
    let cost1 = sum
    let cost2 = getValues().TravelCost ? getValues().TravelCost : 0
    let cost3 = getValues().ExtraCost ? getValues().ExtraCost : 0
    let cost = Number(cost1) - Number(cost2) - Number(cost3)
    setFinalCost(cost)
  }

  const addRow = () => {
    append({})
  }

  const handleAddInput = () => {
    setAdditionalInputs(prevInputs => [
      ...prevInputs,
      `additionalInput${prevInputs.length}`,
    ])
  }

  const [fruitSelect, setFruitSelect] = useState([])
  const getFruit = async () => {
    try {
      const responseData = await getRequest(`fruitDropdown`)
      if (responseData && responseData.status == 200) {
        setFruitSelect(responseData?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getFruit()
    getAllMerchants()
    if (merchantData?.address) {
      setValue("Address", merchantData?.address)
    }
  }, [merchantData])

  useEffect(() => {
    // While detailInvoice page is active, the invoice tab must also activated
    let element = document.getElementById("invoice")
    let element2 = document.getElementById("invoice-ul")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    if (element2) {
      element2.classList.add("mm-show")
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
        element2.classList.remove("mm-show") // remove the 'show' class to the element when change to another page
      }
    }
  }, [])
  useEffect(() => {
    let watchTotal = watch().inputs

    let sumValue = (watch("Weight") - watch("CuttingWeight")) * watch("Price")

    for (let i = 0; i < watchTotal.length; i++) {
      sumValue =
        Number(sumValue ? sumValue : 0) +
        Number(watchTotal[i].total != null ? watchTotal[i].total : 0)
    }

    setSum(sumValue)
  })

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="विक्री इनव्हॉइस" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row border-1">
                      <div className="col-xl-7 col-md-7 col-sm-12 col-12">
                        <div className="form-group mb-3">
                          <label className="col-form-label">नाव*</label>
                          {/* <select
                            id="formrow-InputState variety"
                            name="Name"
                            className="form-select"
                            {...register("Name", {
                              required: "कृपया आपले नाव टाका",
                            })}
                            onChange={getMerchantData}
                          >
                            <option value="">Select</option>

                            {merchant &&
                              merchant.map((merchants, index) => {
                                return (
                                  <option
                                    value={merchants.merchant_id}
                                    key={index}
                                  >
                                    {merchants.name}
                                  </option>
                                )
                              })}
                          </select> */}

                          <Select
                            styles={customStyles}
                            id="formrow-InputState variety"
                            name="Name"
                            className="basic-single"
                            classNamePrefix="select"
                            options={personName}
                            {...register("Name", {
                              required: "कृपया आपले नाव टाका",
                            })}
                            onChange={(selectedOption) => {
                              setValue("Name", selectedOption ? selectedOption.value : "");
                              getMerchantData()
                            }}
                            value={personName.find(
                              (option) =>
                                option.value === getValues().Name
                            )}
                          />

                          {errors.name && (
                            <span style={{ color: "red" }}>
                              {errors.name.message}
                            </span>
                          )}
                        </div>

                        <div className="form-group mb-3">
                          <label className="col-form-label">पत्ता*</label>

                          <textarea
                            className="form-control"
                            type="text"
                            name="Address"
                            disabled
                            defaultValue={merchantData && merchantData?.address}
                            {...register("Address")}
                          ></textarea>
                        </div>
                        <div className="form-group mb-3">
                          <label className="col-form-label">फोन नं*</label>
                          <input
                            className="form-control"
                            type="text"
                            name="Address"
                            disabled
                            defaultValue={merchantData?.phone_no}
                          />
                        </div>
                      </div>
                      <div className="col-xl-5 col-md-5 col-sm-12 col-12">
                        <div className="form-group mb-3">
                          <label className="col-form-label">दिनांक*</label>

                          <input
                            className="form-control"
                            type="date"
                            name="date1"
                            id="example-month-input month"
                            {...register("date1", {
                              required: "कृपया तारीख टाका",
                            })}
                          />
                          {errors.date1 && (
                            <p style={{ color: "red" }}>
                              {errors.date1.message}
                            </p>
                          )}
                        </div>
                        {/* <div className="form-group mb-3">
                          <label className="col-form-label">पावती क्र.*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Receipt"
                            {...register("Receipt", {
                              required: "कृपया पावती क्र. टाका",
                            })}
                          />
                          {errors.Receipt && (
                            <p style={{ color: "red" }}>
                              {errors.Receipt.message}
                            </p>
                          )}
                        </div> */}
                        <div className="form-group">
                          <label className="col-form-label">गाडी नं.*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="VehicleNo"
                            {...register("VehicleNo", {
                              required: "कृपया गाडी नं. टाका",
                            })}
                          />
                          {errors.VehicleNo && (
                            <p style={{ color: "red" }}>
                              {errors.VehicleNo.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="invoice-add-table">
                      <div className="row">
                        <div className="col-lg-12">
                          <h4>माहिती</h4>
                          <div
                            className="row"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <div
                              className="col-md-2 mb-3"
                              style={{ textAlign: "right" }}
                            >
                              {/* <a
                                href="javascript:void(0);"
                                className="add-btns btn btn-primary"
                              >
                                <i className="bx bx-plus align-middle"></i> नवीन
                              </a> */}
                              <button
                                type="button"
                                onClick={addRow}
                                className="btn btn-primary"
                              >
                                नवीन
                              </button>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-striped table-nowrap  mb-0 no-footer add-table-items">
                              <thead>
                                <tr>
                                  <th>फळाचे नाव*</th>
                                  <th>वजन*</th>
                                  <th>कटींग वजन*</th>
                                  <th>नगद वजन* </th>
                                  <th>भाव*</th>
                                  <th>एकूण*</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="add-row">
                                  <td>
                                    <select
                                      className="form-control"
                                      name="fruit"
                                      {...register("fruit")}
                                    >
                                      {/* <option value=""> select</option> */}
                                      <option value="1">पपई</option>

                                      {fruitSelect &&
                                        fruitSelect.map((fruitEach, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={fruitEach.fruit_id}
                                            >
                                              {fruitEach.name}
                                            </option>
                                          )
                                        })}
                                    </select>
                                    {/* {errors.fruit && (
                                      <span style={{ color: "red" }}>
                                        {errors.fruit.message}
                                      </span>
                                    )} */}
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="Weight"
                                        {...register("Weight", {
                                          required: "कृपया अंदाजे वजन टाका",
                                          pattern: {
                                            // value: /^\d+$/,
                                            value: /^\d+(\.\d+)?$/,
                                            message: "कृपया केवळ अंक टाका",
                                          },
                                        })}
                                      />
                                      <span className="p-1">Kg</span>
                                    </div>
                                    {errors.Weight && (
                                      <p style={{ color: "red" }}>
                                        {errors.Weight.message}
                                      </p>
                                    )}
                                  </td>

                                  <td>
                                    <div className="d-flex">
                                      <input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="CuttingWeight"
                                        {...register("CuttingWeight", {
                                          required:
                                            "कृपया अंदाजे कटींग वजन टाका",
                                          pattern: {
                                            // value: /^\d+$/,
                                            value: /^\d+(\.\d+)?$/,
                                            message: "कृपया केवळ अंक टाका",
                                          },
                                        })}
                                      />
                                      <span className="p-1">Kg</span>
                                    </div>
                                    {errors.CuttingWeight && (
                                      <p style={{ color: "red" }}>
                                        {errors.CuttingWeight.message}
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="CashWeight"
                                        value={
                                          watch("Weight") -
                                          watch("CuttingWeight")
                                        }
                                        disabled
                                        // {...register("CashWeight", {
                                        //   required: "कृपया अंदाजे नगद वजन टाका",
                                        //   pattern: {
                                        //     value: /^\d+$/,
                                        //     message: "कृपया केवळ अंक टाका",
                                        //   },
                                        // })}
                                      />
                                      <span className="p-1">Kg</span>
                                      {/* {errors.CashWeight && (
                                      <p style={{ color: "red" }}>
                                        {errors.CashWeight.message}
                                      </p>
                                    )} */}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <span className="p-1">₹</span>
                                      <input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="Price"
                                        {...register("Price", {
                                          required: "कृपया भाव टाका",
                                          pattern: {
                                            // value: /^\d+$/,
                                            value: /^\d+(\.\d+)?$/,
                                            message: "कृपया केवळ अंक टाका",
                                          },
                                        })}
                                      />
                                    </div>
                                    {errors.Price && (
                                      <p style={{ color: "red" }}>
                                        {errors.Price.message}
                                      </p>
                                    )}
                                  </td>

                                  <td>
                                    <div className="d-flex">
                                      <span className="p-2">₹</span>
                                      <input
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        name="Total"
                                        value={
                                          (watch("Weight") -
                                            watch("CuttingWeight")) *
                                          watch("Price")
                                        }
                                        disabled
                                        // value={watch()}
                                        // {...register("Total", {
                                        //   required: "कृपया एकूण टाका",
                                        //   pattern: {
                                        //     value: /^\d+$/,
                                        //     message: "कृपया केवळ अंक टाका",
                                        //   },
                                        // })}
                                      />
                                      {/* {errors.Total && (
                                      <p style={{ color: "red" }}>
                                        {errors.Total.message}
                                      </p>
                                    )} */}
                                    </div>
                                  </td>
                                </tr>
                                {fields.map((field, index) => (
                                  <tr key={field.id}>
                                    <td>
                                      {/* <input
                                        {...register(`inputs[${index}].name`)}
                                        type="text"
                                        placeholder="Name2"
                                        className="form-control"
                                        defaultValue={field.name}
                                      /> */}

                                      <select
                                        {...register(
                                          `inputs[${index}].fruit_id`
                                        )}
                                        className="form-control"
                                        defaultValue={field.fruit_id}
                                        name={`inputs[${index}].fruit_id`}
                                      >
                                        {/* <option value="">select</option> */}
                                        {/* <option value="1">पपई</option> */}

                                        {fruitSelect &&
                                          fruitSelect.map(
                                            (fruitEach, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={fruitEach.fruit_id}
                                                >
                                                  {fruitEach.name}
                                                </option>
                                              )
                                            }
                                          )}
                                      </select>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <input
                                          name={`inputs[${index}].weight`}
                                          {...register(
                                            `inputs[${index}].weight`
                                          )}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          defaultValue={field.weight}
                                          onChange={handleFinalWeight1(index)}
                                        />
                                        <span className="p-1">kg</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <input
                                          name={`inputs[${index}].cutting_weight`}
                                          {...register(
                                            `inputs[${index}].cutting_weight`
                                          )}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          defaultValue={field.cutting_weight}
                                          onChange={handleFinalWeight(index)}
                                        />
                                        <span className="p-1">kg</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <input
                                          name={`inputs[${index}].actual_weight`}
                                          {...register(
                                            `inputs[${index}].actual_weight`
                                          )}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          defaultValue={field.actual_weight}
                                          disabled
                                        />
                                        <span className="p-1">kg</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <span className="p-1">₹</span>
                                        <input
                                          name={`inputs[${index}].rate`}
                                          {...register(`inputs[${index}].rate`)}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          defaultValue={field.rate}
                                          onChange={handleRate(index)}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <span className="p-2">₹</span>
                                        <input
                                          name={`inputs[${index}].total`}
                                          {...register(
                                            `inputs[${index}].total`
                                          )}
                                          type="number"
                                          step="0.01"
                                          className="form-control"
                                          defaultValue={field.total}
                                          disabled
                                        />
                                      </div>
                                    </td>
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                    >
                                      <i className="mdi mdi-close-thick"></i>
                                    </button>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>नोट्स जोडा</label>
                          <textarea
                            type="text"
                            className="form-control"
                            name="Notes"
                            {...register("Notes")}
                          ></textarea>
                          {/* {errors.Notes && (
                            <p style={{ color: "red" }}>
                              {errors.Notes.message}
                            </p>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="invoice-total-card">
                          <h4 className="invoice-total-title">सारांश</h4>
                          <div className="invoice-total-box">
                            <div className="invoice-total-inner">
                              <p>
                                एकूण*{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>
                                    <input
                                      type="number"
                                      step="0.01"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="TotalCost"
                                      {...register("TotalCost")}
                                      value={sum}
                                      onKeyUp={calculateValue}
                                      disabled
                                    />
                                    {/* {errors.TotalCost && (
                                    <span style={{ color: "red" }}>
                                      {errors.TotalCost.message}
                                    </span>
                                  )} */}
                                  </div>
                                </span>
                              </p>

                              <p>
                                वाहतुक खर्च{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>

                                    <input
                                      type="number"
                                      step="0.01"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="TravelCost"
                                      {...register("TravelCost")}
                                      onKeyUp={calculateValue}
                                    />
                                    {/* {errors.TravelCost && (
                                        <span style={{ color: "red" }}>
                                          {errors.TravelCost.message}
                                        </span>
                                      )} */}
                                  </div>
                                </span>
                              </p>
                              <p>
                                इतर खर्च{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>

                                    <input
                                      type="number"
                                      step="0.01"
                                      className="form-control"
                                      placeholder="0.00"
                                      name="ExtraCost"
                                      {...register("ExtraCost")}
                                      onKeyUp={calculateValue}
                                    />
                                    {/* {errors.ExtraCost && (
                                        <span style={{ color: "red" }}>
                                          {errors.ExtraCost.message}
                                        </span>
                                      )} */}
                                  </div>
                                </span>
                              </p>

                              <div className="links-info-one">
                                <div className="links-info">
                                  <div className="links-cont">
                                    <a href="#" className="service-trash"></a>
                                  </div>
                                </div>

                                <div className="invoice-total-footer">
                                  <h4>
                                    एकूण{" "}
                                    <span>
                                      <div className="d-flex">
                                        <span className="p-2">₹</span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          disabled
                                          name="FinalCost"
                                          value={finalCost}
                                        />
                                      </div>
                                    </span>
                                  </h4>
                                </div>
                                <div className="invoice-total-inner1 mt-1">
                                  <p>
                                    अक्षरी{" "}
                                    <span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={words}
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {selectedImage ? (
                          <div
                            className="selectedimg"
                            style={{
                              width: "200px",
                              marginLeft: "250px",
                              height: "40px",
                              marginBottom: "10px",
                              position: "relative",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              alt="not found"
                              width={"250px"}
                              height={"40px"}
                              src={URL.createObjectURL(selectedImage)}
                            />

                            <button
                              className="img-remove"
                              onClick={() => setSelectedImage(null)}
                            >
                              <i className="mdi mdi-close-thick"></i>
                            </button>
                          </div>
                        ) : (
                          <div className="form-group service-upload mt-3 mb-3">
                            <span>अपलोड स्वाक्षरी</span>
                            <input
                              accept="image/png, image/gif, image/jpeg"
                              type="file"
                              onChange={uploadFile}
                            />
                          </div>
                        )} */}

                        <div
                          className="mt-3 mb-3"
                          style={{ textAlign: "right" }}
                        >
                          <div
                            className="selectedimg"
                            style={{
                              height: "40px",
                              marginBottom: "10px",
                              position: "relative",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              style={{ border: "0.5px solid black" }}
                              alt="not found"
                              width={"250px"}
                              height={"50px"}
                              src={
                                "https://samarthfruitcompany.in/api/public/signature/signature.jpeg"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        className="col-md-2 col-5"
                        style={{ textAlign: "left" }}
                      >
                        <Link to="/SalesInvoicelist" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div
                        className="col-md-2 col-7"
                        style={{ textAlign: "right" }}
                      >
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addinvoice
