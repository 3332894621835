import React, { useEffect, useState } from "react"

import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest } from "common/Constants"
import { Pagination } from "@mui/material"
import moment from "moment"

const Farmer = () => {
  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  // hooks start
  const navigate = useNavigate()
  // hooks end

  const[perPageItem, setPerPageItem] = useState(0)
  const [paymentList, setPaymentList] = useState([])

  const getPaymentList = async () => {
    try {
      const responseData = await getRequest(`paymentList?page=${page}`)
      setPaymentList(responseData.data.data)
      let totalPages = responseData.data.total / responseData.data.perPage
      setTotalCount(Math.ceil(totalPages))
      setPerPageItem(responseData.data.perPage)
    } catch (error) {
      console.log(error)
    }
  }

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  //   to go to the view page with the id start
  const handleView = id => {
    try {
      navigate("/viewpayment", { state: { id: id, prevLocation:"/dashboard" } })
    } catch (error) {
      console.log(error)
    }
  }
  //   to go to the view page with the id end

  useEffect(() => {
    getPaymentList()
  }, [page])

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="mb-4 h4 card-title">नवीनतम व्यवहार</div>
              {/* <div className="mb-2 row">
                <div className="col-md-2">
                  <select className="form-select">
                    <option value="10">Show 10</option>
                    <option value="20">Show 20</option>
                    <option value="30">Show 30</option>
                    <option value="40">Show 40</option>
                    <option value="50">Show 50</option>
                  </select>
                </div>
              </div> */}
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <table
                    id="tech-companies-1"
                    className="table table-bordered table-hover"
                  >
                    <thead className="table-light table-nowrap">
                      <tr>
                        <th>अ.क्र.</th>
                        <th>तारीख</th>
                        <th>नाव</th>
                        <th>डेबिट/क्रेडिट</th>
                        <th>पेमेंट पद्धत(कॅश/बँकिंग) </th>
                        <th>तपशील पहा</th>
                      </tr>
                    </thead>

                    {paymentList &&
                      paymentList.map((payment, index) => {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>{((page*perPageItem) - perPageItem)+(index+1)}</td>
                              {/* <td>{!!payment?.date ? payment.date : "-"}</td> */}
                              <td>{!!payment?.date ? moment(payment?.date).format("DD/MM/YYYY") : "-"}</td>
                              <td>{!!payment?.name ? payment.name : "-"}</td>
                              <td>
                                {!!payment?.payment_mode
                                  ? payment.payment_mode == "1"
                                    ? "debit"
                                    : payment.payment_mode == "2"
                                    ? "credit"
                                    : "-"
                                  : "-"}
                              </td>
                              <td>
                                {!!payment?.payment_type
                                  ? payment.payment_type == "1"
                                    ? "cash"
                                    : payment.payment_type == "2"
                                    ? "banking"
                                    : "-"
                                  : "-"}
                              </td>
                              <td>
                                <a
                                  className="btn-sm btn-view  btn"
                                  onClick={e => handleView(payment?.payment_id)}
                                >
                                  <div className="tooltip1">
                                    <i className="bx bx-file align-middle ">
                                      <span className="tooltiptext">पहा</span>
                                    </i>
                                  </div>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })}
                  </table>
                
                </div>
                <ul className="pagination modal-1" style={{ float: "right" }}>
                    <Pagination
                      count={totalCount}
                      page={page}
                      onChange={handleChange}
                      color="primary"
                      shape="rounded"
                    />
                    {/* <li>
                      <a href="#" className="prev">
                        «
                      </a>
                    </li>
                    <li>
                      <a href="#" className="active">
                        1
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">2</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#">3</a>
                    </li>

                    <li>
                      <a href="#" className="next">
                        »
                      </a>
                    </li> */}
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Farmer
