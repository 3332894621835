import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import logoDark from "../../assets/images/logo.png"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import sideimg from "../../assets/images/side-img.jpg"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest } from "common/Constants"
import moment from "moment"
import vehicle from "../../assets/images/vehicle.png"
import phone from "../../assets/images/phone.png"
const Addinvoice = () => {
  const [startDate, setStartDate] = useState(new Date("04-03-2020"))
  //meta title
  document.title = "Samarth Fruit Company"

  //   hooks start
  const navigate = useNavigate()
  const location = useLocation()
  //   hooks end

  const [id, setId] = useState(location.state.id)

  // To get the data for the specific id start
  const [vehicleInvoice, setVehicleInvoice] = useState([])
  const getVehicleInvoice = async () => {
    try {
      const responseData = await getRequest(
        `vehicleInvoiceDetail?vehicle_invoice_id=${id}`
      )
      if (responseData && responseData.status == 200) {
        setVehicleInvoice(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // To get the data for the specific id end

  useEffect(() => {
    getVehicleInvoice()
  }, [])

  // to download pdf start

  const componentRef = useRef(null)

  const downloadPDF = () => {
    // const pageWidth = 210 // Width of the PDF page in mm (A4 size)
    // const pageHeight = 297 // Height of the PDF page in mm (A4 size)
    // const pageMargin = 10 // Margin of the PDF content in mm
    // const orientation = "portrait" // 'portrait' or 'landscape'

    html2canvas(componentRef.current)
      .then(canvas => {
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF()
        const imgProps = pdf.getImageProperties(imgData)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
        pdf.save("component.pdf")
      })
      .catch(error => {
        console.error("Error generating PDF:", error)
      })
  }

  // to download pdf end

  useEffect(() => {
    // While ViewVehicleInvoice page is active, the invoice tab must also activated
    let element = document.getElementById("invoice")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
      element.classList.add("mm-show") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="इनव्हॉइस" />

          <div className="row">
            <div className="col">
              <div className="card card-invoice d-flex">
                <div className="invoice-img">
                  <img src={sideimg} alt="" className="side-imgg" />
                </div> 
                <div className="card-body invoice-body" ref={componentRef}>
                  <div className="card-invoice-head">
                    <div className="row">
                      <div
                        className="col-md-3"
                        style={{ textAlign: "center", width: "20%" }}
                      >
                        <img
                          src={logoDark}
                          alt=""
                          height="120px"
                          width="120px"
                          className="invoice_img"
                        />
                      </div>
                      <div
                        className="col-md-9 mx-auto text-center justify-content-center"
                        style={{ width: "80%" }}
                      >
                        <h1 className="card-invoice-heading">
                          श्री समर्थ फ्रुट कंपनी, मालेगाव
                        </h1>
                        <h5 className="card-invoice-add">
                          पत्ता : ग्रामपंचायत कॉम्प्लेक्स, मालेगाव, ता. अर्धापूर, जि. नांदेड, महाराष्ट्र.
                        </h5>
                        <h4 className="card-invoice-phone">
                          <img src={phone} width="20"/> 9422842222,8888555445
                        </h4>
                      </div>
                      {/* <div
                      className="col-md-6 position-relative"
                      style={{ textAlign: "right" }}
                    >
                      <a href="#" className="btn btn-download">
                        <div className="tooltip1">
                          <i className="bx bx-download">
                            <span className="tooltiptext">डाउनलोड करा</span>
                          </i>
                        </div>
                      </a>
                    </div> */}
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-3 m-auto justify-content-center text-center">
                  <div className="card-invoice-label">
                  <h5  className="card-invoice-add">
                  वाहतूक  इन्व्हॉईस
                      </h5>
                  </div>
                  </div>
                  </div> */}
                   <div className="img-part">
                  <div className="row img-row">
                    <div
                      className="col-md-3 card-invoice-img justify-content-center  text-center"
                      style={{ width: "25%", marginTop: "10px" }}
                    >
                      {/* <div className="card-invoice-label">
                  <h5  className="card-invoice-add">
                  विक्री इन्व्हॉईस
                      </h5>
                  </div> */}
                      <img src={vehicle} alt="" />
                    </div>
                  </div>
                  </div>
                  <div className="row d-none">
                    <div className="col-md-6" style={{ textAlign: "left" }}>
                      <img
                        src={logoDark}
                        alt=""
                        height="95px"
                        className="invoice_img"
                      />
                    </div>
                    {/* <div
                      className="col-md-6 position-relative"
                      style={{ textAlign: "right" }}
                    >
                      <a href="#" className="btn btn-download">
                        <div className="tooltip1">
                          <i className="bx bx-download">
                            <span className="tooltiptext">डाउनलोड करा</span>
                          </i>
                        </div>
                      </a>
                    </div> */}
                  </div>
                  <div className="invoice-form-part">
                  <div className="row  invoice-form-detail">
                    <div
                      className="col-xl-9 col-md-9 col-sm-12 col-12"
                      style={{ textAlign: "left", width: "75%" }}
                    >
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <h2 className="h2_label mb-0">
                          नाव <span className="p-1">:</span>
                        </h2>

                        <p className="mb-0 para1">
                          {!!vehicleInvoice?.name ? vehicleInvoice?.name : "-"}
                        </p>
                      </div>
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <h2 className=" h2_label mb-0">
                          पत्ता <span className="p-1">:</span>
                        </h2>

                        <p className="mb-0 para1">
                          {!!vehicleInvoice?.address
                            ? vehicleInvoice?.address
                            : "-"}
                        </p>
                      </div>
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <h2 className="h2_label mb-0">
                        फोन नं. <span className="p-1">:</span>
                        </h2>

                        <p className="mb-0 para1">{vehicleInvoice?.phone_no}</p>
                      </div>
                    </div>
                    <div
                      className="col-xl-3 col-md-3 col-sm-12 col-12"
                      style={{ textAlign: "left" ,width:"25%"}}
                    >
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <h2 className="h2_label mb-0">
                          दिनांक <span className="p-1">:</span>
                        </h2>

                        <p className="mb-0 para1">
                          {/* {!!vehicleInvoice?.date ? vehicleInvoice?.date : "-"} */}
                          {!!vehicleInvoice?.date
                            ? moment(vehicleInvoice?.date).format("DD/MM/YYYY")
                            : "-"}
                        </p>
                      </div>
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <h2 className="h2_label mb-0">
                          पावती क्र<span className="p-1">:</span>
                        </h2>

                        <p className="mb-0 para1">
                          {!!vehicleInvoice?.receipt_no
                            ? vehicleInvoice?.receipt_no
                            : "-"}
                        </p>
                      </div>
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <h2 className=" h2_label mb-0">
                          गाडी नं. <span className="p-1">:</span>
                        </h2>

                        <p className="mb-0 para1">
                          {!!vehicleInvoice?.vehicle_no
                            ? vehicleInvoice?.vehicle_no
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="invoice-card-note-part">
                  <div className="row invoice-card-note ">
                    <div className="col-md-6" style={{width:"50%"}}>
                      <div className="mb-3">
                        <h2 className="h2_label">नोट :</h2>
                        <p className="mb-0 para1">
                          {!!vehicleInvoice?.notes
                            ? vehicleInvoice?.notes
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6" style={{width:"50%"}}>
                      <div className="invoice-total-card">
                        {/* <h4 className="invoice-total-title">सारांश</h4> */}
                        <div className="invoice-total-box">
                          <div className="invoice-total-inner view-invoice-total-inner">
                            <p>
                              गाडी भाडे{" "}
                              <span>
                                ₹
                                {!!vehicleInvoice?.vehicle_charge
                                  ? vehicleInvoice?.vehicle_charge
                                  : "-"}
                              </span>
                            </p>
                            <p>
                              ओव्हरलोड{" "}
                              <span>
                                ₹
                                {!!vehicleInvoice?.overload_charge
                                  ? vehicleInvoice?.overload_charge
                                  : "-"}
                              </span>
                            </p>
                            <p>
                              इतर खर्च{" "}
                              <span>
                                ₹
                                {!!vehicleInvoice?.other_charge
                                  ? vehicleInvoice?.other_charge
                                  : "-"}
                              </span>
                            </p>
                            <p>
                              ऍडवान्स{" "}
                              <span>
                                ₹
                                {!!vehicleInvoice?.advance
                                  ? vehicleInvoice?.advance
                                  : "-"}
                              </span>
                            </p>

                            <div className="links-info-one">
                              <div className="links-info">
                                <div className="links-cont">
                                  <a href="#" className="service-trash"></a>
                                </div>
                              </div>

                              <div className="invoice-total-footer view-invoice-total-footer">
                                <h4>
                                  एकूण{" "}
                                  <span>
                                    ₹{" "}
                                    {!!vehicleInvoice?.overall_total
                                      ? vehicleInvoice?.overall_total
                                      : "-"}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div style={{ float: "right" }}>
                        <img
                          src={vehicleInvoice?.signature}
                          style={{
                            width: "200px",
                            height: "100px",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        />
                      </div> */}

                      <div
                        className="mt-3 mb-2 printview d-none"
                        style={{ textAlign: "right" }}
                      >
                        <div
                          className="selectedimg"
                          style={{
                            height: "40px",
                            marginBottom: "10px",
                            position: "relative",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            style={{ border: "0.5px solid black" }}
                            alt="not found"
                            width={"250px"}
                            height={"50px"}
                            src={
                              "https://samarthfruitcompany.in/api/public/signature/signature.jpeg"
                            }
                          />
                        </div>
                      </div>

                      {/* <div className="form-group service-upload mt-3 mb-3">
                        <span>अपलोड स्वाक्षरी</span>
                        <input type="file" multiple="" />
                      </div> */}
                      {/* <div className="form-group float-end mb-0">
                        <button
                          className="btn btn-primary"
                          onClick={downloadPDF}
                        >
                          प्रिंट करा
                        </button>
                      </div> */}
                    </div>
                  </div>
                  </div>
                  <div className="card-rules">
                  <div className="row"
                   
                  >
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="notes-label">नियम व अटी : </label>
                        <p className="para2">
                          गाडी मध्ये मोजून आणि भरून दिलेला माल चांगल्या
                          अवस्थेमध्ये ठरलेल्या ठिकाणापर्यंत आणि ठरलेल्या वेळेत
                          पोहोचवण्याची जिम्मेदारी गाडी मालक आणि चालकाची असेल.
                        </p>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="card-footer-part">
                  <div className="row invoice-footer">
                    <div className="col-md-6  invoice-footer-print" style={{width:"50%",display:"flex",justifyContent:"center",flexDirection:"column",paddingBottom:"20px"}}>
                      <div className="d-flex">
                        <h2 className=" h2_label mb-0">
                          गाडी चालक नाव <span className="p-1">:</span>
                        </h2>
                        <p className="mb-0 para1">
                          {!!vehicleInvoice?.driver_name
                            ? vehicleInvoice?.driver_name
                            : "-"}
                        </p>
                      </div>
                      <div className="d-flex">
                        <h2 className=" h2_label mb-0">
                          मो.नं.<span className="p-1">:</span>
                        </h2>
                        <p className="mb-0 para1">
                          {!!vehicleInvoice?.driver_mobile_no
                            ? vehicleInvoice?.driver_mobile_no
                            : "-"}
                        </p>
                      </div>
                    </div>
                  
                    <div className="col-md-6 mt-3 mb-3 text-right justify-content-center d-flex"
                     style={{
                      width: "50%",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                     
                      <div
                          className="selectedimg"
                          style={{
                            height: "40px",
                            marginBottom: "10px",
                            position: "relative",
                            marginTop: "10px",
                            justifyContent: "end",
                            display: "flex",
                          }}
                        >
                          <img
                           
                            alt="not found"
                            width={"230px"}
                            height={"50px"}
                            src={
                              "https://samarthfruitcompany.in/api/public/signature/signature.jpeg"
                            }
                          />
                          </div>
                        <p className="sign-p">श्री समर्थ फ्रुट कंपनी</p>
                        </div>
                    </div>
                  </div>

                  <div
                    className="row mb-3 d-print-none print-btn"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div
                      className="col-md-2 col-5"
                      style={{ textAlign: "left" }}
                    >
                      <Link to="/VehicleInvoice" className="btn btn-danger">
                        मागे जा
                      </Link>
                    </div>
                    <div
                      className="col-md-2 col-7"
                      style={{ textAlign: "right" }}
                    >
                      {/* <button className="btn btn-primary" onClick={downloadPDF}> */}
                      <button
                        className="btn btn-primary"
                        onClick={() => window.print()}
                      >
                        प्रिंट करा
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addinvoice
