import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import { Pagination } from "@mui/material"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Modal } from "react-bootstrap"
import { getRequest } from "common/Constants"

const Otherslist = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  document.title = "Samarth Fruit Company"

  const navigate = useNavigate()

  const [otherPay, setOtherPay] = useState([])
  const getOtherPay = async () => {
    const otherResponse = await getRequest(`otherList?page=${page}&name=${name}&phone_no=${phone}`) //api
    setOtherPay(otherResponse.data.data)
    let totalPages = otherResponse?.data?.total / otherResponse?.data?.perPage
        setTotalCount(Math.ceil(totalPages))
        setPerPageItem(otherResponse.data.perPage)
  }

  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  const [deleteId, setDeleteId] = useState("")
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  const [perPageItem, setPerPageItem] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `deleteOther?otherId=${deleteId}`   //api
      )
      setIsLoading(false)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const handleView = id => {
    try {
      navigate("/Viewothers", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = id => {
    try {
      navigate("/Editothers", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getOtherPay();
  }, [page, render])
  return (
    <div>
      <div className="row mt-3">
        <div className="col">
          <form className="">
            <div className="row mb-4">
              <div className="col-sm-3 col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">नाव</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="नाव"
                    onChange={e => {
                      setName(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-3 col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">फोन नं.</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="फोन नं."
                    minLength={10}
                    maxLength={10}
                    onChange={e => {
                      setPhone(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-2 col-xl-2 align-self-end">
                <div className="mb-3">
                  <Button type="button" color="primary" className="w-md" onClick={() => {
                    setPage(1);
                    getOtherPay()
                  }}>
                    फिल्टर
                  </Button>
                </div>
              </div>
            </div>
          </form>
          <div className="table-rep-plugin ">
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              <table
                id="tech-companies-1"
                className="table table-striped table-bordered"
              >
                <thead>
                  <tr>
                    <th>अ.क्र.</th>
                    <th>नाव</th>
                    <th>प्रवर्ग</th>
                    <th>फोन नं</th>
                    <th>पत्ता</th>

                    {/* <th>व्हरायटी</th>
                        <th>लागवड क्षेत्र (एकर मध्ये )</th>
                        <th>झाडांची संख्या</th>
                        <th>अंदाजे वजन (टन मध्ये)</th>
                        <th>लागवडीची तारीख</th>
                        <th>काढणी तारीख</th>
                        <th>तोडणी वारंवारिता</th>
                        <th>तोडणी महिने</th> */}
                    <th></th>
                  </tr>
                </thead>
                {
                  otherPay && otherPay.map((item, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>
                            {page * perPageItem -
                              perPageItem +
                              (index + 1)}
                          </td>
                          <td>{item?.otherName}</td>
                          <td>{item?.catName}</td>
                          <td>{item?.phone_no}</td>
                          <td>{item?.address}</td>

                          <td>
                            <a className="btn-sm btn-view  btn" 
                            // href="/Viewothers"
                            onClick={e => handleView(item?.otherId)}
                            >
                              <div className="tooltip1">
                                <i className="bx bx-file align-middle ">
                                  <span className="tooltiptext">पहा</span>
                                </i>
                              </div>
                            </a>

                            <a className="btn-sm btn-edit  btn"
                            //  href="/Editothers"
                             onClick={e =>
                              handleEdit(item?.otherId)
                            }
                             >
                              <div className="tooltip1">
                                <i className="bx bx-edit align-middle">
                                  <span className="tooltiptext">संपादित करा</span>
                                </i>
                              </div>
                            </a>

                            <a className="btn-sm btn-delete btn" 
                             onClick={()=>handleShow(item?.otherId)}
                            >
                              <div className="tooltip1">
                                <i className="bx bxs-trash align-middle">
                                  <span className="tooltiptext">हटवा</span>
                                </i>
                              </div>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })
                }
              </table>
            </div>
            <ul className="pagination modal-1" style={{ float: "right" }}>
              <Pagination color="primary" shape="rounded" />
            </ul>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>इतर हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleDelete}  className="btn btn-success" disabled={isLoading} >
          {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Otherslist
