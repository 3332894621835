import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledCollapse,
} from "reactstrap"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.png"
import { getRequest } from "common/Constants"

function UserProfile(props) {
   const [isLoading, setIsLoading] = useState(false)

  // to get the data of the login user start
  const [userData, setUserData] = useState([])
  const getLoginUserData = async () => {
    setIsLoading(true)
    const responseData = await getRequest(`loginUserDetail`)
    setIsLoading(false)
    if (responseData && responseData.status == 200) {
      setUserData(responseData.data)
    }
  }
  // to get the data of the login user end

  useEffect(() => {
    getLoginUserData()
  }, [])

  return (
    <React.Fragment>
      {/* <Row>
        <Col lg="12">
          <Card>
            <CardBody> */}
              <div className="d-flex">
                <div className="ms-3">
                  <img
                    src={avatar}
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div>
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted profile-name">
                    <h5>Admin</h5>
                    {/* <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p> */}
                  </div>
                </div>
              </div>
            {/* </CardBody>
          </Card>
        </Col>
      </Row> */}

      {
        isLoading ? 
        <>
        <h1><Skeleton/></h1>
        <h1><Skeleton/></h1>
        <h1><Skeleton/></h1>
        <h1><Skeleton/></h1>
        <h1><Skeleton/></h1>
        </> : 
        <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <form>
                <div className="row mb-3 border-1">
                  <label className="col-md-2 col-form-label">नाव</label>
                  <div className="col-md-10 m-auto">
                    <p className="mb-0 para1">
                      {!!userData?.name ? userData.name : "-"}
                    </p>
                  </div>
                </div>
                <div className="row mb-3 border-1">
                  <label className="col-md-2 col-form-label">पत्ता</label>
                  <div className="col-md-10 m-auto">
                    <p className="mb-0 para1">{!!(userData?.address)? userData.address : "-"}</p>
                  </div>
                </div>

                <div className="row mb-3 border-1">
                  <label
                    htmlFor="example-tel-input"
                    className="col-md-2 col-form-label"
                  >
                    फोन नं
                  </label>
                  <div className="col-md-10 m-auto">
                    <p className="mb-0 para1">{!!(userData?.phone_no)? userData.phone_no : "-"}</p>
                  </div>
                </div>
                <div className="row mb-3 border-1">
                  <label
                    htmlFor="example-tel-input"
                    className="col-md-2 col-form-label"
                  >
                    रॉल्स
                  </label>
                  <div className="col-md-10 m-auto">
                    <p className="mb-0 para1">{!!(userData?.role)? userData.role : "-"} </p>
                  </div>
                </div>
                <div className="row mb-3 border-1">
                  <label
                    htmlFor="example-tel-input"
                    className="col-md-2 col-form-label"
                  >
                    ई-मेल
                  </label>
                  <div className="col-md-10 m-auto">
                    <p className="mb-0 para1">{!!(userData?.email)? userData.email : "-"}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      }



      

      {/* <h4 className="card-title mb-4">Change User Name</h4>
        
          
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
    </React.Fragment>
  )
}

export default UserProfile
