import React, { useEffect, useState } from "react"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import Sidebar from "components/VerticalLayout/Sidebar"
import { Button } from "reactstrap"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Link, useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  getHeader,
  getRequest,
  postRequest,
  responsecode200,
} from "common/Constants"

const Editfarmerorder = () => {
  //meta title

  // hooks start
  const navigate = useNavigate()
  const location = useLocation()
  // hooks end

  const [id, setId] = useState(location.state.id)
  const [isLoading, setIsLoading] = useState(false)

  // get the specific farmer data start
  const [farmerData, setFarmerData] = useState([])

  const getFarmerDetails = async () => {
    try {
      const responseData = await getRequest(`FarmerDetail?farmer_id=${id}`)
      if (responseData && responseData.status == 200) {
        setFarmerData(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // get the specific farmer data end

  // to get the taluka list start
  const [taluka, setTaluka] = useState([])
  const getTalukaList = async () => {
    try {
      const responseData = await getRequest(`talukaDropdown`)
      if (responseData && responseData.status == 200) {
        setTaluka(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the taluka list end
  // to get the district list start
  const [district, setDistrict] = useState([])
  const getDistrictList = async () => {
    try {
      const responseData = await getRequest(`districtDropdown`)
      if (responseData && responseData.status == 200) {
        setDistrict(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the district list end

  const [validToken, setValidToken] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: farmerData.name || "",
      Address: farmerData.address || "",
      Village: farmerData.village || "",
      District: farmerData.district_id || "",
      Taluka: farmerData.taluka_id || "",
      Telephone: farmerData.phone_no || "",
      papaya: farmerData.is_planted || "",
      // City: "",
      // Variety: "",
      // area: "",
      // Counts: "",
      // weight: "",
      // date1: "",
      // date2: "",
      // month: "",
      // frequency: "",
      // nopapaya: "",
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .min(2, "कमीत कमी 2 अक्षर टाका ")
        .required("कृपया आपले नाव टाका")
        .matches(/^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/, {
          message: "केवळ अंक मान्य नाहीत",
        }),
      Address: Yup.string()
        .min(3, "कमीत कमी ३ अक्षर टाका ")
        .required("कृपया तुमचा पत्ता टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
      Village: Yup.string()
        .min(3, "कमीत कमी ३ अक्षर टाका ")
        .required("कृपया  गावाचे नाव टाका")
        .matches(/^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/, {
          message: "कृपया गावाचे नाव टाका",
        }),
      District: Yup.string().required("कृपया जिल्ह्याचे नाव टाका"),
      Taluka: Yup.string().required("कृपया तालुक्याचे नाव टाका"),
      Telephone: Yup.string()
        .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .required("कृपया  फोन नं. टाका"),
        papaya: Yup.string().required("कृपया पपई लागवड केलेले टाका"),
      // Variety: Yup.string().required("कृपया  व्हरायटी टाका"),
      // area: Yup.string().required("कृपया लागवड केलेले क्षेत्र टाका"),
      // Counts: Yup.string().required("कृपया झाडांची संख्या  टाका"),
      // weight: Yup.string().required("कृपया अंदाजे वजन टाका"),
      // date1: Yup.string().required("कृपया तारीख टाका"),
      // date2: Yup.string().required("कृपया तारीख टाका"),
      // frequency: Yup.string().required("कृपया तोडणी वारंवारिता टाका"),
      // month: Yup.string().required("कृपया महिने टाका"),
      // // nopapaya:Yup.string().required("कृपया फिएल्डस  टाका"),
    }),
    onSubmit: async values => {
      try {
        let data = {
          name: values.Name,
          farmer_id: id,
          address: values.Address,
          village: values.Village,
          taluka_id: values.Taluka,
          district_id: values.District,
          phone_no: values.Telephone,
          is_planted: values.papaya,
        }
        setIsLoading(true)
        const responseData = await postRequest(`farmerUpdate`, data)
        setIsLoading(false)
        if (responseData && responseData.status == 200) {
          navigate("/farmer/Listindex")
        } else if (responseData && responseData.status == 409) {
          navigate("/Editfarmer")
        }

        // const response = await axios.post(
        //   `${reactAppUrl}/createFarmer`,
        //   data,
        //   getHeader()
        // )

        // if (response && response.status == responsecode200()) {
        //   toast.success(response.data.message)
        //   navigate("/farmer/Listindex")
        // }
      } catch (error) {
        setIsLoading(false)
        if (error.response && error.response.status == responsecode408()) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.response.data.message)
        }
      }
    },
  })

  const [variery, setVariety] = useState([])
  const getVariety = async () => {
    try {
      const responseData = await getRequest(`varietyList`)
      if (responseData && responseData.status == 200) {
        setVariety(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVariety()
    getFarmerDetails()
    getTalukaList()
    getDistrictList()
  }, [])

  useEffect(() => {
    // While view farmer page is active, the yadi tab must also activated
    let element = document.getElementById("farmerList")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="शेतकरी" />

              <div className="row">
                <div className="col" style={{ padding: "0" }}>
                  <div className="card">
                    <div className="card-body">
                      <div className="cardTitle h4 d-none">Textual inputs</div>
                      <p className="card-title-desc d-none">
                        Here are examples of <code>.form-control</code> applied
                        to each textual HTML5 <code>&lt;input&gt;</code>{" "}
                        <code>type</code>.
                      </p>
                      <form
                        className="needs-validation"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">नाव*</label>
                          <div className="col-md-10">
                            <input
                              id="Name"
                              name="Name"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Name}
                            />
                            {validation.touched.Name &&
                            validation.errors.Name ? (
                              <span className="error">
                                {validation.errors.Name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            पत्ता*
                          </label>
                          <div className="col-md-10">
                            <textarea
                              id="Address"
                              name="Address"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Address}
                            />
                            {validation.touched.Address &&
                            validation.errors.Address ? (
                              <span className="error">
                                {validation.errors.Address}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <div className="row">
                              <label className="col-md-4 col-form-label">
                                गाव*
                              </label>
                              <div className="col-md-8">
                                <input
                                  id="Village"
                                  name="Village"
                                  className="form-control"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Village}
                                />
                                {validation.touched.Village &&
                                validation.errors.Village ? (
                                  <span className="error">
                                    {validation.errors.Village}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="row">
                              <label className="col-md-4 col-form-label">
                                तालुका*
                              </label>
                              <div className="col-md-6 col-10">
                                <select
                                  id="Taluka"
                                  name="Taluka"
                                  className="form-select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Taluka}
                                >
                                  <option value="">select</option>
                                  {taluka &&
                                    taluka.map((farmers, index) => {
                                      return (
                                        <option
                                          value={farmers.taluka_id}
                                          key={index}
                                        >
                                          {farmers.name}
                                        </option>
                                      )
                                    })}
                                </select>
                                {validation.touched.Taluka &&
                                validation.errors.Taluka ? (
                                  <span className="error">
                                    {validation.errors.Taluka}
                                  </span>
                                ) : null}
                              </div>
                              <div className="col-md-2 col-2 btn-col">
                                <Link to="/Information" className="btn btn-primary">ऍड</Link>
                              </div>
                            </div>
                          </div>


                          <div className="col-md-6 mb-3">
                            <div className="row">
                              <label className="col-md-4 col-12 col-form-label">
                                जिल्हा*
                              </label>
                              <div className="col-md-6 col-10">

                                <select
                                  id="District"
                                  name="District"
                                  className="form-select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.District}
                                >
                                  <option value="">select</option>
                                  {district &&
                                    district.map((farmers, index) => {
                                      return (
                                        <option
                                          value={farmers.district_id}
                                          key={index}
                                        >
                                          {farmers.name}
                                        </option>
                                      )
                                    })}
                                </select>
                                {validation.touched.District &&
                                validation.errors.District ? (
                                  <span className="error">
                                    {validation.errors.District}
                                  </span>
                                ) : null}
                              </div>
                              <div className="col-md-2 col-2 btn-col">
                                <Link to="/Information" className="btn btn-primary">ऍड</Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="row">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-4 col-form-label"
                              >
                                फोन नं*
                              </label>
                              <div className="col-md-8">
                                <input
                                  id="Telephone"
                                  name="Telephone"
                                  className="form-control"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Telephone}
                                />
                                {validation.touched.Telephone &&
                                validation.errors.Telephone ? (
                                  <span className="error">
                                    {validation.errors.Telephone}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="row">
                              <label className="col-md-4 col-form-label">
                                पपई लागवड केलेले*
                              </label>
                              <div className="col-md-8">
                                <select
                                  id="formrow-InputState variety"
                                  name="papaya"
                                  className="form-select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.papaya == 1 ? 1 : 0}
                                >
                                  <option value="">select</option>
                                  <option value="1">हो </option>
                                  <option value="0">नाही </option>
                                </select>
                                {validation.touched.papaya &&
                                validation.errors.papaya ? (
                                  <span className="error">
                                    {validation.errors.papaya}
                                  </span>
                                ) : null}
                              </div>{" "}
                            </div>
                          </div>
                        </div>

                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2 col-5"
                            style={{ textAlign: "left" }}
                          >
                            <Link
                              to="/farmer/Listindex"
                              className="btn btn-danger"
                            >
                              मागे जा
                            </Link>
                          </div>
                          <div
                            className="col-md-2 col-7"
                            style={{ textAlign: "right" }}
                          >
                            <Button color="primary" type="submit" disabled = {isLoading}>
                            {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Editfarmerorder
