import { getRequest } from "common/Constants"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
import { Button, Label } from "reactstrap"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const TopCities = () => {

  const [isLoading, setIsLoading] = useState(false)


  // get month data start
  const [date, setDate] = useState("")
  const getMonth = (e) =>{
    setDate(e.target.value)
  }
  // get month data end

  // data for the getting the top cities start

  const [talukas, setTalukas] = useState([])
  const getTheCityData = async() =>{
    try {
      setIsLoading(true)
      const responseData = await getRequest(`papayaPalntedTalukas?date=${date}`)
      setIsLoading(false)
      // console.log(responseData.data.data)
      if(responseData && responseData.status ==200){
        setTalukas(responseData?.data?.data)
      }
      
    } catch (error) {
      
    }
  }
  // data for the getting the top cities end

  useEffect(() => {
    getTheCityData()
  },[date])



  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            पपईची लागवड असलेले शीर्ष तालुके
          </CardTitle>
          {/* <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>1,456</h3>
            <p>San Francisco</p>
          </div> */}
          <form>
            <div className="row mb-3">
              <div className="col-sm-4 col-xl-4">
                <div className="form-group">
                  <input type="month" className="form-control" onChange={getMonth}/>
                </div>
              </div>
              {/* <div className="col-sm-3 col-xl-3 align-self-end">
                <div>
                  <Button type="button" color="primary" className="w-md">
                    फिल्टर
                  </Button>
                </div>
              </div> */}
            </div>
          </form>
          {
            isLoading ? 
            <>
            <h1><Skeleton/></h1>
            <h1><Skeleton/></h1>
            <h1><Skeleton/></h1>
            <h1><Skeleton/></h1>
            </> : 
            <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  {/* <th>अ.क्र</th> */}
                  <th>तालुका</th>
                  <th>प्रमाण</th>
                </tr>
              </thead>
              {talukas.map((taluka, index) => {
                return(
                  <tbody key={index}>
                    <tr>
                      {/* <td>
                        {index+1}
                      </td> */}
                      <td>
                        {!!(taluka?.name) ? taluka?.name : "-"}
                      </td>
                      <td>
                      {!!(taluka?.count) ? taluka?.count : "-"}<span className="pl-1">Kg</span>
                      </td>
                    </tr>
                  </tbody>
                )
              })}
            </table>
          </div>
          }
          
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities
