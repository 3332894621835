import React, { useEffect, useState } from "react"
// import Select from 'react-select-plus';
// import 'react-select-plus/dist/react-select-plus.css';
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  getHeader,
  getRequest,
  postRequest,
  responsecode200,
} from "common/Constants"
import { cloneDeep } from "lodash"

const AddFarmer = () => {
  const navigate = useNavigate()
  //meta title

  const reactAppUrl = process.env.REACT_APP_ALLURL

  const [validToken, setValidToken] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // to get the taluka list start
  const [taluka, setTaluka] = useState([])
  const getTalukaList = async () => {
    try {
      const responseData = await getRequest(`talukaDropdown`)
      if (responseData && responseData.status == 200) {
        setTaluka(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the taluka list end
  // to get the district list start
  const [district, setDistrict] = useState([])
  const getDistrictList = async () => {
    try {
      const responseData = await getRequest(`districtDropdown`)
      if (responseData && responseData.status == 200) {
        setDistrict(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the district list end

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: "",
      Address: "",
      Village: "",
      District: "",
      Taluka: "",
      Telephone: "",
      papaya: "",
    },
    validationSchema: Yup.object({
      Name: Yup.string()
        .min(2, "कमीत कमी 2 अक्षर टाका ")
        .required("कृपया आपले नाव टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
      Address: Yup.string()
        .min(3, "कमीत कमी ३ अक्षर टाका ")
        .required("कृपया तुमचा पत्ता टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
      Village: Yup.string()
        .min(3, "कमीत कमी ३ अक्षर टाका ")
        .required("कृपया  गावाचे नाव टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "कृपया गावाचे नाव टाका",
          }
        ),
      District: Yup.string().required("कृपया जिल्ह्याचे नाव टाका"),
      papaya: Yup.string().required("कृपया पपई लागवड केलेले टाका"),
      Taluka: Yup.string().required("कृपया तालुक्याचे नाव टाका"),
      Telephone: Yup.string()
        .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा.")
        .required("कृपया  फोन नं. टाका"),
    }),
    onSubmit: async values => {

      try {
        let data = {
          name: values.Name,
          address: values.Address,
          village: values.Village,
          taluka_id: values.Taluka,
          district_id: values.District,
          phone_no: values.Telephone,
          is_planted: values.papaya,
        }
        setIsLoading(true)
        const responseData = await postRequest(`createFarmer`, data)
        setIsLoading(false)
        if (responseData && responseData.status == 200) {
          // toast.success(responseData.data.message)
          navigate("/farmer/Listindex")
        }
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    },
  })

  const [variery, setVariety] = useState([])
  const getVariety = async () => {
    try {
      const responseData = await getRequest(`varietyList`)
      if (responseData && responseData.status == 200) {
        setVariety(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVariety()
    getTalukaList()
    getDistrictList()
  }, [])
  // state = {
  //   selectedOption: '',
  // }
  // handleChange = (selectedOption) => {
  //   this.setState({ selectedOption });
  //   console.log(`Selected: ${selectedOption.label}`);
  // }
  // render() {
  // 	const { selectedOption } = this.state;
  // 	const value = selectedOption && selectedOption.value;

  // to check the phone no start
  const [isPhoneChecking, setIsPhonechecking] = useState(false)
  const checkPhoneNo = async () => {
    try {
      setIsPhonechecking(true)
      const response = await getRequest(`checksPhoneNoFarmer?phone_no=${validation.values.Telephone}`)
      setIsPhonechecking(false)
      // toast.error(response?.data?.message)
    } catch (error) {
      setIsPhonechecking(false)
      console.log(error)
    }
  }
  // to check the phone no end

  return (
    <div>
      {/* <div className="row">
        <div className="col" style={{ padding: "0" }}> */}
      {/* <div className="card">
            <div className="card-body">
             */}

      <form
        className="needs-validation mt-4"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div className="row mb-3">
          <label
            htmlFor="example-tel-input"
            className="col-md-2 col-form-label"
          >
            फोन नं*
          </label>
          <div className="col-md-10">
            <input
              id="Telephone"
              name="Telephone"
              className="form-control"
              type="number"
              onChange={validation.handleChange}
              onBlur={e => {
                validation.handleBlur(e)
                checkPhoneNo()
              }}
              value={validation.values.Telephone}
            />
            {validation.touched.Telephone && validation.errors.Telephone ? (
              <span className="error">{validation.errors.Telephone}</span>
            ) : null}
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-md-2 col-form-label">नाव*</label>
          <div className="col-md-10">
            <input
              id="Name"
              name="Name"
              className="form-control"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.Name}
            />
            {validation.touched.Name && validation.errors.Name ? (
              <span className="error">{validation.errors.Name}</span>
            ) : null}
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-md-2 col-form-label">पत्ता*</label>
          <div className="col-md-10">
            <textarea
              id="Address"
              name="Address"
              className="form-control"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.Address}
            />
            {validation.touched.Address && validation.errors.Address ? (
              <span className="error">{validation.errors.Address}</span>
            ) : null}
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6 mb-3">
            <div className="row">
              <label className="col-md-4 col-form-label">गाव*</label>
              <div className="col-md-8">
                <input
                  id="Village"
                  name="Village"
                  className="form-control"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.Village}
                />
                {validation.touched.Village && validation.errors.Village ? (
                  <span className="error">{validation.errors.Village}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="row">
              <label className="col-md-4 col-12 col-form-label">तालुका*</label>
              <div className="col-md-6 col-10">
                <select
                  id="Taluka"
                  name="Taluka"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.Taluka !== "select"
                      ? validation.values.Taluka
                      : ""
                  }
                >
                  <option value="">select</option>
                  {taluka &&
                    taluka.map((farmers, index) => {
                      return (
                        <option value={farmers.taluka_id} key={index}>
                          {farmers.name}
                        </option>
                      )
                    })}
                </select>
                {validation.touched.Taluka && validation.errors.Taluka ? (
                  <span className="error">{validation.errors.Taluka}</span>
                ) : null}
              </div>
              <div className="col-md-2 col-2 btn-col">
                <Link to="/Information" className="btn btn-primary">
                  ऍड
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <div className="row">
              <label className="col-md-4  col-12 col-form-label">जिल्हा*</label>
              <div className="col-md-6 col-10">
                <select
                  id="District"
                  name="District"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.District}
                >
                  <option value="">select</option>
                  {district &&
                    district.map((farmers, index) => {
                      return (
                        <option value={farmers.district_id} key={index}>
                          {farmers.name}
                        </option>
                      )
                    })}
                </select>
                {validation.touched.District && validation.errors.District ? (
                  <span className="error">{validation.errors.District}</span>
                ) : null}
              </div>
              <div className="col-md-2 col-2 btn-col">
                <Link to="/Information" className="btn btn-primary">
                  ऍड
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row mb-3">
              <label className="col-md-4 col-form-label">
                पपई लागवड केलेले*
              </label>
              <div className="col-md-8">
                <select
                  id="formrow-InputState variety"
                  name="papaya"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.papaya}
                >
                  <option value="">select</option>
                  <option value="1">हो </option>
                  <option value="0">नाही </option>
                </select>
                {validation.touched.papaya && validation.errors.papaya ? (
                  <span className="error">{validation.errors.papaya}</span>
                ) : null}
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="row mb-3" style={{ justifyContent: "flex-end" }}>
          <div className="col-md-2" style={{ textAlign: "right" }}>
            <Button color="primary" type="submit" disabled={isLoading || isPhoneChecking}>
              {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
            </Button>
          </div>
        </div>
      </form>
      {/* </div>
          </div> */}
      {/* </div>
      </div> */}
    </div>
  )
}

export default AddFarmer
