import React, { useEffect, useState } from "react"
import "../assets/css/style.css"

import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Modal from "react-bootstrap/Modal"
import { Button, Label } from "reactstrap"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { getRequest } from "common/Constants"
import { Pagination } from "@mui/material"

const Subadmin = () => {
  // Hooks start
  const navigate = useNavigate()
  // Hooks end

  const [isLoading, setIsLoading] = useState(false)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  //   to set page start
  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }
  //   to set page end

  //   get the subadmin list start
  const [subAdmin, setSubAdmin] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [perPageItem, setPerPageItem] = useState(0)
  const getSubAdminList = async () => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(`subAdminList?page=${page}`)
      setIsLoading(false)

      if (responseData.status == 200) {
        setSubAdmin(responseData.data.data)
        let totalPages = responseData.data.total / responseData.data.perPage
        setTotalCount(Math.ceil(totalPages))
        setPerPageItem(responseData.data.perPage)
      } else if (responseData.status == 409) {
        navigate("/addsubadmin")
      }
    } catch (error) {}
  }

  //   get the subadmin list end

  //   to go to the view page with the id start
  // const handleView = id => {
  //   try {
  //     navigate("/editsubadmin", { state: { id: id } })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  //   to go to the view page with the id end

  // To Edit the merchant detail start

  const handleEdit = id => {
    try {

      // navigate({pathname:"/EditBusiness",state:id})
      navigate("/editsubadmin", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the merchant detail end

  // react bootstrap modal start
  const [deleteId, setDeleteId] = useState("")

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  // react bootstrap modal end

  //   to go to the view page with the id start
  const handleView = id => {
    try {
      navigate("/viewsubadmin", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }
  //   to go to the view page with the id end

  // To delete the merchant detail start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {

      const responseData = await getRequest(
        `subAdminDelete?sub_admin_id=${deleteId}`
      )
      setShow(false)
      setRender(!render)
      navigate("/Profile")
    } catch (error) {
      console.log(error)
    }
  }

  // To delete the merchant detail end

  useEffect(() => {
    getSubAdminList()
  }, [page, render])

  return (
    <div>
      {/* <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body"> */}
              <div className="row" style={{ justifyContent: "flex-end" }}>
                <div className="col-md-2 mb-3" style={{ textAlign: "right" }}>
                  <Link className="btn btn-primary" to="/addsubadmin">
                    <i className="bx bx-plus align-middle"></i>नवीन सब ऍडमिन{" "}
                  </Link>
                </div>
              </div>

              {isLoading ? (
                <>
                  <h1>
                    <Skeleton />
                  </h1>
                  <h1>
                    <Skeleton />
                  </h1>
                  <h1>
                    <Skeleton />
                  </h1>
                  <h1>
                    <Skeleton />
                  </h1>
                  <h1>
                    <Skeleton />
                  </h1>
                </>
              ) : subAdmin.length > 0 ? (
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>अ.क्र.</th>
                          <th>नाव</th>

                          <th>पत्ता</th>

                          <th>फोन नं</th>
                          <th>ई-मेल </th>
                          <th></th>
                        </tr>
                      </thead>

                      {subAdmin.map((subadmin, index) => {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>
                                {page * perPageItem - perPageItem + (index + 1)}
                              </td>
                              <td>{subadmin.name}</td>
                              <td>{subadmin.address}</td>
                              <td>{subadmin.phone_no}</td>
                              <td>{subadmin.email}</td>
                              <td>
                                <a
                                  className="btn-sm btn-view btn"
                                  onClick={e =>
                                    handleView(subadmin.sub_admin_id)
                                  }
                                >
                                  <div className="tooltip1">
                                    <i className="bx bx-file align-middle">
                                      <span className="tooltiptext">पहा</span>
                                    </i>
                                  </div>
                                </a>

                                <a
                                  className="btn-sm btn-edit  btn"
                                  onClick={e =>
                                    handleEdit(subadmin.sub_admin_id)
                                  }
                                >
                                  <div className="tooltip1">
                                    <i className="bx bx-edit align-middle">
                                      <span className="tooltiptext">
                                        संपादित करा
                                      </span>
                                    </i>
                                  </div>
                                </a>

                                <a
                                  className="btn-sm btn-delete btn"
                                  onClick={() =>
                                    handleShow(subadmin.sub_admin_id)
                                  }
                                >
                                  <div className="tooltip1">
                                    <i className="bx bxs-trash align-middle">
                                      <span className="tooltiptext">हटवा</span>
                                    </i>
                                  </div>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        )
                      })}
                    </table>
                  </div>
                  <ul className="pagination modal-1" style={{ float: "right" }}>
                    <Pagination
                      count={totalCount}
                      page={page}
                      onChange={handleChange}
                      color="secondary"
                      shape="rounded"
                    />
                  </ul>
                </div>
              ) : "No data available"}
            {/* </div>
          </div>
        </div>
      </div> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>सब ऍडमिन हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDelete} className="btn btn-success">
            होय
          </Button>
          <Button variant="secondary" onClick={handleClose} className="btn btn-danger">
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Subadmin
