import React, { useState } from "react";
import UserProfile from "./user-profile";
import Subadmin from "pages/subadmin";
import Variety from "pages/Variety/variety";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledCollapse
} from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";
import { getRole } from "common/Constants";
function UiTabsAccordions(props) {

  //meta title
  document.title = "Tabs & Accordions | Skote - React Admin & Dashboard Template";

  const [activeTab, setactiveTab] = useState("1");
  const [activeTab1, setactiveTab1] = useState("5");
  const [activeTab2, setactiveTab2] = useState("1");
  const [activeTab3, setactiveTab3] = useState("1");
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1");
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1");
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const [col5, setcol5] = useState(true);
  const [col6, setcol6] = useState(true);
  const [col7, setcol7] = useState(true);

  const [col8, setcol8] = useState(true);
  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  const t_col5 = () => {
    setcol5(!col5);
  };

  const t_col6 = () => {
    setcol6(!col6);
  };

  const t_col7 = () => {
    setcol7(!col7);
  };

  const t_col8 = () => {
    setcol6(!col6);
    setcol7(!col7);
  };

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const toggle2 = tab => {
    if (activeTab2 !== tab) {
      setactiveTab2(tab);
    }
  };

  const toggle3 = tab => {
    if (activeTab3 !== tab) {
      setactiveTab3(tab);
    }
  };

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  const toggleVerticalIcon = tab => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab);
    }
  };

  const Role = getRole()


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="प्रोफाईल " />

          <Row>
       

            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Justify Tabs</CardTitle> */}
                 

                  <Nav pills className="navtab-bg navtab-profile nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "Profile" }}
                        className={classnames({
                          active: activeTab1 === "5",
                        })}
                        onClick={() => {
                          toggle1("5");
                        }}
                      >
                        प्रोफाईल 
                      </NavLink>
                    </NavItem>
                    {Role == 1 && <NavItem>
                      <NavLink
                        style={{ cursor: "Subadmin" }}
                        className={classnames({
                          active: activeTab1 === "6",
                        })}
                        onClick={() => {
                          toggle1("6");
                        }}
                      >
                       सब ऍडमीन 
                      </NavLink>
                    </NavItem>}
                
              
                  </Nav>

                  <TabContent activeTab={activeTab1} className="py-4 text-muted">
                    <TabPane tabId="5">
                      {/* <Row>
                        <Col sm="12"> */}
                         <UserProfile></UserProfile>
                        {/* </Col>
                      </Row> */}
                    </TabPane>
                    <TabPane tabId="6">
                      {/* <Row>
                        <Col sm="12"> */}
                         <Subadmin></Subadmin>
                        {/* </Col>
                      </Row> */}
                    </TabPane>
                    

                  
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

        
        </Container>
      </div>
    </React.Fragment>
  );
}

export default UiTabsAccordions;
