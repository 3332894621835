import React, { useEffect, useState } from "react"

import "../../assets/css/style.css"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest } from "common/Constants"
import { Pagination } from "@mui/material"

const Variety = () => {
  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  //   hooks start
  const navigate = useNavigate()
  //   hooks end

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  // to get the variety list start
  const [varietyList, setVarietyList] = useState([])
  const [perPageItem, setPerPageItem] = useState(0)

  const getVarietyList = async () => {
    const responseData = await getRequest(`varietyList?page=${page}`)
    if (responseData && responseData.status == 200) {
      setVarietyList(responseData.data.data)
      let totalPages = responseData.data.total / responseData.data.perPage
      setTotalCount(Math.ceil(totalPages))
      setPerPageItem(responseData.data.perPage)
    }
  }
  // to get the variety list end

  // To Edit the merchant detail start

  const handleEdit = id => {
    try {
      navigate("/editvariety", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the merchant detail end

  // To delete the merchant detail start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {

      // const responseData = await getRequest(`sellInvoiceDelete?sell_invoice_id=${id}`)

      setRender(!render)
    } catch (error) {
      console.log(error)
    }
  }

  // To delete the merchant detail end

  useEffect(() => {
    getVarietyList()
  }, [page, render])

  return (
    <div>
      {/* <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body"> */}
              <div className="row" style={{ justifyContent: "flex-end" }}>
                <div className="col-md-2 mb-3" style={{ textAlign: "right" }}>
                  <Link className="btn btn-primary" to="/addvariety">
                    <i className="bx bx-plus align-middle"></i>नवीन व्हरायटी{" "}
                  </Link>
                </div>
              </div>

              {varietyList.length > 0 ? (
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>अ.क्र.</th>
                          <th>व्हरायटी नाव</th>

                          <th>स्टेटस</th>

                          <th></th>
                        </tr>
                      </thead>

                      {varietyList &&
                        varietyList.map((variety, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  {page * perPageItem -
                                    perPageItem +
                                    (index + 1)}
                                </td>
                                <td>{!!variety?.name ? variety.name : "-"}</td>
                                <td>
                                  <span
                                    className={`font-size-11 badge-soft-${
                                      variety?.is_block === 0
                                        ? "danger"
                                        : "success"
                                    } badge bg-secondary`}
                                  >
                                    {variety?.is_block == 0
                                      ? "नाही"
                                      : variety?.is_block == 1
                                      ? "हो"
                                      : "-"}
                                  </span>
                                </td>
                                <td>
                                  <a
                                    className="btn-sm btn-edit  btn"
                                    onClick={e =>
                                      handleEdit(variety?.variety_id)
                                    }
                                  >
                                    <div className="tooltip1">
                                      <i className="bx bx-edit align-middle">
                                        <span className="tooltiptext">
                                          संपादित करा
                                        </span>
                                      </i>
                                    </div>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          )
                        })}
                    </table>
                  </div>
                  <ul className="pagination modal-1" style={{ float: "right" }}>
                    <Pagination
                      count={totalCount}
                      page={page}
                      onChange={handleChange}
                      color="primary"
                      shape="rounded"
                    />
                  </ul>
                </div>
              ) : (
                "No data available"
              )}
            </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Variety
