import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

import { ToWords } from "to-words"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm } from "react-hook-form"
import { getRequest, postRequest } from "common/Constants";

import Select from "react-select"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

const Editvehicle = () => {
  const [startDate, setStartDate] = useState(new Date("04-03-2020"))
  //meta title
  document.title = "Samarth Fruit Company"

  //   hooks start
  const navigate = useNavigate()

  const location = useLocation()
  //   hooks end

  // to translate numbet to words start
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  })

  // to translate numbet to words end

  const [id, setId] = useState(location.state.id)
  const [isLoading, setIsLoading] = useState(false)

  // file upload start
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const uploadFile = async e => {
    try {
      setSelectedImage(e.target.files[0])
      const formData = new FormData()
      formData.append("file", e.target.files[0])

      const responseData = await postRequest(`uploadSignature`, formData)

      if (responseData && responseData.status == 200) {
        setImageUrl(responseData.data.data.imageUrl)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // file upload end

  // to get the merchant data to fill automatically start
  const [merchant, setMerchant] = useState([])
  const [personName, setPersonName] = useState([])
  const getAllMerchant = async () => {
    const responseData = await getRequest(`allMerchants`)
    setMerchant(responseData.data.data)
    const mappedData = responseData.data.data.map(item => ({
      value: item.merchant_id,
      label: item.name,
    }))
    setPersonName(mappedData)
    setVehicleInvoice()
  }

  const [merchantId, setMerchantId] = useState("")
  const [merchantData, setMerchantData] = useState(null)
  const getMerchantData = async e => {
    setMerchantId(getValues().Name)
    const responseData = await getRequest(
      `merchantDetail?merchant_id=${getValues().Name}`
    )

    setMerchantData(responseData.data.data)
  }
  // to get the merchant data to fill automatically end

  // to get sales invoice data start

  const [vehicleInvoiceData, setVehicleInvoiceData] = useState([])
  const setVehicleInvoice = async () => {
    const responseData = await getRequest(
      `vehicleInvoiceDetail?vehicle_invoice_id=${id}`
    )
    setValue("Name", responseData.data.data.merchant_id)
    setValue(
      "Address",
      merchantData != null
        ? merchantData.address
        : responseData.data.data.address
    )
    setValue("Phone", responseData.data.data.phone_no)
    setValue("date1", responseData.data.data.date)
    // setValue("Receipt", responseData.data.data.receipt_no)
    setValue("VehicleNo", responseData.data.data.vehicle_no)
    setValue("Notes", responseData.data.data.notes)
    setValue("VehicleCost", responseData.data.data.vehicle_charge)
    setValue("OverLoadCost", responseData.data.data.overload_charge)
    setValue("ExtraCost", responseData.data.data.other_charge)
    setValue("Advance", responseData.data.data.advance)
    setValue("FinalCost", responseData.data.data.overall_total)
    setValue("DriverName", responseData.data.data.driver_name)
    setValue("DriverContact", responseData.data.data.driver_mobile_no)
    setVehicleInvoiceData(responseData.data.data)
  }
  // to get sales invoice data end

  // react hook form start
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm()

  const onSubmit = async data => {
    try {
      
    // Handle form submission here

    const data1 = {
      merchant_id: merchantId ? merchantId : vehicleInvoiceData.merchant_id,
      vehicle_invoice_id: id,
      name: data.Name,
      address: data.Address,
      date: data.date1,
      // receipt_no: data.Receipt,
      vehicle_no: data.VehicleNo,
      notes: data.Notes,
      vehicle_charge: Number(data.VehicleCost),
      overload_charge: Number(data.OverLoadCost),
      other_charge: Number(data.ExtraCost),
      advance: Number(data.Advance),
      overall_total: finalCost,
      driver_name: data.DriverName,
      driver_mobile_no: data.DriverContact,
      // signature: imageUrl ? imageUrl : vehicleInvoiceData.signature,
    }

    setIsLoading(true)
    const responseData = await postRequest("vehicleInvoiceUpdate", data1)
    setIsLoading(false)
    if (responseData.status == 200) {
      navigate("/VehicleInvoice")
    } else if (responseData.status == 409) {
      navigate("/Editvehicle")
    }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  // react hook form end

  //   total calculation start
  const [finalCost, setFinalCost] = useState(0)

  const calculateValue = () => {
    let cost1 = getValues().VehicleCost
    let cost2 = getValues().OverLoadCost ? getValues().OverLoadCost : 0
    let cost3 = getValues().ExtraCost ? getValues().ExtraCost : 0
    let cost4 = getValues().Advance ? getValues().Advance : 0
    let cost = Number(cost1) + Number(cost2) + Number(cost3) - Number(cost4)
    setFinalCost(cost)
  }
  //   total calculation end

  let words = toWords.convert(
    vehicleInvoiceData && finalCost
      ? finalCost
      : vehicleInvoiceData.overall_total
      ? vehicleInvoiceData.overall_total
      : "0"
  )

  useEffect(() => {
    getAllMerchant()
    calculateValue()
  }, [])

  useEffect(() => {
    // While Editvehicle page is active, the invoice tab must also activated
    let element = document.getElementById("invoice")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="एडिट वाहतुक इनव्हॉइस" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-3 border-1">
                      <div className="col-xl-7 col-md-7 col-sm-12 col-12">

                        <div className="form-group mb-3">
                          <label className="col-form-label">नाव</label>
                          {/* <select
                            id="formrow-InputState variety"
                            name="Name"
                            className="form-select"
                            {...register("Name")}
                            onClick={getMerchantData}
                          >
                            <option value="">Select</option>

                            {merchant &&
                              merchant.map((merchants, index) => {
                                return (
                                  <option
                                    value={merchants.merchant_id}
                                    key={index}
                                  >
                                    {merchants.name}
                                  </option>
                                )
                              })}
                          </select> */}

<Select
                            styles={customStyles}
                            id="formrow-InputState variety"
                            name="Name"
                            className="basic-single"
                            classNamePrefix="select"
                            options={personName}
                            {...register("Name", {
                              required: "कृपया आपले नाव टाका",
                            })}
                            onChange={selectedOption => {
                              setValue(
                                "Name",
                                selectedOption ? selectedOption.value : ""
                              )
                              getMerchantData()
                            }}
                            value={personName.find(
                              option => option.value === getValues().Name
                            )}
                          />

                          {errors.name && (
                            <span style={{ color: "red" }}>
                              {errors.name.message}
                            </span>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="col-form-label">पत्ता*</label>

                          <textarea
                            className="form-control"
                            type="text"
                            name="Address"
                            {...register("Address")}
                            value={merchantData?.address}
                            disabled
                          ></textarea>
                          {errors.Address && (
                            <span style={{ color: "red" }}>
                              {errors.Address.message}
                            </span>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <label className="col-form-label">फोन नं</label>

                          <input
                            className="form-control"
                            type="text"
                            name="Phone"
                            {...register("Phone")}
                            disabled
                            value={merchantData?.phone_no}
                          />
                        </div>
                      </div>
                      <div className="col-xl-5 col-md-5 col-sm-12 col-12">
                        <div className="form-group mb-3">
                          <label>दिनांक*</label>
                          {/* <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            className="form-control"
                            dateFormat="d-MM-yyyy"
                            placeholderText="Select date"
                          /> */}
                          <input
                            className="form-control"
                            type="date"
                            name="date1"
                            id="example-month-input month"
                            {...register("date1", {
                              required: "कृपया तारीख टाका",
                            })}
                          />
                          {errors.date1 && (
                            <span style={{ color: "red" }}>
                              {errors.date1.message}
                            </span>
                          )}
                        </div>
                        {/* <div className="form-group mb-3">
                          <label>पावती क्र.*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Receipt"
                            id="example-month-input month"
                            {...register("Receipt", {
                              required: "कृपया पावती क्र टाका",
                            })}
                          />
                          {errors.Receipt && (
                            <span style={{ color: "red" }}>
                              {errors.Receipt.message}
                            </span>
                          )}
                        </div> */}
                        <div className="form-group">
                          <label>गाडी नं.*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="VehicleNo"
                            {...register("VehicleNo", {
                              required: "कृपया गाडी नं. टाका",
                            })}
                          />
                          {errors.VehicleNo && (
                            <span style={{ color: "red" }}>
                              {errors.VehicleNo.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>नोट्स जोडा</label>
                          <textarea
                            type="text"
                            className="form-control"
                            name="Notes"
                            {...register("Notes")}
                          ></textarea>
                          {/* {errors.Notes && (
                            <span style={{ color: "red" }}>
                              {errors.Notes.message}
                            </span>
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="invoice-total-card">
                          <h4 className="invoice-total-title">सारांश</h4>
                          <div className="invoice-total-box">
                            <div className="invoice-total-inner">
                              <p>
                                गाडी भाडे*{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>
                                    
                                      <input
                                        type="number"
                                        step="0.01"
                                        placeholder="0.00"
                                        className="form-control"
                                        name="VehicleCost"
                                        {...register("VehicleCost", {
                                          required: "कृपया गाडी भाडे टाका",
                                        })}
                                        onKeyUp={calculateValue}
                                      />
                                      {errors.VehicleCost && (
                                        <span style={{ color: "red" }}>
                                          {errors.VehicleCost.message}
                                        </span>
                                      )}
                                   
                                  </div>
                                </span>
                              </p>
                              <p>
                                ओव्हरलोड{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>
                                   
                                      <input
                                        type="number"
                                        step="0.01"
                                        placeholder="0.00"
                                        className="form-control"
                                        name="OverLoadCost"
                                        {...register("OverLoadCost")}
                                        onKeyUp={calculateValue}
                                      />
                                      {/* {errors.OverLoadCost && (
                                      <span style={{ color: "red" }}>
                                        {errors.OverLoadCost.message}
                                      </span>
                                    )} */}
                                    
                                  </div>
                                </span>
                              </p>
                              <p>
                                इतर खर्च{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>
                                   
                                      <input
                                        type="number"
                                        step="0.01"
                                        placeholder="0.00"
                                        className="form-control"
                                        name="ExtraCost"
                                        {...register("ExtraCost")}
                                        onKeyUp={calculateValue}
                                      />
                                      {/* {errors.ExtraCost && (
                                      <span style={{ color: "red" }}>
                                        {errors.ExtraCost.message}
                                      </span>
                                    )} */}
                                  
                                  </div>
                                </span>
                              </p>
                              <p>
                                ऍडवान्स{" "}
                                <span>
                                  <div className="d-flex">
                                    <span className="p-2">₹</span>
                                  
                                      <input
                                        type="number"
                                        step="0.01"
                                        placeholder="0.00"
                                        className="form-control"
                                        name="Advance"
                                        {...register("Advance")}
                                        onKeyUp={calculateValue}
                                      />
                                      {/* {errors.Advance && (
                                      <span style={{ color: "red" }}>
                                        {errors.Advance.message}
                                      </span>
                                    )} */}
                                    
                                  </div>
                                </span>
                              </p>

                              <div className="links-info-one">
                                <div className="links-info">
                                  <div className="links-cont">
                                    <a href="#" className="service-trash"></a>
                                  </div>
                                </div>

                                <div className="invoice-total-footer">
                                  <h4>
                                    एकूण{" "}
                                    <span>
                                      <div className="d-flex">
                                        <span className="p-2">₹</span>
                                        <input
                                          type="number"
                                          step="0.01"
                                          placeholder="0.00"
                                          className="form-control"
                                          disabled
                                          name="FinalCost"
                                          value={
                                            finalCost
                                              ? finalCost
                                              : vehicleInvoiceData.overall_total
                                          }
                                        />
                                      </div>
                                    </span>
                                  </h4>
                                </div>
                                <div className="invoice-total-inner1 mt-1">
                                  <p>
                                    अक्षरी{" "}
                                    <span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value={words}
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          className="mt-3 mb-3"
                          style={{ textAlign: "right" }}
                        >
                          {selectedImage ? (
                            <div
                              className="selectedimg"
                              style={{
                                width: "250px",
                                display: "flex",
                                marginLeft: "250px",
                                height: "40px",
                                marginBottom: "10px",
                                position: "relative",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                alt="not found"
                                width={"250px"}
                                height={"40px"}
                                src={URL.createObjectURL(selectedImage)}
                              />

                              <button
                                className="img-remove"
                                onClick={() => setSelectedImage(null)}
                              >
                                <i className="mdi mdi-close-thick"></i>
                              </button>
                            </div>
                          ) : (
                            <img
                              src={vehicleInvoiceData.signature}
                              style={{ width: "200px", height: "40px" }}
                            />
                          )}
                          {selectedImage ? null : (
                            <div className="form-group mt-3 mb-3">
                              <input
                                type="file"
                                multiple=""
                                onChange={uploadFile}
                                style={{ width: "200px" }}
                              />
                            </div>
                          )}
                        </div> */}

                        <div
                          className="mt-3 mb-3"
                          style={{ textAlign: "right" }}
                        >
                          <div
                            className="selectedimg"
                            style={{
                              height: "40px",
                              marginBottom: "10px",
                              position: "relative",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              style={{ border: "0.5px solid black" }}
                              alt="not found"
                              width={"250px"}
                              height={"50px"}
                              src={
                                "https://samarthfruitcompany.in/api/public/signature/signature.jpeg"
                              }
                            />
                          </div>
                        </div>

                        {/* <div className="form-group service-upload mt-3 mb-3">
                          <span>अपलोड स्वाक्षरी</span>
                          <input type="file" multiple="" />
                        </div> */}
                        {/* <div className="form-group float-end mb-0">
                          <button className="btn btn-primary" type="submit">
                            जतन करा
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          <label>नियम व अटी :</label>
                          <p className="mb-0 para2">
                            गाडी मध्ये मोजून आणि भरून दिलेला माल चांगल्या
                            अवस्थेमध्ये ठरलेल्या ठिकाणापर्यंत आणि ठरलेल्या वेळेत
                            पोहोचवण्याची जिम्मेदारी गाडी मालक आणि चालकाची असेल.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-3  mb-3">
                        <div className="form-group">
                          <label>गाडी चालक नाव* </label>
                          <input
                            type="text"
                            className="form-control"
                            name="DriverName"
                            {...register("DriverName", {
                              required: "कृपया गाडी चालकाचे नाव टाका",
                            })}
                          />
                          {errors.DriverName && (
                            <span style={{ color: "red" }}>
                              {errors.DriverName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3   mb-3">
                        <div className="form-group">
                          <label>मो.नं.*</label>
                          <input
                            type="number"
                            className="form-control"
                            name="DriverContact"
                            {...register("DriverContact", {
                              required: "कृपया गाडी चालकाचा मोबाईल  नं टाका",
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "कृपया मान्य 10 अंकाचा मोबाइल नंबर प्रविष्ट करा",
                              },
                            })}
                          />
                          {errors.DriverContact && (
                            <span style={{ color: "red" }}>
                              {errors.DriverContact.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="row mb-3 mt-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        className="col-md-2 col-5"
                        style={{ textAlign: "left" }}
                      >
                        <Link to="/VehicleInvoice" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div
                        className="col-md-2 col-7"
                        style={{ textAlign: "right" }}
                      >
                        <button className="btn btn-primary" type="submit" disabled={isLoading}>
                        {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editvehicle
