import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useLocation } from "react-router-dom"
import axios from "axios"
import { getRequest } from "common/Constants"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import moment from "moment"
// import { useFormik } from "formik";
// import * as Yup from "yup";
const Viewfarmerorder = () => {
  // hooks start
  const navigate = useNavigate()
  const location = useLocation()
  // hooks end

  const [isLoading, setIsLoading] = useState(false)

  const [id, setId] = useState(location.state.id)

  // to get the data for specific id start
  const [farmerorder, setFarmerOrder] = useState([])
  const getFarmerIdData = async () => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `farmerOrderDetail?farmer_order_id=${id}`
      )
      setIsLoading(false)

      if (responseData && responseData.status == 200) {
        setFarmerOrder(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the data for specific id end

  //meta title
  document.title = "Samarth Fruit Company"

  useEffect(() => {
    getFarmerIdData()
  }, [])

  useEffect(() => {
    // While view farmerorder page is active, the farmer order tab must also activated
    let element = document.getElementById("farmerorderList")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="शेतकरी ऑर्डर" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <div className="cardTitle h4 d-none">Textual inputs</div>
                  <p className="card-title-desc d-none">
                    Here are examples of <code>.form-control</code> applied to
                    each textual HTML5 <code>&lt;input&gt;</code>{" "}
                    <code>type</code>.
                  </p>
                  <form>
                    {isLoading ? (
                      <>
                        <h1>
                          <Skeleton />
                        </h1>
                        <h1>
                          <Skeleton />
                        </h1>
                        <h1>
                          <Skeleton />
                        </h1>
                        <h1>
                          <Skeleton />
                        </h1>
                        <h1>
                          <Skeleton />
                        </h1>
                        <h1>
                          <Skeleton />
                        </h1>
                      </>
                    ) : (
                      <>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            शेतकरी चे नाव
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {farmerorder ? farmerorder.farmer : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            व्हरायटी
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {farmerorder ? farmerorder.variety : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            लागवड क्षेत्र (एकर मध्ये )
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {farmerorder ? farmerorder.planted_area : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            झाडांची संख्या
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {farmerorder ? farmerorder.tree_count : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            अंदाजे वजन
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {farmerorder.weight} <span >kg</span>
                            </p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            लागवडीची तारीख
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {/* {farmerorder ? farmerorder.plantation_date : "-"} */}
                              {farmerorder ? moment(farmerorder.plantation_date).format("DD/MM/YYYY") : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            काढणी तारीख
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {/* {farmerorder ? farmerorder.cutting_date : "-"} */}
                              {farmerorder ? moment(farmerorder.cutting_date).format("DD/MM/YYYY") : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            तोडणी वारंवारिता
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {farmerorder ? farmerorder.cutting_ratio : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            तोडणी महिने
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">
                              {farmerorder ? farmerorder.cutting_month : "-"}
                            </p>
                          </div>
                        </div>
                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2"
                            style={{ textAlign: "left" }}
                          >
                            <Link
                              to="/farmerorderlist"
                              className="btn btn-danger"
                            >
                              मागे जा
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Viewfarmerorder
