import React, { useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "../assets/css/style.css"
import { Link, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"
import { Form } from "react-router-dom"
import { getHeader, postRequest } from "common/Constants"

const Addsubadmin = () => {
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: "",
      Address: "",
      Telephone: "",
      EmailId: "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("कृपया आपले नाव टाका"),
      Address: Yup.string().required("कृपया तुमचा पत्ता टाका"),
      Telephone: Yup.string().min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा").max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा").required("कृपया  फोन नं. टाका"),
      EmailId: Yup.string()
        .email("वैध ईमेल टाका")
        .required("कृपया  ई-मेल टाका"),
      password: Yup.string().min(6, "कृपया सहा किंवा सहापेक्षा जास्त संख्या प्रविष्ट करा").required("कृपया  पासवर्ड टाका"),
      confirmpassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "पासवर्ड जुळले पाहिजेत")
        .required("कृपया  कन्फर्म पासवर्ड टाका"),
    }),
    onSubmit: async values => {
      try {
        const data = {
          name: values.Name,
          email: values.EmailId,
          password: values.password,
          phone_no: values.Telephone,
          address: values.Address,
        }

        const responseData = await postRequest(`subAdminCreate`, data)

        if (responseData.status == 200) {
          navigate("/Profile")
        } else if (responseData.status == 409) {
          navigate("/addsubadmin")
        }
      } catch (error) {
        console.log(error)
      }
    },
  })

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="ऍड " />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">नाव</label>
                      <div className="col-md-10">
                        <input
                          id="Name"
                          name="Name"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Name}
                        />
                        {validation.touched.Name && validation.errors.Name ? (
                          <span className="error">
                            {validation.errors.Name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">पत्ता</label>
                      <div className="col-md-10">
                        <textarea
                          id="Address"
                          name="Address"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Address}
                        />
                        {validation.touched.Address &&
                        validation.errors.Address ? (
                          <span className="error">
                            {validation.errors.Address}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        फोन नं
                      </label>
                      <div className="col-md-10">
                        <input
                          id="Telephone"
                          name="Telephone"
                          className="form-control"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Telephone}
                        />
                        {validation.touched.Telephone &&
                        validation.errors.Telephone ? (
                          <span className="error">
                            {validation.errors.Telephone}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        ई-मेल
                      </label>
                      <div className="col-md-10">
                        <input
                          id="EmailId"
                          name="EmailId"
                          className="form-control"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.EmailId}
                        />
                        {validation.touched.EmailId &&
                        validation.errors.EmailId ? (
                          <span className="error">
                            {validation.errors.EmailId}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        पासवर्ड
                      </label>
                      <div className="col-md-10">
                        <input
                          id="password"
                          name="password"
                          className="form-control"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password}
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <span className="error">
                            {validation.errors.password}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        कन्फर्म पासवर्ड
                      </label>
                      <div className="col-md-10">
                        <input
                          id="confirmpassword"
                          name="confirmpassword"
                          className="form-control"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmpassword}
                        />
                        {validation.touched.confirmpassword &&
                        validation.errors.confirmpassword ? (
                          <span className="error">
                            {validation.errors.confirmpassword}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="row mb-3"><label className="form-check-label col-md-2">
                        Isblocked
                      </label>
                      <div className="col-md-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="CustomCheck1"
                        />
                      </div>
                    </div> */}
                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-2 col-5" style={{ textAlign: "left" }}>
                        <Link to="/Profile" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div className="col-md-2 col-7" style={{ textAlign: "right" }}>
                        <Button color="primary" type="submit">
                          जतन करा
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addsubadmin
