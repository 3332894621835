import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function ConfirmationDialog({open,onclose,onconfirm,data}) {


  return (
    <>
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onclose(false)}
        aria-describedby="alert-dialog-slide-description"
    >
        
        <DialogContent className='p-3'  >
        <div className='d-flex'style={{justifyContent:"space-between"}} >
        <h5>Confirmation</h5>
        <span  className='text-right' onClick={()=>onclose(false)}><i className="fa-regular fa-circle-xmark"></i></span>
        </div>
       <h6 className='text-center' > Are you Sure.</h6>
            
            <div className='d-flex' >
            <Button onClick={() => onclose(false)} className='text-danger ' >NO</Button>
            <Button onClick={() => onconfirm(true,data)} className='text-success ' >Yes</Button>
            </div>
        </DialogContent>
        
    </Dialog>
</>
  )
}

export default ConfirmationDialog
