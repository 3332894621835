import React, { useEffect, useState } from "react"
import "../../assets/css/style.css"
import { Button, Label } from "reactstrap"
import Modal from "react-bootstrap/Modal"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Select from "react-select"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { Pagination } from "@mui/material"
import moment from "moment"
import { getRequest } from "common/Constants"
import { CleaningServices } from "@mui/icons-material"
import axios from "axios"

const Merchantpayment = () => {
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [name, setName] = useState("")
  const [category, setCategory] = useState("")

  const navigate = useNavigate()

  document.title = "Samarth Fruit Company"

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  const [otherPay, setOtherPay] = useState([])
  const getOtherPay = async () => {
    try {
      setIsLoading(true)
      const otherResponse = await getRequest(
        `otherPayList?page=${page}&otherName=${name}&catName=${category}&start_date=${startDate}&end_date=${endDate}`
      ) //api
      setIsLoading(false)
      let totalPages = otherResponse.data.total / otherResponse.data.perPage
      setTotalCount(Math.ceil(totalPages))
      setPerPageItem(otherResponse?.data?.perPage)
      setOtherPay(otherResponse?.data?.data)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  const [deleteId, setDeleteId] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  const [perPageItem, setPerPageItem] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `otherPayDelete?otherPayId=${deleteId}` //api
      )
      setIsLoading(false)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getOtherPay()
  }, [page, render])

  //   to go to the view page with the id start
  const handleView = id => {
    try {
      navigate("/viewbusinesspayment", { state: { id: id } }) // chnge page
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = id => {
    try {
      // navigate({pathname:"/EditBusiness",state:id})
      navigate(`/editbusinesspayment/${id}`)
    } catch (error) {
      console.log(error)
    }
  }

  // to get category list start

  const reactAppUrl = process.env.REACT_APP_ALLURL

  const [otherc, setOtherc] = useState([])
  const [catName, setCatName] = useState([])
  const getAllOthersc = async () => {
    const othercResponse = await axios.get(`${reactAppUrl}/categoryList`) //api dropdown category
    setOtherc(othercResponse.data.data)
    const mappedData = othercResponse.data.data.map(item => ({
      value: item.categoryId,
      label: item.catName,
    }))
    setCatName(mappedData)
  }
  useEffect(() => {
    getAllOthersc()
  }, [])
  // to get category list end

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="इतर पेमेंट" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="row mb-3 mt-3"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div
                          className="col-md-2 "
                          style={{ textAlign: "right" }}
                        >
                          <a
                            className="btn btn-primary"
                            href="/addbusinesspayment"
                          >
                            <i className="bx bx-plus align-middle"></i> नवीन इतर
                          </a>
                        </div>
                      </div>

                      {/* <div className="col-md-2 mb-3" style={{ textAlign: "right" }}>
                      <Link to="/Addfarmer" type="button" class="btn-add btn-sm  btn btn-primary"></Link>
                    </div> */}

                      <form>
                        <div className="row mb-3 mt-3">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-sm-3 col-xl-3">
                                <div className="form-group mb-3">
                                  <label className="form-label">नाव</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="नाव"
                                    onChange={e => {
                                      setName(e.target.value)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">प्रवर्ग </Label>
                                  {/* <select
                                    id="category"
                                    name="category"
                                    className="form-select"
                                    onChange={e => {
                                      setCategory(
                                        e.target.value == ""
                                          ? ""
                                          : e.target.value == "1"
                                          ? 1
                                          : e.target.value == "2"
                                          ? 2
                                          : e.target.value == "3"
                                          ? 3
                                          : 4
                                      )
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="1">शेतकरी </option>
                                    <option value="2">व्यापारी</option>
                                    <option value="3">व्यवसाय</option>
                                    <option value="4">इतर</option>
                                  </select> */}

                                  <Select
                                    styles={customStyles}
                                    id="formrow-InputState variety"
                                    name="Name"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={catName}
                                    onChange={selectedOption => {
                                      setCategory(
                                        selectedOption
                                          ? selectedOption.value
                                          : ""
                                      )
                                    }}
                                    value={catName.find(
                                      option => option.value === category
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    या तारखे पासून
                                  </Label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e => setStartDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    या तारखे पर्यंत
                                  </Label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e => setEndDate(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-2 col-xl-2  mt-0 filter-button">
                            <div>
                              <Button
                                type="button"
                                color="primary"
                                className="w-md"
                                onClick={getOtherPay}
                              >
                                फिल्टर
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>

                      {isLoading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                        </>
                      ) : (
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th>अ.क्र.</th>
                                  <th>नाव</th>
                                  <th style={{ width: "10%" }}>प्रवर्ग</th>
                                  <th style={{ width: "10%" }}>तारीख</th>
                                  <th style={{ width: "10%" }}>
                                    डेबिट/क्रेडिट
                                  </th>
                                  <th style={{ width: "10%" }}>
                                    पेमेंट पद्धत(कॅश/बँकिंग)
                                  </th>
                                  <th style={{ width: "10%" }}>रक्कम </th>
                                  <th style={{ width: "20" }}>हेतू </th>
                                </tr>
                              </thead>
                              {otherPay.length > 0 ? (
                                otherPay.map((item, index) => {
                                  return (
                                    <tbody key={index}>
                                      <tr>
                                        <td>
                                          {page * perPageItem -
                                            perPageItem +
                                            (index + 1)}
                                        </td>
                                        <td>{item?.otherName}</td>
                                        <td style={{ width: "10%" }}>
                                          {item?.catName}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          {new Date(
                                            item?.date
                                          ).toLocaleDateString("en-GB")}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          {item?.payment_mode == 1
                                            ? "डेबिट"
                                            : "क्रेडिट"}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          {item?.payment_type == 1
                                            ? "कॅश"
                                            : "बँकिंग"}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          {item?.amount}
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {item?.reason}
                                        </td>

                                        <td>
                                          <a
                                            className="btn-sm btn-view  btn"
                                            onClick={e =>
                                              handleView(item?.otherPayId)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bx-file align-middle ">
                                                <span className="tooltiptext">
                                                  पहा
                                                </span>
                                              </i>
                                            </div>
                                          </a>

                                          <a
                                            className="btn-sm btn-edit  btn"
                                            onClick={e =>
                                              handleEdit(item?.otherPayId)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bx-edit align-middle">
                                                <span className="tooltiptext">
                                                  संपादित करा
                                                </span>
                                              </i>
                                            </div>
                                          </a>

                                          <a
                                            className="btn-sm btn-delete btn"
                                            onClick={() =>
                                              handleShow(item?.otherPayId)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bxs-trash align-middle">
                                                <span className="tooltiptext">
                                                  हटवा
                                                </span>
                                              </i>
                                            </div>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan="7">
                                    <div className="nodata">
                                      <b>Data Not Available</b>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </table>
                          </div>
                          <ul
                            className="pagination modal-1"
                            style={{ float: "right" }}
                          >
                            <Pagination
                              count={totalCount}
                              page={page}
                              onChange={handleChange}
                              color="primary"
                              shape="rounded"
                            />
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>इतर पेमेंट</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Merchantpayment
