import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Footer from "components/VerticalLayout/Footer"
import Header from "components/VerticalLayout/Header"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form } from "react-router-dom"
import axios from "axios"
// import { useFormik } from "formik";
// import * as Yup from "yup";
const ViewFarmer = () => {
  const location = useLocation()

  const [id, setId] = useState(location.state.id)

  const reactAppUrl = process.env.REACT_APP_ALLURL

  //meta title
  document.title = "Samarth Fruit Company"

  const [customchkPrimary, setcustomchkPrimary] = useState(true)
  const [customchkSuccess, setcustomchkSuccess] = useState(true)
  const [customchkInfo, setcustomchkInfo] = useState(true)
  const [customchkWarning, setcustomchkWarning] = useState(true)
  const [customchkDanger, setcustomchkDanger] = useState(true)
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true)
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true)
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true)
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  const [merchantData, setMerchantData] = useState([])
  const getMerchantData = async () => {
    const response = await axios.get(
      `${reactAppUrl}/merchantDetail?merchant_id=${id}`
    )
    setMerchantData(response.data.data)
  }

  // get specific farmer details end

  useEffect(() => {
    getMerchantData()
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="व्यापारी" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">नाव</label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{merchantData.name}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            व्यवसायाचे नाव
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{merchantData.business_name}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            पत्ता
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{merchantData.address}</p>
                          </div>
                        </div>

                        <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            फोन नं
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{merchantData.phone_no}</p>
                          </div>
                        </div>
                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2"
                            style={{ textAlign: "left" }}
                          >
                            <Link
                              to="/farmer/Listindex"
                              className="btn btn-danger"
                            >
                              मागे जा
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default ViewFarmer
