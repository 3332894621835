import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Circles } from "react-loader-spinner"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"
import Orderdetails from "./Orderdetails"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getRequest, getRole } from "common/Constants"

const Dashboard = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  // to get the farmer dashboard data start

  const [totalFarmer, setTotalFarmer] = useState(null)
  const [papayaFarmer, setPapayaFarmer] = useState(null)
  const [noPapayaFarmer, setNoPapayaFarmer] = useState(null)
  const getDashboardFarmerData = async () => {
    setIsLoading(true)
    const responseData = await getRequest("dashboardCount")
    setIsLoading(false)

    if (responseData && responseData.status == 200) {
      setTotalFarmer(responseData.data.farmer_count)
      setPapayaFarmer(responseData.data.papaya_planted_farmer)
      setNoPapayaFarmer(responseData.data.without_papaya_planted_farmer)
    }
  }
  // to get the farmer dashboard data end

  const reports = [
    {
      title: "एकूण शेतकरी",
      iconClass: "bx-copy-alt",
      description: totalFarmer == 0 ? 0 :  totalFarmer ? totalFarmer  : "-",
    },
    {
      title: "पपई लागवड केलेले शेतकरी",
      iconClass: "bx-archive-in",
      description: papayaFarmer == 0 ? 0 :  papayaFarmer ? papayaFarmer  : "-",
    },
    {
      title: "पपई लागवड न केलेले  शेतकरी",
      iconClass: "bx-archive-out",
      description: noPapayaFarmer == 0 ? 0 :  noPapayaFarmer ? noPapayaFarmer : "-",
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
    getDashboardFarmerData()
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  // useEffect(() => {
  //   toast.success("Login successful")
  // }, [])

  //meta title
  document.title = "Samarth Fruit Company"

  // define role start
  const Role = getRole()
  // define role end

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="spinner">
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="page-content">
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* Same as */}
          <Container fluid>
            <Breadcrumbs
              title={props.t("डॅशबोर्ड")}
              breadcrumbItem={props.t("डॅशबोर्ड")}
            />
            <Row>
              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {reports.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>

                <Card className="d-none">
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">Email Sent</h4>
                      <div className="ms-auto">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: periodType === "weekly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                onChangeChartPeriod("weekly")
                              }}
                              id="one_month"
                            >
                              Week
                            </Link>{" "}
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: periodType === "monthly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                onChangeChartPeriod("monthly")
                              }}
                              id="one_month"
                            >
                              Month
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: periodType === "yearly" },
                                "nav-link"
                              )}
                              onClick={() => {
                                onChangeChartPeriod("yearly")
                              }}
                              id="one_month"
                            >
                              Year
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="clearfix"></div> */}
                    <StackedColumnChart
                      periodData={periodData}
                      dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <Orderdetails></Orderdetails>
              </Col>
            </Row>
            <Row>
              {Role == 1 ? (
                <Col xl="6">
                  {/* <WelcomeComp /> */}
                  <MonthlyEarning />
                </Col>
              ) : null}
              <Col xl="6">
                <TopCities />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <LatestTranaction />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
