import React, { useEffect, useState } from "react"
import "../../assets/css/style.css"
import { Link, useLocation, useNavigate } from "react-router-dom"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getRequest, postRequest } from "common/Constants"
import { Pagination } from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"

const Editcategory = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  document.title = "Samarth Fruit Company"

  const navigate = useNavigate()

  const location = useLocation()

  const [id, setId] = useState(location?.state?.id)

  // To delete the sistrict start
  const [CategoryData, setCategoryData] = useState([])
  const [render, setRender] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const getCategoryList = async () => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `showCategory?categoryId=${id}`  //api show
      )
      setIsLoading(false)
      setCategoryData(responseData?.data?.data)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Category: isLoading ? "Fetching..." : CategoryData.catName,
    },
    validationSchema: Yup.object({
      Category: Yup.string()
        .min(2, "कमीत कमी 2 अक्षर टाका ")
        .required("कृपया प्रवर्ग टाका")
        .matches(
          /^(?=.*[a-zA-Z\u0900-\u097F])[a-zA-Z0-9\u0900-\u097F\s!@#$%^&*()-=_+[\]{}|;':",.<>/?]+$/,
          {
            message: "केवळ अंक मान्य नाहीत",
          }
        ),
    }),
    onSubmit: async values => {

      try {
        let data = {
          catName: values.Category,
          categoryId: location?.state?.id,
        }
        const responseData = await postRequest(`updateCat`, data)  //edit category

        if (responseData && responseData.status == 200) {
          // toast.success(responseData.data.message)
          var element = document.getElementById("district")
          element.value = ""
          setRender(!render)
          navigate("/Information")
        }
      } catch (error) {
        console.log(error)
      }
    },
  })
  useEffect(() => {
    getCategoryList()
  }, [])


  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="एडिट प्रवर्ग" />
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}>
                    <div className="row">
                      <div className="col-md-5 mb-3">
                        <div className="form-group">
                          <label>प्रवर्ग</label>
                          <input
                            type="text"
                            id="Category"
                            className="form-control"
                            name="Category"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Category}
                          />
                          {validation.touched.Category &&
                            validation.errors.Category ? (
                            <span className="error">
                              {validation.errors.Category}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        className="col-md-2 col-5"
                        style={{ textAlign: "left" }}
                      >
                        <Link to="/Information" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div
                        className="col-md-2 col-7"
                        style={{ textAlign: "right" }}
                      >
                        <button
                          type="submit"
                          color="primary"
                          className="w-md btn btn-primary"
                        >
                          एडिट
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editcategory
