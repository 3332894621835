import React, { useEffect, useState } from "react"
import "../../assets/css/style.css"

import { Link, useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import Modal from "react-bootstrap/Modal"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getHeader,
  getRequest,
  responsecode200,
  responsecode408,
} from "common/Constants"
import axios from "axios"
import { Pagination } from "@mui/material"
import { clearSubmit } from "redux-form"
import moment from "moment"
import { toast } from "react-toastify"

const Salesorder = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const reactAppUrl = process.env.REACT_APP_ALLURL

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  const [perPageItem, setPerPageItem] = useState(0)
  const [salesOrderList, setSalesOrderList] = useState([])
  const getSalesOrderList = async () => {
    try {
      setIsLoading(true)
      const salesOrderListData = await axios.get(
        `${reactAppUrl}/sellOrderList?page=${page}&from_date=${fromDate}&to_date=${toDate}&name=${name}&phone_no=${phone}`,
        getHeader()
      )
      setIsLoading(false)

      if (
        salesOrderListData &&
        salesOrderListData.status == responsecode200()
      ) {
        setSalesOrderList(salesOrderListData.data.data)

        let totalPages =
          salesOrderListData.data.total / salesOrderListData.data.perPage
        setTotalCount(Math.ceil(totalPages))
        setPerPageItem(salesOrderListData.data.perPage)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response && error.response.status == responsecode408()) {
        toast.error(`${error.response.data.message}`)
      } else {
        toast.error(`${error.response.data.message}`)
      }
    }
  }

  //   to go to the view page with the id start
  const handleView = id => {
    try {
      navigate("/viewsale", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }
  //   to go to the view page with the id end

  // To Edit the merchant detail start

  const handleEdit = id => {
    try {

      // navigate({pathname:"/EditBusiness",state:id})
      navigate("/editsaleorder", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the merchant detail end

  // react bootstrap modal start

  const [deleteId, setDeleteId] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  // react bootstrap modal end

  // To delete the merchant detail start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `sellOrderDelete?sell_order_id=${deleteId}`
      )
      setIsLoading(false)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  // To delete the merchant detail end

  useEffect(() => {
    getSalesOrderList()
  }, [page, render])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="विक्री ऑर्डर" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="row"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div
                          className="col-md-2 mb-3"
                          style={{ textAlign: "right" }}
                        >
                          <a
                            className="btn btn-primary"
                            href="/createsalesorder"
                            
                          >
                            <i className="bx bx-plus align-middle"></i>नवीन
                            विक्री ऑर्डर
                          </a>
                        </div>
                      </div>
                      <form className="mt-3">
                        <div className="row mb-4">
                          <div className="col-md-10">
                            <div className="row">
                        <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">
                          नाव 
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="नाव"
                            onChange={e => {
                              setName(
                                e.target.value
                              )
                            }}/>
                        </div>
                      </div>
                      <div className="col-sm-3 col-xl-3">
                        <div className="form-group mb-3">
                          <label className="form-label">
                          फोन  नं.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="फोन नं."
                            minLength={10}
                            maxLength={10}
                            onChange={e => {
                              setPhone(
                                e.target.value
                              )
                            }}/>
                        </div>
                      </div>
                          <div className="col-sm-3 col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">या तारखे पासून</label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="dd-mm-yyyy"
                                onChange={e => setFromDate(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 col-xl-3">
                            <div className="form-group mb-3">
                              <label className="form-label">या तारखे पर्यंत</label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="dd-mm-yyyy"
                                onChange={e => setToDate(e.target.value)}
                              />
                            </div>
                          </div>
                          </div>
                          </div>
                          <div className="col-sm-2 col-xl-2 align-self-end">
                            <div className="mb-3">
                              <Button
                                type="button"
                                color="primary"
                                className="w-md"
                                onClick={getSalesOrderList}
                              >
                                फिल्टर
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>

                      {isLoading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                        </>
                      ) : salesOrderList && salesOrderList.length>0 ? (
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th>अ.क्र.</th>
                                  <th>नाव</th>

                                  <th>पत्ता</th>

                                  <th>फोन नं</th>
                                  <th>व्हरायटी</th>
                                  <th>अंदाजे वजन</th>
                                  <th>विक्री तारीख</th>
                                  <th>नोट </th>
                                  <th></th>
                                </tr>
                              </thead>

                              {salesOrderList && salesOrderList.map((salesOrder, index) => {
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td>
                                        {page * perPageItem -
                                          perPageItem +
                                          (index + 1)}
                                      </td>
                                      <td>{!!salesOrder.merchant_name ? salesOrder.merchant_name : "-"}</td>
                                      <td>{!!salesOrder.merchant_address ? salesOrder.merchant_address : "-"}</td>
                                      <td>{!!salesOrder.merchant_phone_no ? salesOrder.merchant_phone_no : "-"}</td>
                                      <td>{!!salesOrder.type ? salesOrder.type : "-"}</td>
                                      <td>{!!salesOrder.weight ? salesOrder.weight : "-"} kg</td>
                                      <td>{!!salesOrder.sell_date ? moment(salesOrder.sell_date).format("DD/MM/YYYY") : "-"}</td>
                                      <td>{!!salesOrder.note ? ((salesOrder.note).length < 8 ? (salesOrder.note) : (salesOrder.note).slice(0, 4) + "..." + (salesOrder.note).slice(-4)) : "-"}</td>
                                      <td>
                                        <a
                                          className="btn-sm btn-view  btn"
                                          onClick={e =>
                                            handleView(
                                              salesOrder?.sell_order_id
                                            )
                                          }
                                        >
                                          <div className="tooltip1">
                                            <i className="bx bx-file align-middle ">
                                              <span className="tooltiptext">
                                                पहा
                                              </span>
                                            </i>
                                          </div>
                                        </a>

                                        <a
                                          className="btn-sm btn-edit  btn"
                                          onClick={e =>
                                            handleEdit(
                                              salesOrder?.sell_order_id
                                            )
                                          }
                                        >
                                          <div className="tooltip1">
                                            <i className="bx bx-edit align-middle">
                                              <span className="tooltiptext">
                                                संपादित करा
                                              </span>
                                            </i>
                                          </div>
                                        </a>

                                        <a
                                          className="btn-sm btn-delete btn"
                                          // onClick={e =>
                                          //   handleDelete(
                                          //     salesOrder?.sell_order_id
                                          //   )
                                          // }
                                          onClick={() =>
                                            handleShow(
                                              salesOrder?.sell_order_id
                                            )
                                          }
                                        >
                                          <div className="tooltip1">
                                            <i className="bx bxs-trash align-middle">
                                              <span className="tooltiptext">
                                                हटवा
                                              </span>
                                            </i>
                                          </div>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                )
                              })}
                            </table>
                          </div>
                          <ul
                            className="pagination modal-1"
                            style={{ float: "right" }}
                          >
                            <Pagination
                              count={totalCount}
                              page={page}
                              onChange={handleChange}
                              color="secondary"
                              shape="rounded"
                            />
                          </ul>
                        </div>
                      ) : <div className="nodata"><b>No data available</b></div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>विक्री ऑर्डर हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDelete}  className="btn btn-success" disabled={isLoading} >
          {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button variant="secondary" onClick={handleClose}  className="btn btn-danger">
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Salesorder
