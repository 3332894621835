import React, { useEffect, useState } from "react"

import "../../assets/css/style.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import axios from "axios"
import {
  getHeader,
  getRequest,
  postRequest,
  responsecode200,
  responsecode408,
} from "common/Constants"
import Select from "react-select"
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

const Editsaleorder = () => {
  //meta title

  const reactAppUrl = process.env.REACT_APP_ALLURL

  // hooks start
  const navigate = useNavigate()
  const location = useLocation()
  // hooks end

  const [id, setId] = useState(location.state.id)
  const [merchant, setMerchant] = useState([])
  const [personName, setPersonName] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const getAllMerchants = async () => {
    const merchantResponse = await getRequest(`allMerchants`)
    setMerchant(merchantResponse.data.data)
    const mappedData = merchantResponse.data.data.map(item => ({
      value: item.merchant_id,
      label: item.name,
    }))
    setPersonName(mappedData)
  }

  // to get the specific merchant data start
  const [salesData, setSalesData] = useState([])
  const getSalesOrderData = async () => {
    try {
      const responseData = await getRequest(
        `sellOrderDetail?sell_order_id=${id}`
      )
      if (responseData && responseData.status == 200) {
        setSalesData(responseData.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the specific merchant data end

  // to get the address and phone no start
  const [merchantData, setMerchantData] = useState([])
  const [merchantId, setMerchantId] = useState([])
  const handleUsernameChange = async (value) => {
    try {
      // validation.handleChange(event)
      const selectedName = value
      // setMerchantId(event.target.value)
      setMerchantId(value)
      // setSalesData((pre)=>{...pre,merchant_id:})
      setSalesData(pre => ({
        ...pre,
        merchant_id: value,
      }))

      const responseDetail = await axios.get(
        `${reactAppUrl}/merchantDetail?merchant_id=${selectedName}`,
        getHeader
      )
      setMerchantData(responseDetail.data.data)

      validation.setFieldValue("Address", responseDetail.data.data.address)
      validation.setFieldValue("Telephone", responseDetail.data.data.phone_no)
    } catch (error) {
      console.log(error)
    }
  }
  // to get the address and phone no end

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: salesData.merchant_id || "",
      Telephone: salesData.merchant_phone_no || "",
      Address: salesData.merchant_address || "",
      Variety: salesData.type || "",
      weight: salesData.weight || "",
      Note: salesData.note || "",
      date1: salesData.sell_date || "",
      // date2: "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("कृपया आपले नाव टाका"),
      Address: Yup.string().required("कृपया तुमचा पत्ता टाका"),
      Telephone: Yup.string()
        .required("कृपया  फोन नं. टाका")
        .min(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा")
        .max(10, "कृपया दहा अंकी क्रमांक प्रविष्ट करा"),
      Variety: Yup.string().required("कृपया  व्हरायटी टाका"),
      weight: Yup.string().required("कृपया अंदाजे वजन टाका"),
      date1: Yup.string().required("कृपया तारीख टाका"),
      // Note: Yup.string().required("कृपया नोट टाका"),
      // date2: Yup.string().required("कृपया तारीख टाका"),
    }),
    onSubmit: async values => {
      try {
        const data = {

          merchant_id: values.Name,
          sell_order_id: id,
          type: values.Variety,
          weight: values.weight,
          note: values.Note,
          sell_date: values.date1,
        }

        setIsLoading(true)
        const responseData = await postRequest(`sellOrderUpdate`, data)
        setIsLoading(false)

        if (responseData && responseData.status == 200) {
          navigate("/Salesorder")
        } else if (responseData && responseData.status == 409) {
          navigate("/editsaleorder")
        }
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    },
  })
  // to get the variety list data start

  const [varietyList, setVarietyList] = useState([])
  const [varietyName, setVarietyName] = useState([])
  const getVarietyList = async () => {
    try {
      const responseData = await getRequest(`varietyList`)
      if (responseData && responseData.status == 200) {
        setVarietyList(responseData.data.data)
        const mappedData = responseData.data.data.map(item => ({
          value: item.variety_id,
          label: item.name,
        }))
        setVarietyName(mappedData)
      }
    } catch (error) {
      console.log(error)
    }
  }
  // to get the variety list data end

  useEffect(() => {
    getAllMerchants()
    getSalesOrderData()
    getVarietyList()
  }, [])

  useEffect(() => {
    // While editsales order page is active, the order tab must also activated
    let element = document.getElementById("order")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="विक्री ऑर्डर" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">नाव *</label>
                      {/* <div className="col-md-10">
                            <input
                              id="Name"
                              name="Name"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Name}
                            />
                            {validation.touched.Name && validation.errors.Name ? (
                              <span className="error">{validation.errors.Name}</span>
                            ) : null}
                          </div> */}

                      <div className="col-md-10">
                        {/* <select
                          id="formrow-InputState variety"
                          name="Name"
                          className="form-select"
                          // onChange={validation.handleChange}
                          onChange={handleUsernameChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Name}
                        >
                          <option value="">Select</option>

                          {merchant &&
                            merchant.map((merchants, index) => {
                              return (
                                <option
                                  value={merchants.merchant_id}
                                  key={index}
                                >
                                  {merchants.name}
                                </option>
                              )
                            })}
                        </select> */}

                        <Select
                          styles={customStyles}
                          id="Name"
                          name="Name"
                          className="basic-single"
                          classNamePrefix="select"
                          options={personName}
                          onChange={selectedOption => {
                            validation.setFieldValue(
                              "Name",
                              selectedOption ? selectedOption.value : ""
                            )
                            handleUsernameChange(selectedOption.value)
                          }}
                          onBlur={e => {
                            validation.handleBlur(e)
                            // handleUsernameChange()
                          }}
                          value={personName.find(
                            option => option.value === validation.values.Name
                          )}
                        />

                        {validation.touched.Name && validation.errors.Name ? (
                          <span className="error">
                            {validation.errors.Name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">पत्ता*</label>
                      <div className="col-md-10">
                        <textarea
                          id="Address"
                          name="Address"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Address}
                          disabled
                        />
                        {validation.touched.Address &&
                        validation.errors.Address ? (
                          <span className="error">
                            {validation.errors.Address}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        फोन नं*
                      </label>
                      <div className="col-md-10">
                        <input
                          id="Telephone"
                          name="Telephone"
                          className="form-control"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Telephone}
                          disabled
                        />
                        {validation.touched.Telephone &&
                        validation.errors.Telephone ? (
                          <span className="error">
                            {validation.errors.Telephone}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        व्हरायटी*
                      </label>
                      <div className="col-md-10">
                        {/* <select
                          id="formrow-InputState variety"
                          name="Variety"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Variety}
                        >
                          <option value="">Select</option>
                          {varietyList &&
                            varietyList.map((variety, index) => {
                              return (
                                <option value={variety.variety_id} key={index}>
                                  {variety.name}
                                </option>
                              )
                            })}
                        </select> */}

                        <Select
                          styles={customStyles}
                          id="farmername"
                          name="Variety"
                          className="basic-single"
                          classNamePrefix="select"
                          options={varietyName}
                          onChange={selectedOption => {
                            validation.setFieldValue(
                              "Variety",
                              selectedOption ? selectedOption.value : ""
                            )
                          }}
                          onBlur={e => {
                            validation.handleBlur(e)
                          }}
                          value={varietyName.find(
                            option => option.value == validation.values.Variety
                          )}
                        />

                        {validation.touched.Variety &&
                        validation.errors.Variety ? (
                          <span className="error">
                            {validation.errors.Variety}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        अंदाजे वजन
                      </label>
                      <div className="col-md-10">
                        <div className="d-flex">
                          <input
                            id="weight"
                            name="weight"
                            className="form-control"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.weight}
                          />
                          <span className="p-1">Kg</span>
                        </div>
                        {validation.touched.weight &&
                        validation.errors.weight ? (
                          <span className="error">
                            {validation.errors.weight}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">नोट</label>
                      <div className="col-md-10">
                        <textarea
                          id="Note"
                          name="Note"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Note}
                        />
                        {validation.touched.Note && validation.errors.Note ? (
                          <span className="error">
                            {validation.errors.Note}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        विक्री तारीख*
                      </label>
                      {/* <div className="col-md-10">
                            <DatePicker
                              selected={startDate}
                              onChange={date => setStartDate(date)}
                              className="form-control"
                              name="date1"
                              dateFormat="d-MM-yyyy"
                              placeholderText="Select date"
                            />
                          </div> */}

                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          name="date1"
                          id="example-month-input month"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.date1}
                        />
                        {validation.touched.date1 && validation.errors.date1 ? (
                          <span className="error">
                            {validation.errors.date1}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        className="col-md-2 col-5"
                        style={{ textAlign: "left" }}
                      >
                        <Link to="/Salesorder" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div
                        className="col-md-2 col-7"
                        style={{ textAlign: "right" }}
                      >
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? " प्रस्तुत करत आहोत" : "प्रस्तुत करणे"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editsaleorder
