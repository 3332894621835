import React, { useEffect, useState } from "react"
import "../../assets/css/style.css"
import { Button, Label } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import Modal from "react-bootstrap/Modal"

// import { Link } from "react-router-dom";
// import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { getRequest } from "common/Constants"
import { Pagination } from "@mui/material"
import moment from "moment"

const Paymentlist = () => {
  const [isLoading, setIsLoading] = useState(false)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  //meta title
  document.title = "Samarth Fruit Company"

  // hooks start
  const navigate = useNavigate()
  // hooks end

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  const [deleteId, setDeleteId] = useState("")

  // react bootstrap modal start

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  // react bootstrap modal end

  const [perPageItem, setPerPageItem] = useState(0)
  const [paymentList, setPaymentList] = useState([])

  const getPaymentList = async () => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `paymentList?page=${page}&from_date=${startDate}&to_date=${endDate}`
      )
      setIsLoading(false)
      setPaymentList(responseData.data.data)
      let totalPages = responseData.data.total / responseData.data.perPage
      setTotalCount(Math.ceil(totalPages))
      setPerPageItem(responseData.data.perPage)
    } catch (error) {
      console.log(error)
    }
  }

  // To delete the merchant detail start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `paymentDelete?payment_id=${deleteId}`
      )
      setIsLoading(false)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  // To delete the merchant detail end

  //   to go to the view page with the id start
  const handleView = id => {
    try {
      navigate("/viewpayment", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }
  //   to go to the view page with the id end

  // To Edit the payment detail start

  const handleEdit = id => {
    try {

      // navigate({pathname:"/EditBusiness",state:id})
      navigate("/editpayment", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the payment detail end

  useEffect(() => {
    getPaymentList()
  }, [page, render])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="पेमेंट" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="row mb-3 mt-3"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div
                          className="col-md-2 "
                          style={{ textAlign: "right" }}
                        >
                          <a className="btn btn-primary" href="/Addpayment">
                            <i className="bx bx-plus align-middle"></i> नवीन
                            पेमेंट
                          </a>
                        </div>
                      </div>
                      <div className="row mb-3 mt-3">
                        {/* <div className="col-md-2 mb-3" style={{ textAlign: "right" }}>
                      <Link to="/Addfarmer" type="button" class="btn-add btn-sm  btn btn-primary"></Link>
                    </div> */}
                        <div className="col-md-10">
                          <form>
                            <div className="row">
                              <div className="col-sm-3 col-xl-3">
                                <div className="form-group mb-3">
                                  <label className="form-label">नाव</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="नाव"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">प्रवर्ग </Label>
                                  <select
                                    id="category"
                                    name="category"
                                    className="form-select"
                                  >
                                    <option value="default">Select</option>
                                    <option value="1">शेतकरी </option>
                                    <option value="2">व्यापारी</option>
                                    <option value="3">व्यवसाय</option>
                                    <option value="4">इतर</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    या तारखे पासून
                                  </Label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e => setStartDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    या तारखे पर्यंत
                                  </Label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e => setEndDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-sm-3 col-xl-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    पेमेंट स्थिती
                                  </Label>
                                  <select className="form-control select2-search-disable">
                                    <option value="Pending">बाकी </option>
                                    <option value="Completed">पूर्ण</option>
                                  </select>
                                </div>
                              </div> */}

                              <div className="col-sm-3 col-xl-3  mt-0 filter-button">
                                <div>
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="w-md"
                                    onClick={getPaymentList}
                                  >
                                    फिल्टर
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {isLoading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                        </>
                      ) : paymentList.length > 0 ? (
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th>अ.क्र.</th>
                                  <th>तारीख</th>
                                  <th>नाव</th>
                                  <th>डेबिट/क्रेडिट</th>
                                  <th>पेमेंट पद्धत(कॅश/बँकिंग)</th>
                                  <th>नोट </th>
                                </tr>
                              </thead>

                              {paymentList &&
                                paymentList.map((payment, index) => {
                                  return (
                                    <tbody key={index}>
                                      <tr>
                                        <td>
                                          {page * perPageItem -
                                            perPageItem +
                                            (index + 1)}
                                        </td>
                                        <td>
                                          {/* {!!payment?.date ? payment.date : "-"} */}
                                          {!!payment?.date
                                            ? moment(payment?.date).format(
                                                "DD/MM/YYYY"
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          {!!payment?.name ? payment.name : "-"}
                                        </td>
                                        <td>
                                          {!!payment?.payment_mode
                                            ? payment.payment_mode == "1"
                                              ? "debit"
                                              : payment.payment_mode == "2"
                                              ? "credit"
                                              : "-"
                                            : "-"}
                                        </td>
                                        <td>
                                          {!!payment?.payment_type
                                            ? payment.payment_type == "1"
                                              ? "cash"
                                              : payment.payment_type == "2"
                                              ? "banking"
                                              : "-"
                                            : "-"}
                                        </td>
                                        <td>
                                          {!!payment?.notes
                                            ? payment.notes
                                            : "-"}
                                        </td>
                                        <td>
                                          <a
                                            className="btn-sm btn-view  btn"
                                            onClick={e =>
                                              handleView(payment?.payment_id)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bx-file align-middle ">
                                                <span className="tooltiptext">
                                                  पहा
                                                </span>
                                              </i>
                                            </div>
                                          </a>

                                          <a
                                            className="btn-sm btn-edit  btn"
                                            onClick={e =>
                                              handleEdit(payment?.payment_id)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bx-edit align-middle">
                                                <span className="tooltiptext">
                                                  संपादित करा
                                                </span>
                                              </i>
                                            </div>
                                          </a>

                                          {/* <Link
                                            to="#"
                                            type="button"
                                            className="btn-sm btn-print btn "
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bx-printer align-middle">
                                                <span className="tooltiptext">
                                                  प्रिंट करा
                                                </span>
                                              </i>
                                            </div>
                                          </Link> */}

                                          <a
                                            className="btn-sm btn-delete btn"
                                            // onClick={e =>
                                            //   handleDelete(payment?.payment_id)
                                            // }
                                            onClick={() =>
                                              handleShow(payment?.payment_id)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bxs-trash align-middle">
                                                <span className="tooltiptext">
                                                  हटवा
                                                </span>
                                              </i>
                                            </div>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                })}
                            </table>
                          </div>
                          <ul
                            className="pagination modal-1"
                            style={{ float: "right" }}
                          >
                            <Pagination
                              count={totalCount}
                              page={page}
                              onChange={handleChange}
                              color="primary"
                              shape="rounded"
                            />
                          </ul>
                        </div>
                      ) : (
                        <div className="nodata">
                          <b>No data available</b>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>पेमेंट हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Paymentlist
