import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form } from "react-router-dom"
import { getRequest, postRequest } from "common/Constants"
import { useFormik } from "formik"
import * as Yup from "yup"
const Editvariety = () => {
  // hooks start
  const location = useLocation()
  const navigate = useNavigate()
  // hooks end

  const [id, setId] = useState(location.state.id)

  // get variety data start

  const [varietySpecific, setVarietySpecific] = useState([])
  const getMerchantData = async () => {

    const responseData = await getRequest(`varietyDetail?variety_id=${id}`)

    setVarietySpecific(responseData.data.data)
  }

  // get variety data end

  //   to edit the varient useFormik start

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: varietySpecific.name || "",
      status: varietySpecific.is_block == 0 ? varietySpecific.is_block : varietySpecific.is_block || "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("कृपया आपले नाव टाका"),
      status: Yup.string().required("कृपया स्टेटस टाका"),
    }),
    onSubmit: async values => {
      try {
        let data = {
          variety_id: id,
          name: values.Name,
          is_block: values.status,
        }

        const responseData = await postRequest(`varietyUpdate`, data)

        if (responseData && responseData.status == 200) {
          navigate("/Information")
        } else if (responseData && responseData.status == 409) {
          navigate("/editvariety")
        }
      } catch (error) {
        if (error.response && error.response.status == responsecode408()) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.response.data.message)
        }
      }
    },
  })
  //   to edit the varient useFormik end

  // const [variery, setVariety] = useState([])
  // const getVariety = async () => {
  //   try {
  //     const responseData = await getRequest(`varietyList`)
  //     if (responseData && responseData.status == 200) {
  //       setVariety(responseData.data.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  useEffect(() => {
    getMerchantData()
    // getVariety()
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="एडिट  व्हरायटी" />
          <div className="row">
            <div className="col" style={{ padding: "0" }}>
              <div className="card">
                <div className="card-body">
                  <form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">
                        व्हरायटी नाव
                      </label>
                      <div className="col-md-10">
                        <input
                          id="Name"
                          name="Name"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Name}
                        />
                        {validation.touched.Name && validation.errors.Name ? (
                          <span className="error">
                            {validation.errors.Name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-md-2 col-form-label">स्टेटस </label>

                      <div className="col-md-10">
                        <select
                          id="formrow-InputState s"
                          name="status"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.status}
                        >
                          <option value=""> select</option>
                          <option value="1">ऍक्टिवेट</option>
                          <option value="0">डेअक्टिवेट</option>
                        </select>
                        {validation.touched.status &&
                        validation.errors.status ? (
                          <span className="error">
                            {validation.errors.status}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-2 col-5" style={{ textAlign: "left" }}>
                        <Link to="/Information" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                      <div className="col-md-2 col-7" style={{ textAlign: "right" }}>
                        <Button color="primary" type="submit">
                          प्रस्तुत करणे
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editvariety
