import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "../../assets/css/style.css"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Button } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import "react-toastify/dist/ReactToastify.css"
import { getRequest, postRequest } from "common/Constants"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "34px", // Adjust the height to your preference
  }),
}

const Editfarmerpayment = () => {
  const reactAppUrl = process.env.REACT_APP_ALLURL

  // hooks start
  const navigate = useNavigate()
  const location = useLocation()
  const {id} = useParams();
  // hooks end
  const [farmer, setFarmer] = useState([])
  const [personName, setPersonName] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const getAllFarmers = async () => {
    // const merchantResponse = await axios.get(`${reactAppUrl}/allMerchants`)
    const farmerResponse = await getRequest(`dropdownFarmer`) //api chnge
    setFarmer(farmerResponse.data.data)
    const mappedData = farmerResponse.data.data.map(item => ({
      value: item.farmer_id,
      label: item.name,
    }))
    setPersonName(mappedData)
  }

  // api get merchant
  const [farmerPaymentId, setFarmerPaymentId] = useState(id)
  const [farmerPaymentData, setFarmerPaymentData] = useState([])
  const getFarmer = async () => {
    const farmerResponse = await getRequest(
      `farmerPayDetails?farmerPayId=${farmerPaymentId}`
    ) //api frmer
    setFarmerPaymentData(farmerResponse?.data)
  }
  useEffect(() => {
    getFarmer()
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Name: farmerPaymentData.id || "",
      Date: farmerPaymentData.date || "",
      Amount: farmerPaymentData.Money || "क्रेडिट",
      Paymentstatus: farmerPaymentData.payment_type || "",
      Money: farmerPaymentData.amount || "",
      Hetu: farmerPaymentData.reason || "",
      Note: farmerPaymentData.notes || "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("कृपया आपले नाव टाका"),
      Date: Yup.string().required("कृपया तारीख टाका"),
      Amount: Yup.string().required("कृपया पेमेंट प्रकार टाका"),
      Paymentstatus: Yup.string().required("कृपया पेमेंट पेमेंट पद्धत टाका"),
      Money: Yup.string().required("कृपया रक्कम टाका"),
      Hetu: Yup.string().required("कृपया हेतू टाका"),
      Note: Yup.string().required("कृपया नोट टाका"),
    }),
    onSubmit: async values => {
      try {
        const data = {
          // merchant_id: merchantId,
          // type: values.Variety,
          // weight: values.weight,
          // note: values.Note,
          // sell_date: values.date1,
          farmerPayId: id,
          id: values.Name,
          date: values.Date,
          payment_mode: 2,
          payment_type: values.Paymentstatus,
          amount: values.Money,
          reason: values.Hetu,
          notes: values.Note,
        }

        setIsLoading(true)
        const responseData = await postRequest(`updateFarmerPay`, data) //api frmr
        setIsLoading(false)

        if (responseData && responseData.status == 200) {
          navigate("/farmerpayment") //pge change
        } else if (responseData && responseData.status == 409) {
          navigate(`/editfarmerpayment/${id}`) // pge change
        }
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    },
  })

  useEffect(() => {
    getAllFarmers()
  }, [])

  useEffect(() => {
    // While detailInvoice page is active, the invoice tab must also activated
    let element = document.getElementById("paymentlist")
    let element2 = document.getElementById("payment-ul")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    if (element2) {
      element2.classList.add("mm-show")
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
        element2.classList.remove("mm-show") // remove the 'show' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs
                title="डॅशबोर्ड"
                breadcrumbItem="एडिट शेतकरी पेमेंट"
              />

              <div className="row">
                <div className="col" style={{ padding: "0" }}>
                  <div className="card">
                    <div className="card-body">
                      <form className="needs-validation mt-4"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            नाव *
                          </label>
                          <div className="col-md-10">
                            {/* <select
                              id="formrow-InputState variety"
                              name="Name"
                              className="form-select"
                              onChange={validation.handleChange}
                              // onChange={handleUsernameChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Name}
                            >
                              <option value="">Select</option>

                              {farmer &&
                                farmer.map((farmers, index) => {
                                  return (
                                    <option
                                      value={farmers.farmer_id}
                                      key={index}
                                    >
                                      {farmers.name}
                                    </option>
                                  )
                                })}
                            </select> */}

                            <Select
                              styles={customStyles}
                              id="farmername"
                              name="Name"
                              className="basic-single"
                              classNamePrefix="select"
                              options={personName}
                              onChange={selectedOption => {
                                validation.setFieldValue(
                                  "Name",
                                  selectedOption ? selectedOption.value : ""
                                )
                              }}
                              onBlur={e => {
                                validation.handleBlur(e)
                              }}
                              value={personName.find(
                                option =>
                                  option.value === validation.values.Name
                              )}
                            />

                            {validation.touched.Name &&
                            validation.errors.Name ? (
                              <span className="error">
                                {validation.errors.Name}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            तारीख*
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="date"
                              name="Date"
                              id="example-month-input month"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Date}
                            />
                            {validation.touched.Date &&
                            validation.errors.Date ? (
                              <span className="error">
                                {validation.errors.Date}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            पेमेंट प्रकार*
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Amount"
                              name="Amount"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Amount}
                              disabled
                            />
                            {validation.touched.Amount &&
                            validation.errors.Amount ? (
                              <span className="error">
                                {validation.errors.Amount}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            पेमेंट पद्धत(कॅश/बँकिंग)*
                          </label>
                          <div className="col-md-10">
                            <select
                              id="formrow-InputState payment"
                              name="Paymentstatus"
                              className="form-select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Paymentstatus}
                            >
                              <option value="">Select</option>
                              <option value="1">कॅश</option>
                              <option value="2">बँकिंग</option>
                            </select>
                            {validation.touched.Paymentstatus &&
                            validation.errors.Paymentstatus ? (
                              <span className="error">
                                {validation.errors.Paymentstatus}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            रक्कम*
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Money"
                              name="Money"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Money}
                            />
                            {validation.touched.Money &&
                            validation.errors.Money ? (
                              <span className="error">
                                {validation.errors.Money}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">
                            हेतू*
                          </label>
                          <div className="col-md-10">
                            <input
                              id="Hetu"
                              name="Hetu"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Hetu}
                            />
                            {validation.touched.Hetu &&
                            validation.errors.Hetu ? (
                              <span className="error">
                                {validation.errors.Hetu}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label className="col-md-2 col-form-label">नोट</label>
                          <div className="col-md-10">
                            <textarea
                              id="Note"
                              name="Note"
                              className="form-control"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Note}
                            />
                            {validation.touched.Note &&
                            validation.errors.Note ? (
                              <span className="error">
                                {validation.errors.Note}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div
                          className="row mb-3"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            className="col-md-2 col-5"
                            style={{ textAlign: "left" }}
                          >
                            <Link
                              to="/farmerpayment"
                              className="btn btn-danger"
                            >
                              मागे जा
                            </Link>
                          </div>
                          <div
                            className="col-md-2 col-7"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading
                                ? " प्रस्तुत करत आहोत"
                                : "प्रस्तुत करणे"}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Editfarmerpayment
