import React, { useEffect, useState } from "react"
import "../../assets/css/style.css"
import { Button, Label } from "reactstrap"
import Sidebar from "components/VerticalLayout/Sidebar"
import Header from "components/VerticalLayout/Header"
import Footer from "components/VerticalLayout/Footer"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Modal } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { Pagination } from "@mui/material"
import moment from "moment"
import { getRequest } from "common/Constants"
const Merchantpayment = () => {
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [name, setName] = useState("")
  const navigate = useNavigate()

  document.title = "Samarth Fruit Company"

  const [merchantPay, setMerchantPay] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  const getMerchantPay = async () => {
    try {
      setIsLoading(true)
      const merchantResponse = await getRequest(`merchantPayList?page=${page}&name=${name}&start_date=${startDate}&end_date=${endDate}`)
      setIsLoading(false)
      setMerchantPay(merchantResponse.data.data)
      let totalPages =
        merchantResponse.data.total / merchantResponse.data.perPage
      setTotalCount(Math.ceil(totalPages))
      setPerPageItem(merchantResponse.data.perPage)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  const [deleteId, setDeleteId] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  const handleEdit = id => {
    try {
      // navigate({pathname:"/EditBusiness",state:id})
      navigate(`/editmerchantpayment/${id}`)
    } catch (error) {
      console.log(error)
    }
  }

  const [perPageItem, setPerPageItem] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)
      const responseData = await getRequest(
        `merchantPayDelete?merchantPayId=${deleteId}`
      )
      setIsLoading(false)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getMerchantPay()
  }, [render, page])

  const handleView = id => {
    try {
      navigate("/viewmerchantpayment", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // While detailInvoice page is active, the invoice tab must also activated
    let element = document.getElementById("paymentlist")
    let element2 = document.getElementById("payment-ul")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    if (element2) {
      element2.classList.add("mm-show")
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
        element2.classList.remove("mm-show") // remove the 'show' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="व्यापारी पेमेंट" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="row mb-3 mt-3"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div
                          className="col-md-2 "
                          style={{ textAlign: "right" }}
                        >
                          <a
                            className="btn btn-primary"
                            href="/addmerchantpayment"
                          >
                            <i className="bx bx-plus align-middle"></i> नवीन
                            व्यापारी
                          </a>
                        </div>
                      </div>
                      <div className="row mb-3 mt-3">
                        {/* <div className="col-md-2 mb-3" style={{ textAlign: "right" }}>
                      <Link to="/Addfarmer" type="button" class="btn-add btn-sm  btn btn-primary"></Link>
                    </div> */}
                        <div className="col-md-10">
                          <form>
                            <div className="row">
                              <div className="col-sm-3 col-xl-3">
                                <div className="form-group mb-3">
                                  <label className="form-label">नाव</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="नाव"
                                    onChange={e => {
                                      setName(e.target.value)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    या तारखे पासून
                                  </Label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e => setStartDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mb-3">
                                <div className="form-group">
                                  <Label className="form-label">
                                    या तारखे पर्यंत
                                  </Label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="dd-mm-yyyy"
                                    onChange={e => setEndDate(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-sm-3 col-xl-3  mt-0 filter-button">
                                <div>
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="w-md"
                                    onClick={() => {
                                      setPage(1);
                                      getMerchantPay()
                                    }}
                                  >
                                    फिल्टर
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {isLoading ? (
                        <>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                          <h1>
                            <Skeleton />
                          </h1>
                        </>
                      ) : (
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <table
                              id="tech-companies-1"
                              className="table table-striped table-bordered"
                            >
                              <thead>
                                <tr>
                                  <th>अ.क्र.</th>
                                  <th>नाव</th>
                                  <th style={{ width: "10%" }}>तारीख</th>
                                  <th style={{ width: "10%" }}>
                                    पेमेंट प्रकार
                                  </th>
                                  <th style={{ width: "10%" }}>
                                    पेमेंट पद्धत(कॅश/बँकिंग)
                                  </th>
                                  <th style={{ width: "10%" }}>रक्कम</th>
                                  <th style={{ width: "20%" }}>हेतू </th>
                                </tr>
                              </thead>

                              {merchantPay.length > 0 ? (
                                merchantPay.map((item, index) => {
                                  return (
                                    <tbody key={index}>
                                      <tr>
                                        <td>
                                          {page * perPageItem -
                                            perPageItem +
                                            (index + 1)}
                                        </td>
                                        <td>{item?.name}</td>
                                        <td style={{ width: "10%" }}>
                                          {new Date(
                                            item?.date
                                          ).toLocaleDateString("en-GB")}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          {item?.payment_mode == 1
                                            ? "डेबिट"
                                            : "क्रेडिट"}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          {item?.payment_type == 1
                                            ? "कॅश"
                                            : "बँकिंग"}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          {item?.amount}
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {item?.reason}
                                        </td>
                                        <td>
                                          <a
                                            className="btn-sm btn-view  btn"
                                            onClick={e =>
                                              handleView(item?.merchantPayId)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bx-file align-middle ">
                                                <span className="tooltiptext">
                                                  पहा
                                                </span>
                                              </i>
                                            </div>
                                          </a>

                                          <a
                                            className="btn-sm btn-edit  btn"
                                            onClick={e =>
                                              handleEdit(item?.merchantPayId)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bx-edit align-middle">
                                                <span className="tooltiptext">
                                                  संपादित करा
                                                </span>
                                              </i>
                                            </div>
                                          </a>

                                          <a
                                            className="btn-sm btn-delete btn"
                                            onClick={() =>
                                              handleShow(item?.merchantPayId)
                                            }
                                          >
                                            <div className="tooltip1">
                                              <i className="bx bxs-trash align-middle">
                                                <span className="tooltiptext">
                                                  हटवा
                                                </span>
                                              </i>
                                            </div>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan="7">
                                    <div className="nodata">
                                      <b>Data Not Available</b>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </table>
                          </div>
                          <ul
                            className="pagination modal-1"
                            style={{ float: "right" }}
                          >
                            <Pagination
                              count={totalCount}
                              page={page}
                              onChange={handleChange}
                              color="primary"
                              shape="rounded"
                            />
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>व्यापारी पेमेंट</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Merchantpayment
