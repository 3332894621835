import React, { useEffect, useState } from "react"
import Sidebar from "components/VerticalLayout/Sidebar"
import Footer from "components/VerticalLayout/Footer"
import Header from "components/VerticalLayout/Header"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, useLocation } from "react-router-dom"
import axios from "axios"
// import { useFormik } from "formik";
// import * as Yup from "yup";
const ViewFarmer = () => {
  const location = useLocation()

  const [id, setId] = useState(location.state.id)

  const reactAppUrl = process.env.REACT_APP_ALLURL

  //meta title
  document.title = "Samarth Fruit Company"

  const [customchkPrimary, setcustomchkPrimary] = useState(true)
  const [customchkSuccess, setcustomchkSuccess] = useState(true)
  const [customchkInfo, setcustomchkInfo] = useState(true)
  const [customchkWarning, setcustomchkWarning] = useState(true)
  const [customchkDanger, setcustomchkDanger] = useState(true)
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true)
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true)
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true)
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  const [farmerData, setFarmerData] = useState([])

  // get specific farmer details start

  const getFarmerData = async () => {
    const response = await axios.get(
      `${reactAppUrl}/FarmerDetail?farmer_id=${id}`
    )
    setFarmerData(response.data.data)
  }

  // get specific farmer details end

  useEffect(() => {
    getFarmerData()
  }, [])

  useEffect(() => {
    // While view farmer page is active, the yadi tab must also activated
    let element = document.getElementById("farmerList")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div id="layout-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="शेतकरी" />

              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div className="cardTitle h4 d-none">Textual inputs</div>
                      <p className="card-title-desc d-none">
                        Here are examples of <code>.form-control</code> applied
                        to each textual HTML5 <code>&lt;input&gt;</code>{" "}
                        <code>type</code>.
                      </p>
                      <form>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">नाव</label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.name}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            पत्ता
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.address}</p>
                          </div>
                        </div>
                        <div className="row  border-1">
                        
                        
                            <div className="row">
                              <div className="col-md-4 mb-3">
                                <div className="row">
                                  <label className="col-md-6 col-form-label">
                                    गाव
                                  </label>
                                  <div className="col-md-6 m-auto">
                                    <p className="mb-0 para1">
                                      {farmerData.village}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-3">
                                <div className="row">
                                  <label className="col-md-6 col-form-label">
                                    तालुका
                                  </label>
                                  <div className="col-md-6 m-auto">
                                    <p className="mb-0 para1">
                                      {farmerData.taluka}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-3">
                                <div className="row">
                                  <label className="col-md-6 col-form-label">
                                    जिल्हा
                                  </label>
                                  <div className="col-md-6 m-auto">
                                    <p className="mb-0 para1">
                                      {farmerData.district}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                       
                        </div>
                        <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            फोन नं
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.phone_no}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            पपई लागवड केलेले
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.is_planted == 1 ? "हो" : "नाही"}</p>
                          </div>
                        </div>
                        {/* <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            व्हरायटी
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.type}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            लागवड क्षेत्र (एकर मध्ये )
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.planted_area}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            झाडांची संख्या
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.tree_count}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            अंदाजे वजन (टन मध्ये)
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.weight}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            लागवडीची तारीख
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.plantation_date}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            काढणी तारीख
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.cutting_date}</p>
                          </div>
                        </div>
                        <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            तोडणी वारंवारिता
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.cutting_ratio}</p>
                          </div>
                        </div> */}
                        {/* <div className="row mb-3 border-1">
                          <label className="col-md-2 col-form-label">
                            तोडणी महिने
                          </label>
                          <div className="col-md-10 m-auto">
                            <p className="mb-0 para1">{farmerData.cutting_month}</p>
                          </div>
                        </div> */}
                            <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-2" style={{ textAlign: "left" }}>
                        <Link to="/farmer/Listindex" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                   
                    </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default ViewFarmer
