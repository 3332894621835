import React, { useEffect, useState } from "react"
import { Button, Label } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import axios from "axios"
import { Pagination } from "@mui/material"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Modal } from "react-bootstrap"
import { getRequest } from "common/Constants"

const Farmer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isPlanted, setIsPlanted] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const [startDate, setStartDate] = useState("")
  const [EndDate, setEndDate] = useState("")

  const navigate = useNavigate()

  const reactAppUrl = process.env.REACT_APP_ALLURL

  // for pagination start

  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  }

  // for pagination end

  // react bootstrap modal start

  const [deleteId, setDeleteId] = useState("")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = id => {
    setDeleteId(id)
    setShow(true)
  }

  // react bootstrap modal end

  // To delete the farmer start

  const [render, setRender] = useState(true)
  const handleDelete = async id => {
    try {
      setIsLoading(true)

      const responseData = await getRequest(
        `farmerDelete?farmer_id=${deleteId}`
      )
      setIsLoading(false)
      setPage(1)
      setShow(false)
      setRender(!render)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  // To delete the farmer end

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }
  //meta title
  document.title = "Samarth Fruit Company"

  // get farmer list

  const [perPageItem, setPerPageItem] = useState(0)

  const [farmerList, setFarmerList] = useState([])
  const getFarmerList = async () => {
    try {
      setIsLoading(true)

      const response = await axios.get(
        `${reactAppUrl}/FarmerList?page=${page}&is_planted=${isPlanted}&name=${name}&phone_no=${phone}`
      )

      setIsLoading(false)
      setFarmerList(response.data.data)

      let totalPages = response.data.total / response.data.perPage
      setTotalCount(Math.ceil(totalPages))
      setPerPageItem(response.data.perPage)
    } catch (error) {
      console.log("former", error)
      setIsLoading(false)
    }
  }

  // view the farmer detail start

  const handleView = id => {
    try {
      navigate("/Viewfarmer", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // view the farmer detail end

  // To Edit the sales invoice detail start

  const handleEdit = id => {
    try {
      // navigate({pathname:"/EditBusiness",state:id})
      navigate("/Editfarmer", { state: { id: id } })
    } catch (error) {
      console.log(error)
    }
  }

  // To Edit the sales invoice detail end

  useEffect(() => {
    getFarmerList()
  }, [page, render])

  return (
    <div>
      <div className="row">
        <div className="col">
          <form className="mt-3">
            <div className="row mb-4">
              <div className="col-sm-3 col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">नाव</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="नाव"
                    onChange={e => {
                      setName(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-3 col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">फोन नं.</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="फोन नं"
                    minLength={10}
                    maxLength={10}
                    onChange={e => {
                      setPhone(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-3 col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label">पपई लागवड केलेले*</label>
                  <select
                    id="formrow-InputState variety"
                    name="papaya"
                    className="form-select"
                    onChange={e => {
                      setIsPlanted(
                        e.target.value == ""
                          ? ""
                          : e.target.value == "1"
                          ? 1
                          : 0
                      )
                    }}
                  >
                    <option value="">select</option>
                    <option value="1">हो </option>
                    <option value="0">नाही </option>
                  </select>
                </div>
              </div>
              {/* <div className="col-sm-3 col-xl-3">
                <div className="form-group mb-3">
                  <label className="col-form-label">पपई लागवड केलेले*</label>
                  <select
                    id="formrow-InputState variety"
                    name="papaya"
                    className="form-select"
                    onChange={e => {
                      setIsPlanted(
                        e.target.value == ""
                          ? ""
                          : e.target.value == "1"
                          ? 1
                          : 0
                      )
                    }}
                  >
                    <option value="">select</option>
                    <option value="1">हो </option>
                    <option value="0">नाही </option>
                  </select>
                </div>
              </div> */}
              {/* <div className="col-sm-2 col-xl-2">
                    <div className="form-group mb-3">
                      <Label className="form-label">या तारखे पासून </Label>
                          <input
                        className="form-control"
                        type="date"
                        name="startDate"
                        id="example-month-input month"
                        onChange={e => setStartDate(e.target.value)}
                      />
                    </div>
                  </div> */}
              {/* <div className="col-sm-2 col-xl-2">
                    <div className="form-group mb-3">
                      <Label className="form-label">या तारखे पर्यंत </Label>
                          <input
                        className="form-control"
                        type="date"
                        name="endDate"
                        id="example-month-input month"
                        onChange={e => setEndDate(e.target.value)}
                      />
                    </div>
                  </div> */}
              <div className="col-sm-2 col-xl-2 align-self-end">
                <div className="mb-3">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={getFarmerList}
                  >
                    फिल्टर
                  </Button>
                </div>
              </div>
            </div>
          </form>

          {isLoading ? (
            <>
              <h1>
                <Skeleton />
              </h1>
              <h1>
                <Skeleton />
              </h1>
              <h1>
                <Skeleton />
              </h1>
              <h1>
                <Skeleton />
              </h1>
              <h1>
                <Skeleton />
              </h1>
            </>
          ) : farmerList.length > 0 ? (
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th>अ.क्र.</th>
                      <th>नाव</th>
                      <th>पत्ता</th>
                      <th>गाव</th>
                      <th>तालुका</th>
                      <th>जिल्हा</th>
                      <th>फोन नं</th>
                      <th>पपई लागवड केलेले</th>
                      {/* <th>व्हरायटी</th>
                        <th>लागवड क्षेत्र (एकर मध्ये )</th>
                        <th>झाडांची संख्या</th>
                        <th>अंदाजे वजन (टन मध्ये)</th>
                        <th>लागवडीची तारीख</th>
                        <th>काढणी तारीख</th>
                        <th>तोडणी वारंवारिता</th>
                        <th>तोडणी महिने</th> */}
                      <th></th>
                    </tr>
                  </thead>

                  {farmerList.map((farmer, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>
                            {page * perPageItem - perPageItem + (index + 1)}
                          </td>
                          <td>{farmer.name}</td>
                          <td>{farmer.address}</td>
                          <td>{farmer.village}</td>
                          <td>{farmer.taluka}</td>
                          <td>{farmer.district}</td>
                          <td>{farmer.phone_no}</td>
                          <td>{farmer.is_planted == 0 ? "नाही" : "हो"}</td>
                          {/* <td>{farmer.type}</td>
                            <td>{farmer.planted_area}</td>
                            <td>{farmer.tree_count}</td>
                            <td>{farmer.weight}</td>
                            <td>{farmer.plantation_date}</td>
                            <td>{farmer.cutting_date}</td>
                            <td>{farmer.cutting_ratio}</td>
                            <td>{farmer.cutting_month}</td> */}
                          <td>
                            <a
                              className="btn-sm btn-view  btn"
                              onClick={e => handleView(farmer.farmer_id)}
                            >
                              <div className="tooltip1">
                                <i className="bx bx-file align-middle ">
                                  <span className="tooltiptext">पहा</span>
                                </i>
                              </div>
                            </a>

                            <a
                              className="btn-sm btn-edit  btn"
                              onClick={e => handleEdit(farmer?.farmer_id)}
                            >
                              <div className="tooltip1">
                                <i className="bx bx-edit align-middle">
                                  <span className="tooltiptext">
                                    संपादित करा
                                  </span>
                                </i>
                              </div>
                            </a>

                            <a
                              className="btn-sm btn-delete btn"
                              onClick={() => handleShow(farmer?.farmer_id)}
                            >
                              <div className="tooltip1">
                                <i className="bx bxs-trash align-middle">
                                  <span className="tooltiptext">हटवा</span>
                                </i>
                              </div>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
                </table>
              </div>
              <ul className="pagination modal-1" style={{ float: "right" }}>
                <Pagination
                  count={totalCount}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                  shape="rounded"
                />
              </ul>
            </div>
          ) : (
            <div className="nodata">
              <b>Data Not Available</b>
            </div>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>शेतकरी हटवा</Modal.Title>
        </Modal.Header>
        <Modal.Body>हा डेटा हटवला जाईल</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleDelete}
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? "हटवत आहोत " : "होय"}
          </Button>

          <Button
            variant="secondary"
            onClick={isLoading ? "" : handleClose}
            className="btn btn-danger"
          >
            नाही
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Farmer
