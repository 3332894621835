import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form } from "react-router-dom"
import axios from "axios"
import { responsecode200, responsecode408 } from "common/Constants"
import moment from "moment"
// import { useFormik } from "formik";
// import * as Yup from "yup";
const viewsale = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const reactAppUrl = process.env.REACT_APP_ALLURL

  //meta title
  document.title = "Samarth Fruit Company"

  // to get the salesorder start

  const [id, setId] = useState(location.state.id)
  const [salesOrderList, setSalesOrderList] = useState([])

  const salesorderData = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(
        `${reactAppUrl}/sellOrderDetail?sell_order_id=${id}`
      )
      setIsLoading(false)

      if (response && response.status == responsecode200()) {
        setSalesOrderList(response.data.data)
      }
    } catch (error) {
      if (error.response && error.response.status == responsecode408()) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.response.data.message)
      }
    }
  }

  // to get the salesorder end

  useEffect(() => {
    salesorderData()
  }, [])

  useEffect(() => {
    // While viewsale page is active, the order tab must also activated
    let element = document.getElementById("order")
    if (element) {
      element.classList.add("mm-active") // Add the 'active' class to the element
    }
    return () => {
      if (element) {
        element.classList.remove("mm-active") // remove the 'active' class to the element when change to another page
      }
    }
  }, [])

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="डॅशबोर्ड" breadcrumbItem="विक्री ऑर्डर" />

          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  {isLoading ? (
                    <>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton />
                      </h1>
                      <h1>
                        <Skeleton style={{ width: "50px" }} />
                      </h1>
                    </>
                  ) : salesOrderList ? (
                    <form>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">नाव</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                            {salesOrderList.merchant_name}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">पत्ता</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                            {salesOrderList.merchant_address}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-3 border-1">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                          फोन नं
                        </label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">
                            {salesOrderList.merchant_phone_no}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">
                          व्हरायटी
                        </label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">{salesOrderList.type}</p>
                        </div>
                      </div>

                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">
                          अंदाजे वजन
                        </label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">{salesOrderList.weight + " kg"}</p>
                        </div>
                      </div>

                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">
                          विक्री तारीख
                        </label>
                        <div className="col-md-10 m-auto">
                          {/* <p className="mb-0 para1">{salesOrderList.sell_date}</p> */}
                          <p className="mb-0 para1">
                            {moment(salesOrderList.sell_date).format(
                              "DD/MM/YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 border-1">
                        <label className="col-md-2 col-form-label">नोट</label>
                        <div className="col-md-10 m-auto">
                          <p className="mb-0 para1">{salesOrderList.note}</p>
                        </div>
                      </div>

                      <div
                        className="row mb-3"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="col-md-2" style={{ textAlign: "left" }}>
                          <Link to="/Salesorder" className="btn btn-danger">
                            मागे जा
                          </Link>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className="nodata"><b>No data available</b></div>
                  )}
                    </div>
                    {/* <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">
                        व्हरायटी
                      </label>
                      <div className="col-md-10 m-auto">
                        <p className="mb-0 para1">{salesOrderList.type}</p>
                      </div>
                    </div>

                    <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">
                        अंदाजे वजन
                      </label>
                      <div className="col-md-10 m-auto flex">

                        <p className="mb-0 para1">{salesOrderList.weight} <span>kg</span></p>
                       
                      </div>
                    </div>

                    <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">
                        विक्री तारीख
                      </label>
                      <div className="col-md-10 m-auto">
                        <p className="mb-0 para1">{salesOrderList.sell_date}</p>
                      </div>
                    </div>
                    <div className="row mb-3 border-1">
                      <label className="col-md-2 col-form-label">
                      नोट
                      </label>
                      <div className="col-md-10 m-auto">
                        <p className="mb-0 para1">{salesOrderList.note}</p>
                      </div>
                    </div>

                    <div
                      className="row mb-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-2" style={{ textAlign: "left" }}>
                        <Link to="/Salesorder" className="btn btn-danger">
                          मागे जा
                        </Link>
                      </div>
                    </div> */}
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  )
}

export default viewsale
